import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environments';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  API_URL = `${environment.apiURL}`

  constructor(private http: HttpClient,) { }

  /**
 * Get member of a given id
 *
 * @param id user id of the user to be fetched
 * @returns list of members
 */
  getMemberOfId(id: string) {
    return this.http.get(this.API_URL + `/members/` + id);
  }

  /**
   * Get member of a given id
   *
   * @param id user id of the user to be fetched
   * @returns list of members
   */
  getMemberOfIdSpecificType(id: string, type: string) {
    return this.http.get(this.API_URL + `/members/` + id + "/" + type);
  }

  /**
   * Get all Dashboard data.
   *
   * @param id ObjectId : user id of the academy.
   * @returns
   */
  getDashboardData(id: string) {
    return this.http.get(this.API_URL + `/dashboard/${id}`);
  }

  /**
   * Get all pending invites in the dashboard.
   *
   * @param id ObjectId : user id of the academy.
   * @returns
   */
  getPending(id: string) {
    return this.http.get(this.API_URL + `/dashboard/${id}/pending`);
  }

  /**
   * Change the image of the cover/dp of the academy.
   *
   * @param id Object Id of the Academy.
   * @param type cover/dp of the uploaded image.
   * @param url url of the uploaded image.
   * @returns
   */
  editUserImage(id: string, type: string, url: string) {
    const httpParams = new HttpParams()
      .append("type", type)
      .append("url", url)
    return this.http.put(this.API_URL + `/users/${id}/cover`, httpParams);
  }
}
