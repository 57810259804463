const ClassAccessControl = {
    create: {
        permissions: [
            'class-create',
            'class-access-to-all'
        ]
    },
    view : {
        permissions: [
            'class-view',
            'class-access-to-all'
        ]
    },
    delete: {
        permissions: [
            'class-delete',
            'class-access-to-all'
        ]
    },
    view_class : {
        permissions: [
            'class-view-detail',
            'class-access-to-all'
        ]
    },
    view_supervisor: {
        permissions: [
            'class-view-supervisor',
            'class-access-to-all'
        ]
    },
    create_supervisor: {
        permissions: [
            'class-add-new-supervisor',
            'class-access-to-all'
        ]
    },
    change_supervisor: {
        permissions: [
            'class-change-supervisor',
            'class-access-to-all'
        ]
    },
    delete_supervisor: {
        permissions: [
            'class-delete-supervisor',
            'class-access-to-all'
        ]
    },
    create_member: {
        permissions: [
            'class-add-new-member',
            'class-access-to-all'
        ]
    },
    // create_academy_member: {
    //     permissions: [
    //         'class-add-academy-member',
    //         'class-access-to-all'
    //     ]
    // },
    delete_member: {
        permissions: [
            'class-delete-member',
            'class-access-to-all'
        ]
    }
}

export { 
    ClassAccessControl 
};