<div class="col-12 mb-3">
  <h3 class="page-title">
      <i class="bi bi-person-fill menu-icon header-icon"></i>
      {{title}}
  </h3>
</div>
<div class="col-md-12">
  <div class="card col-12" style="margin-top: 2rem;">
      <div class="card-body">
          <div class="row">
              <div class="col-12">
                  <div class="row" *ngIf="!is_loading">
                      <div class="col-12">
                          <div class="w-100 mb-3">
                              <!-- d-flex h-100 w-100 justify-content-center align-items-center mb-5 p-5 border
                                                                          border-secondary -->
                              <div class="pulse-container mb-5 p-5 h-100 w-100">
                                  <div class="pulse" *ngFor="let num of [1,2, 3, 4, 5, 6]"></div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="w-100 d-flex flex-wrap">
                      <div class="col-lg-3 col-md-4 col-sm-6 col-12 p-4 gallery-preview-wrapper grid"
                          *ngFor="let galleryPreview of data; let i = index">
                          <div class="w-100 h-100 d-flex flex-column justify-content-between gallery-img-preview-wrapper front"
                              style.background-image='url("{{galleryPreview?.details?.uploads[0].url}}")'
                              (click)="openGalleryDetailModal(galleryDetailPopup,galleryPreview)"
                              (keydown.enter)="openGalleryDetailModal(galleryDetailPopup,galleryPreview)"
                              (keydown.space)="openGalleryDetailModal(galleryDetailPopup,galleryPreview)"
                              tabindex="{{i}}"
                              [ngClass]="{'video-src': galleryPreview?.type?.includes('video'), 'pdf-src': galleryPreview?.type?.includes('application')}">
                              <div class="d-flex w-100 p-2 justify-content-end">
                                  <span
                                      class="gallery-preview-play d-flex align-items-center justify-content-center p-2"
                                      *ngIf="galleryPreview?.type?.includes('video')">
                                      <img src="images/icons/video_play.png" alt="">
                                  </span>
                                  <span class=" d-flex align-items-center justify-content-center"
                                      *ngIf="galleryPreview?.type?.includes('application')">
                                      <img style="height: 30px;" src="images/icons/ic_doc_black.png" alt="">
                                  </span>
                              </div>
                              <div class="w-100 p-3 gallery-preview-meta-wrapper" *ngIf="galleryPreview.details">
                                  <div class="w-100">
                                      <h5 class="gallery-title text-light font-weight-bold">
                                          {{galleryPreview.details.date}}</h5>
                                      <p class="gallery-preview-title mb-0">{{galleryPreview.details.title}}</p>
                                  </div>
                              </div>
                          </div>
                          <div
                              class="w-100 h-100 d-flex flex-column justify-content-between gallery-img-preview-wrapper image-load-error back">
                          </div>
                      </div>
                  </div>
                  <div class="no-data" *ngIf="data.length === 0 && is_loading">
                      <Span>No files!</Span>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template class="modal fade" #galleryDetailPopup let-modal>
  <div class="w-100 d-flex gallery-modal-wrapper">
      <div class="w-50 p-5 gallery-modal-carousel-wrapper h-100">
          <ng-container *ngIf="enableCarousel && selectedGalleryDetails" class="w-100">
              <owl-carousel-o [options]="carouselOption1" class="w-100">
                  <ng-template carouselSlide *ngFor="let attachment of selectedGalleryDetails.details.uploads" class="w-100">
                      <div
                          class="gallery-modal-img-wrapper w-100 mb-3 d-flex align-items-center justify-content-center">
                          <img [src]="attachment.url" alt="image" *ngIf="attachment.type.includes('image')"
                              (error)="onImgError($event)" />
                          <video controls *ngIf="attachment.type.includes('video')" class="w-100 h-100">
                              <source [src]="attachment.url" type="video/mp4">
                          </video>
                          <div *ngIf="attachment.type.includes('application')" class="pdf-container">
                              <object [data]="attachment.url" type="application/pdf" width="100%" height="100%">
                                  <p>To view or Download the PDF <a [href]="attachment.url">Click here</a></p>
                              </object>
                          </div>
                      </div>
                  </ng-template>
              </owl-carousel-o>
          </ng-container>
      </div>
      <div class="w-50 gallery-modal-content-wrapper h-100 overflow-auto">
          <div class="w-100 position-relative">
              <button type="button" class="btn btn-close position-absolute modal-close p-4" (click)="modal.dismiss()"
                  tabindex="-1">
                  <img src="images/icons/ic_close_light.png" alt="">
              </button>
          </div>
          <div class="d-flex flex-column justify-content-between w-100 h-100">
              <div *ngIf="galleryDetailsLoading" class="d-flex h-100 w-100 justify-content-center align-items-center">
                  <div class="circle-loader"></div>
              </div>
              <div class="w-100" *ngIf="!galleryDetailsLoading">
                  <div class="w-100 p-4" *ngIf="['homework','pd'].includes(type)">
                      <div class="w-100 mb-3" *ngFor="let info of selectedGalleryDetails?.data">
                          <h6 class="font-weight-bold">{{info[0]}}</h6>
                          <p class="text-base">{{info[1]}}</p>
                      </div>
                  </div>
                  <div class="w-100 mb-3" *ngIf="!['homework','pd'].includes(type)">
                      <div class="w-100 p-2">
                          <div class="d-flex w-100">
                              <div class="gallery-profile-pic p-2">
                                  <img [src]="selectedGalleryDetails.user.profile_pic" alt="">
                              </div>
                              <div class="p-2">
                                  <!-- [routerLink]="['/member/profile/' + memberId]" -->
                                  <h6 class="mb-2 font-weight-bold cursor-pointer" tabindex="-1">
                                      {{selectedGalleryDetails.user.display_name}}
                                  </h6>
                                  <p class="gallery-meta-text mb-1" *ngIf="selectedGalleryDetails.user.location">
                                      <img src="images/icons/ic_location_grey.png" width="10" height="15" alt=""
                                          class="mr-1">
                                      {{selectedGalleryDetails.user.location}}
                                  </p>
                                  <p class="gallery-meta-text">{{selectedGalleryDetails.details.date}}</p>
                              </div>
                          </div>
                          <div class="w-100 pl-2 pr-2">
                              <p class="mb-0">{{selectedGalleryDetails.post.description}}</p>
                          </div>
                      </div>
                      <hr [class]="selectedGalleryDetails.post.description ? '' : 'mt-0 mb-2'">
                      <div class="d-flex w-100 justify-content-center align-items-center" *ngIf="commentsLoading"
                          style="height: 70px">
                          <div class="circle-loader"></div>
                      </div>
                      <div class="w-100 p-2" *ngIf="!commentsLoading">
                          <div class="w-100 d-flex" *ngFor="let comment of selectedGalleryDetails.comments">
                              <div class="gallery-profile-pic p-2">
                                  <img [src]="comment.profile_pic" alt="">
                              </div>
                              <div class="p-2">
                                  <p class="mb-2">
                                      <!-- [routerLink]="['/member/profile/' + memberId]" -->
                                      <b class="text-capitalize cursor-pointer">
                                          {{comment.display_name}}
                                      </b>
                                      &nbsp;{{comment.comment}}
                                  </p>
                                  <p class="gallery-meta-text mb-0">7 hours ago</p>
                              </div>
                          </div>
                          <p class="gallery-meta-text p-2" *ngIf="selectedGalleryDetails.comments.length === 0">No
                              comments available</p>
                      </div>
                  </div>
              </div>
              <div class="w-100 p-4" *ngIf="['homework','pd'].includes(type) && !galleryDetailsLoading"
                  (click)="redirectToMoreInfo(selectedGalleryDetails?.user?._id)"
                  (keydown.enter)="redirectToMoreInfo(selectedGalleryDetails?.user?._id)"
                  (keydown.space)="redirectToMoreInfo(selectedGalleryDetails?.user?._id)"
                  tabindex="0"
                  >
                  <div><button class="btn btn-primary btn-block rounded-1 w-100">More Info</button></div>
              </div>
          </div>
      </div>
  </div>
</ng-template>
