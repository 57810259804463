/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { BlobServiceClient, BlobUploadCommonResponse, ContainerClient, RestError } from '@azure/storage-blob';
import { ErrorHelpersService } from '../helpers/error-helpers.service';
import { Buffer } from 'buffer';

export interface AzureResponse {
  upload_request_id: string,
  upload_status: number,
  upload_file: string,
  upload_file_type: string,
  uploadpath: string,
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private blobServiceClient: BlobServiceClient | undefined;
  public isUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private api: ApiService,
    private error_handler: ErrorHelpersService
  ) { }

  /**
   * Function to generate to Azure storage blob and upload a file to Azure.
   *
   * @param file File: File to be uploaded.
   * @param account_name string: Name of the Azure storage account.
   * @param container_name string: Name of the Azure blod container.
   * @param upload_path string: Path where the file is to be uploaded.
   * @returns Json with upload status || Error status
   */
  async azureupload(file: File, account_name: string, container_name: string, upload_path: string): Promise<any> {
    const token = await this.api.getUploadTokenAzure(account_name).toPromise();
    if (token.sasToken == "error") {
      return { status: "error", error: "Token generation error." }
    }
    const response = await this.azureuploadFile(file, account_name, container_name, upload_path, token.sasToken)
    return response
  }

  /**
   * Function to connect to Azure storage blob and upload a file at given file path.
   *
   * @param file File: File to be uploaded.
   * @param account_name string: Name of the Azure storage account.
   * @param container_name string: Name of the Azure blod container.
   * @param upload_path string: Path where the file is to be uploaded.
   * @param token string: shared access signature (SAS) used to create connection with the Azure blob container.
   * @returns Json with upload status || Error status
   */
  private async azureuploadFile(file: File, account_name: string, container_name: string, upload_path: string, token: string): Promise<any> {
    const filename = file.name.replace(/ /g, "_");
    const filetype = file.type
    this.blobServiceClient = new BlobServiceClient(`https://${account_name}.blob.core.windows.net?${token}`);
    const blockBlobClient = this.blobServiceClient.getContainerClient(container_name).getBlockBlobClient(`${upload_path}/${filename}`);

    const options = {
      blobHTTPHeaders: {
        blobContentType: filetype
      },
      onProgress: (progress: any) => {
        const percentage = Math.floor((progress.loadedBytes / progress.totalBytes) * 100);
        this.error_handler.handleError(`Upload progress: ${percentage}%`)
      }
    };

    try {
      const response: BlobUploadCommonResponse = await blockBlobClient.uploadData(file, options);
      if (response._response.status === 201) {
        const upload_status = {
          upload_request_id: response.requestId,
          upload_status: 200,
          upload_file: filename,
          upload_file_type: filetype,
          uploadpath: `https://${account_name}.blob.core.windows.net/${container_name}/${upload_path}/${filename}`,
        }
        return upload_status
      } else {
        throw new Error('Upload failed');
      }
    } catch (error: any) {
      this.error_handler.handleError(`File upload error: ${error}`);
      throw error;
    }
  }

  /**
   * Function to connect to Azure storage blob and delete a file at given file path.
   *
   * @param account_name string: Name of the Azure storage account.
   * @param container_name string: Name of the Azure blod container.
   * @param upload_path string: Name of the Azure blod container.
   * @returns Json with upload status || Error status
   */

  async azuredelete(account_name: string, container_name: string, upload_path: string): Promise<any> {
    try {
      const token = await this.api.getUploadTokenAzure(account_name).toPromise();

      if (token.sasToken === "error") {
        return { status: "error", error: "Token generation error." };
      }

      const response = await this.azuredeleteFile(account_name, container_name, upload_path, token.sasToken);

      return response;
    } catch (error) {
      // Handle specific error types if possible
      if ( error instanceof RestError && error.message.includes("The specified blob does not exist.")) {
        return { status: "error", error: "The specified blob does not exist." };
      }
      if (error instanceof RangeError) {
        return { status: "error", error: "A RangeError occurred." };
      }
      this.error_handler.handleError(`An unexpected error occurred during azuredelete: ${error}`);
      return { status: "error", error: "An unexpected error occurred." };
    }
  }

  /**
   * Function to connect to Azure storage blob and delete a file at given file path.
   *
   * @param account_name string: Name of the Azure storage account.
   * @param container_name string: Name of the Azure blod container.
   * @param upload_path string: Name of the Azure blod container.
   * @returns Json with upload status || Error status
   */
  private async azuredeleteFile(account_name: string, container_name: string, upload_path: string, token: string): Promise<any> {
    this.blobServiceClient = new BlobServiceClient(`https://${account_name}.blob.core.windows.net?${token}`);
    // const blockBlobClient = this.blobServiceClient.getContainerClient(container_name).getBlockBlobClient(`${upload_path}`);

    const containerClient: ContainerClient = this.blobServiceClient.getContainerClient(container_name);

    try {
      const response: BlobUploadCommonResponse = await containerClient.deleteBlob(`${upload_path}`);
      if (response._response.status === 202) {
        const upload_status = {
          upload_request_id: response.requestId,
          upload_status: 200,
          upload_file: upload_path,
        }
        return upload_status
      } else {
        throw new Error('Delete failed');
      }
    } catch (error) {
      this.error_handler.handleError(`File Delete error: ${error}`);
      throw error;
    }
  }

  /**
   * Function to generate to Azure storage blob and upload a cropped base64 data to Azure.
   *
   * @param file File: File to be uploaded.
   * @param account_name string: Name of the Azure storage account.
   * @param container_name string: Name of the Azure blod container.
   * @param upload_path string: Path where the file is to be uploaded.
   * @param base64 string: Base64 encode data of the cropped image.
   * @returns Json with upload status || Error status
   */
  async azureuploadCropped(file: File, account_name: string, container_name: string, upload_path: string, base64: string): Promise<any> {
    const token = await this.api.getUploadTokenAzure(account_name).toPromise();
    if (token.sasToken == "error") {
      return { status: "error", error: "Token generation error." }
    }
    const response = await this.azureuploadFileCropped(file, account_name, container_name, upload_path, token.sasToken, base64)
    return response
  }

  /**
   * Function to connect to Azure storage blob and delete a cropped base64 data at given file path.
   *
   * @param file File: File to be uploaded.
   * @param account_name string: Name of the Azure storage account.
   * @param container_name string: Name of the Azure blod container.
   * @param upload_path string: Path where the file is to be uploaded.
   * @param token string: shared access signature (SAS) used to create connection with the Azure blob container.
   * @param base64 string: Base64 encode data of the cropped image.
   * @returns
   */
  private async azureuploadFileCropped(file: File, account_name: string, container_name: string, upload_path: string, token: string, base64: string): Promise<any> {
    const filename = file.name.replace(/ /g, "_");
    const filetype = file.type
    this.blobServiceClient = new BlobServiceClient(`https://${account_name}.blob.core.windows.net?${token}`);
    const blockBlobClient = this.blobServiceClient.getContainerClient(container_name).getBlockBlobClient(`${upload_path}/${filename}`);

    try {
      // Convert the base64 data to a buffer
      // const buffer = Buffer.from(base64, 'base64');
      const buffer = Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')

      // Upload the image to Azure Blob Storage
      const response = await blockBlobClient.uploadData(buffer, {
        blobHTTPHeaders: {
          blobContentType: filetype, // Replace 'image/jpeg' with the appropriate content type
        },
      });
      if (response._response.status === 201) {
        const upload_status = {
          upload_request_id: response.requestId,
          upload_status: 200,
          upload_file: filename,
          upload_file_type: filetype,
          uploadpath: `https://${account_name}.blob.core.windows.net/${container_name}/${upload_path}/${filename}`,
        }
        return upload_status
      } else {
        throw new Error('Upload failed');
      }
    } catch (error: any) {
      this.error_handler.handleError(`An error occurred while uploading the image: ${error.message}`);
    }
  }
}
