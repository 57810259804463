<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="contain d-flex">
  <div class="login-container" *ngIf="!isMultiple">
    <div *ngIf="!not_academy">
      <div class="login-logo">
        <img src="images/icons/logo/logo_orange.png" class="logo">
      </div>
      <div class="pt-4">
          <h2>Log in</h2>
      </div>
      <div class="pt-4">
          <form class="pt-3" [formGroup]="loginForm" (ngSubmit)="onLogin()">
              <input class="d-flex bd-highlight w-100 p-3 login-input" placeholder="Email address/ Username"
                  formControlName="email" id="email" name="email">
              <div class="d-flex w-100 bd-highlight login-input mt-2">
                  <input class="d-flex  w-100 p-3 login-input" placeholder="Password" id="password" name="password"
                      [type]="hide ? 'password' : 'text'" formControlName="password"><img
                      class="password-toggle clickable-link"
                      [src]="hide? 'images/icons/input/ic_eye_openxhdpi.png' : 'images/icons/input/ic_eye_closedxhdpi.png'"
                      (click)="togglePassword()">
              </div>
          </form>
          <p class="error-text" *ngIf="error">{{error_text}}*</p>
          <a href="" [routerLink]="['/password']" >
            <p class="pt-3 float-end clickable-link color-grey" >Forgot password?</p>
          </a>
      </div>
      <div class="mt-6">
          <button class="btn btn-dark w-100" (click)="onLogin()" [disabled]="!loginForm.valid">Login</button>
      </div>
      <!-- <div class="mt-4 text-center">
          <p class="color-grey">Dont have an account yet? <span class="clickable-link color-blue ml-2 opacity-100"
                  routerLink="/signup">Signup</span>
          </p>
          <p class="color-grey">or connect using</p>

      </div> -->
    </div>
    <div class="auth-form-transparent text-left p-3" *ngIf="not_academy">
      <div class="login-logo">
        <img src="images/icons/logo/logo_orange.png" class="logo">
      </div>
      <h6 class="font-weight-light" *ngIf="!is_parents_or_child">Sorry this seems to be a {{profile}} profile. We are
        temporarily limiting access
        to only academy email ids to improve quality and user experience. <br><br>All features for coaches, academy
        admins and
        owners
        are available with the academy login. Coach specific logins and other user roles shall be available soon.</h6>
      <div>
        <button class="btn btn-primary mt-4" (click)="toggle()" *ngIf="!is_parents_or_child">I have Academy
          Account</button>
      </div>
      <h6 class="font-weight-light" *ngIf="is_parents_or_child">Looks like you’re a {{profile}}. This is the boring
        space of all the behind the scenes activity. For you, we have a
        curated space. Click here to go there.</h6>
      <div>
        <div class="d-flex flex-colomn" *ngIf="is_parents_or_child" style="grid-gap:1rem;">
          <a class="btn btn-primary mt-4" href="{{pwa_url}}">Take me to Player
            App</a>
          <button class="btn btn-primary mt-4" (click)="toggle()" *ngIf="is_parents_or_child">Take me back</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 d-flex align-items-center justify-content-center" style="flex-direction: column;"
      *ngIf="isMultiple">
      <h4>Select an academy</h4>
      <h6 class="font-weight-light">Looks like you have been assigned a role in multiple academies, please select an
        Academy to continue login.</h6>
      <div class="d-flex align-items-center justify-content-center">
        <div class="d-card m-3 pointer" *ngFor="let academy of academy_list" (click)="academySelector(academy)">
          <div class="d-card-body c-pointer">
            <div class="d-flex">
              <div class="profile-pic">
                <img [src]="academy.academy_info?.basic_information?.dp" class="profile-pic"
                  (error)="onImgError($event)">
              </div>
              <div class="profile-text">
                {{academy.academy_info?.basic_information?.name?.first_name}}
                {{academy.academy_info?.basic_information?.name?.last_name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="desktop-only">
      <img class="side-panel" src="images/bg/bg_login.png">
  </div>
</div>


<!-- <div class="contain d-flex">
  <div id="g_id_onload" data-client_id="399156699473-2q9cheo3m5f4f551kclutpl73lgu6s8b.apps.googleusercontent.com"
      data-login_uri="http://localhost:4200" data-auto_prompt="false">
  </div>
  <div class="g_id_signin" data-type="standard" data-size="large" data-theme="outline" data-text="sign_in_with"
      data-shape="rectangular" data-logo_alignment="left">
  </div>

  <div id="buttonDiv"></div>
</div> -->
