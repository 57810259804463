const MemberAccessControl = {

    view_member_management : {
        permissions: [
            'member-management-view',
            'member-access-to-all'
        ]
    },

    // **member listing
    create_member_listing: {
        permissions: [
            'member-listing-create',
            'member-access-to-all'
        ]
    },
    view_member_listing : {
        permissions: [
            'member-listing-view',
            'member-access-to-all'
        ]
    },
    delete_member_listing: {
        permissions: [
            'member-listing-delete',
            'member-access-to-all'
        ]
    },
    copy_link_member_listing: {
        permissions: [
            'member-listing-link-copy',
            'member-access-to-all'
        ]
    },
    create_plan_member_listing: {
        permissions: [
            'member-listing-create-plan',
            'member-access-to-all'
        ]
    },
    edit_plan_member_listing: {
        permissions: [
            'member-listing-edit-plan',
            'member-access-to-all'
        ]
    },
    view_member_profile_page : {
        permissions: [
            'member-view-profile-page',
            'member-access-to-all'
        ]
    },
    member_assign_group : {
        permissions: [
            'member-assign-group',
            'member-access-to-all'
        ]
    },
    resend_coach_invitation : {
        permissions: [
            'coach-invitation-resend',
            'member-access-to-all'
        ]
    },
    resend_member_invitation : {
        permissions: [
            'member-invitation-resend',
            'member-access-to-all'
        ]
    },
    resend_admin_invitation : {
        permissions: [
            'admin-invitation-resend',
            'member-access-to-all'
        ]
    },
    resend_parent_mail : {
        permissions: [
            'parent-mail-resend',
            'member-access-to-all'
        ]
    },
    share_member_profile : {
        permissions: [
            'share-profile-member',
            'member-access-to-all'
        ]
    },


    // **profile page

    view_profile_personal_info : {
        permissions: [
            'profile-view-personal-info',
            'member-access-to-all'
        ]
    },
    edit_profile_personal_info : {
        permissions: [
            'profile-edit-personal-info',
            'member-access-to-all'
        ]
    },
    view_profile_contact_info : {
        permissions: [
            'profile-view-contact-info',
            'member-access-to-all'
        ]
    },
    view_profile_parental_info : {
        permissions: [
            'profile-view-parental-info',
            'member-access-to-all'
        ]
    },
    edit_profile_parental_info : {
        permissions: [
            'profile-edit-parental-info',
            'member-access-to-all'
        ]
    },
    view_profile_education_quali : {
        permissions: [
            'profile-view-education-quali',
            'member-access-to-all'
        ]
    },
    create_profile_education_quali : {
        permissions: [
            'profile-create-education-quali',
            'member-access-to-all'
        ]
    },
    delete_profile_education_quali : {
        permissions: [
            'profile-delete-education-quali',
            'member-access-to-all'
        ]
    },
    view_profile_nutrition : {
        permissions: [
            'profile-view-nutrition',
            'member-access-to-all'
        ]
    },
    edit_profile_nutrition : {
        permissions: [
            'profile-edit-nutrition',
            'member-access-to-all'
        ]
    },
    view_profile_associated_detail : {
        permissions: [
            'profile-view-associated-detail',
            'member-access-to-all'
        ]
    },
    view_profile_yearwise_academic_report: {
        permissions: [
            'profile-view-yearwise-academic-report',
            'member-access-to-all'
        ]
    },
    view_profile_achievements : {
        permissions: [
            'profile-view-achievements',
            'member-access-to-all'
        ]
    },
    view_profile_groupwise_pd: {
        permissions: [
            'profile-view-groupwise-pd',
            'member-access-to-all'
        ]
    },
    view_profile_pending_homework: {
        permissions: [
            'profile-view-pending-homework',
            'member-access-to-all'
        ]
    },
    view_profile_attendance: {
        permissions: [
            'profile-view-attendance',
            'member-access-to-all'
        ]
    },
    view_profile_gallery: {
        permissions: [
            'profile-view-gallery',
            'member-access-to-all'
        ]
    },

    //** */ Members pending invites

    view_member_pending_invites: {
        permissions: [
            'member-view-pending-invites',
            'member-access-to-all'
        ]
    },
    // create_member_invite : {
    //     permissions: [
    //         'member-create-invite',
    //         'member-access-to-all'
    //     ]
    // },
    delete_member_invite : {
        permissions: [
            'member-delete-invite',
            'member-access-to-all'
        ]
    },
    // copy_link_member_invites: {
    //     permissions: [
    //         'member-invites-link-copy',
    //         'member-access-to-all'
    //     ]
    // },
    accept_member_invites: {
        permissions: [
            'member-invites-accept',
            'member-access-to-all'
        ]
    },

    //** fee management */

    view_fee_management: {
        permissions: [
            'fee-management-view',
            'member-access-to-all'
        ]
    },

    view_fee_payment_dues: {
        permissions: [
            'fee-view-payment-dues',
            'member-access-to-all'
        ]
    },
    send_fee_reminder_all: {
        permissions: [
            'fee-send-reminder-all',
            'member-access-to-all'
        ]
    },
    edit_fee_dues_plan: {
        permissions: [
            'fee-edit-dues-plan',
            'member-access-to-all'
        ]
    },
    send_fee_reminder_individual: {
        permissions: [
            'fee-send-reminder-individual',
            'member-access-to-all'
        ]
    },
    record_fee_individual: {
        permissions: [
            'fee-record-individual',
            'member-access-to-all'
        ]
    },
    view_transaction_history: {
        permissions: [
            'transaction-view-history',
            'member-access-to-all'
        ]
    },
    download_transaction_all: {
        permissions: [
            'transaction-download-all',
            'member-access-to-all'
        ]
    },
    view_indi_transaction_history: {
        permissions: [
            'indi-transaction-view-history',
            'member-access-to-all'
        ]
    },
    download_transaction_individual: {
        permissions: [
            'transaction-download-individual',
            'member-access-to-all'
        ]
    },

    //** Membership plans */

    view_membership_plans: {
        permissions: [
            'membership-view-plans',
            'member-access-to-all'
        ]
    },
    create_membership_plans: {
        permissions: [
            'membership-create-plans',
            'member-access-to-all'
        ]
    },
    view_membership_individual_plans: {
        permissions: [
            'membership-view-individual-plans',
            'member-access-to-all'
        ]
    },
    view_membership_plan_amount: {
        permissions: [
            'membership-view-plan-amount',
            'member-access-to-all'
        ]
    },
    edit_custom_membership_plans: {
        permissions: [
            'membership-edit-custom-plans',
            'member-access-to-all'
        ]
    },
    delete_custom_membership_plans: {
        permissions: [
            'membership-delete-custom-plans',
            'member-access-to-all'
        ]
    },
    view_membership_plan_features: {
        permissions: [
            'membership-view-plan-features',
            'member-access-to-all'
        ]
    },
    create_membership_subscription: {
        permissions: [
            'membership-create-subscription',
            'member-access-to-all'
        ]
    },
    view_membership_subscription: {
        permissions: [
            'membership-view-subscription',
            'member-access-to-all'
        ]
    },

    //** User roles management */

    view_user_roles_management: {
        permissions: [
            'user-roles-view-management',
            'member-access-to-all'
        ]
    },
    create_user_roles: {
        permissions: [
            'user-roles-create',
            'member-access-to-all'
        ]
    },
    edit_custom_user_role: {
        permissions: [
            'user-roles-edit-custom',
            'member-access-to-all'
        ]
    },
    delete_custom_user_role: {
        permissions: [
            'user-roles-delete-custom',
            'member-access-to-all'
        ]
    },

    //** Recruitments */
    view_recruitment: {
        permissions: [
            'recruitment-view',
            'member-access-to-all'
        ]
    },

}

export {
    MemberAccessControl
};
