const DashboardAccessControl = {
    view : {
        permissions: [
            'dashboard-view',
            'dashboard-access-to-all'
        ]
    },
    //** added permission in 'memberAccessControl.create_member_listing' */ 
    // create_coach_member : {
    //     permissions: [
    //         'dasboard-add-new-member',
    //         'dashboard-access-to-all'
    //     ]
    // },
    //** added permission in 'ClassAccessControl.create' */   
    // create_group : {
    //     permissions: [
    //         'dasboard-add-new-group',
    //         'dashboard-access-to-all'
    //     ]
    // },
    view_invites_pending : {
        permissions: [
            'dasboard-view-invites-pending',
            'dashboard-access-to-all'
        ]
    },
    view_today_events : {
        permissions: [
            'dasboard-view-today-events',
            'dashboard-access-to-all'
        ]
    },
    view_gallery : {
        permissions: [
            'dasboard-view-gallery',
            'dashboard-access-to-all'
        ]
    },

}

export { 
    DashboardAccessControl 
};