/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClassAccessControl, DashboardAccessControl, MemberAccessControl } from '../../auth/access-control';
import { SharedModule } from '../../shared/shared.moudle';
import { StorageService } from '../../../services/storage.service';
import { cropperConfig, USER_DP, USER_ID, USER_SUPER } from '../../../data-models/user-data';
import { DashboardService } from '../dashboard.service';
import { ErrorHelpersService } from '../../../helpers/error-helpers.service';
import { ApiService } from '../../../services/api.service';
import { MembersService } from '../../members/members.service';
import { DatePipe } from '@angular/common';
import { CalenderService } from '../../calender/calender.service';
import { Title } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { environment } from '../../../../environments/environments';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from '../../../services/upload.service';

const storage_user = environment.storage_user
const container_user = environment.container_user

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  @ViewChild('myfileid') myButton!: ElementRef<HTMLButtonElement>;
  imageChangedEvent: Event | null = null;
  croppedImage: any;
  today = new Date()
  amount = 5499000000.99
  tile = "We Build a New Program"

  classAccessControl = ClassAccessControl;
  dashboardAccessControl = DashboardAccessControl;
  memberAccessControl = MemberAccessControl;
  id: any;
  user: any;
  vaildDate = true;
  date: Date = new Date();
  events: any;
  classes: any;
  calenderLoaded = false;
  academy_name = 'academy';
  noOfCoach: any;
  noOfGroups: any;
  noOfStudents: any;
  pending: any;
  nopendingData = false;
  galleryLoading = false;
  user_feed_all: any;
  user_feed: any;
  noData = false;
  dataFetchSuccess = false;
  member_pending: any = [];
  isLoading = true;
  member_pending_all: any;
  sports: any[] = [];
  sportsCountToday: any;
  homeworks: any[] = [];
  homeworkCountToday: any;
  pds: any[] = [];
  pdCountToday: any;
  upload_image_type = 'dp';
  current_feed: any
  fileSelect = false;
  coverImageChangedEvent: any;
  profileImageChangedEvent: any;
  file: any;
  upload: string | undefined;
  afterCrop = false;
  uploadPath: any;
  selectedFilter: string | any = '';

  constructor(
    private storage: StorageService,
    private service: DashboardService,
    private api: ApiService,
    private memberApi: MembersService,
    private datepipe: DatePipe,
    private calender: CalenderService,
    private error_handler: ErrorHelpersService,
    private modalService: NgbModal,
    private uploadApi: UploadService,
    private toastr: ToastrService,
    private titleService: Title
  ){

  }


  onImgError(event: any) {
    event.target.src = 'images/samples/ic_profile.png'
  }

  onCoverImgError(event: any) {
    event.target.src = 'images/icons/transparent_image.png'
  }

  openCoverUploadModal(mediumModalContent: any, type: any) {
    this.upload_image_type = type
    this.modalService.open(mediumModalContent, cropperConfig as NgbModalOptions);
  }


  /**
   * Open the passed popup model.
   *
   * @param mediumModalContent the model to be opened.
   */
  openMediumModal(mediumModalContent: any, data: any) {
    this.current_feed = data
    this.modalService.open(mediumModalContent, { windowClass: "myCustomModalClass" });
  }

  ngOnInit() {
    this.id = this.storage.getJsonValue(USER_ID)
    this.user = this.storage.getJsonValue(USER_SUPER)
    if (this.user.basic_information.dob == "0001-01-01T00:00:00Z")
      this.vaildDate = false
    this.loadDashboardData()
    this.loadPendingData()
    // this.getFeeds()
    this.loadPendingMembers()
    const firstday = this.datepipe.transform(new Date(this.date.getFullYear(), this.date.getMonth(), 1), 'dd-MM-yyyy') || '';
    const lastDay = this.datepipe.transform(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0), 'dd-MM-yyyy') || '';
    this.calender.getCalenderEvents(this.id, firstday, lastDay).subscribe((res: any) => {
      this.events = res?.academy_events
      this.classes = res?.classes
      if (res?.academy_events) {
        this.generateEvents()
      } else{
        this.calenderLoaded = true
      }
    })
    if (this.user.basic_information.name.first_name != "")
      this.academy_name = `${this.user.basic_information.name.first_name} ${this.user.basic_information.name.last_name}`
    this.titleService.setTitle(this.academy_name)
  }

  /**
   * Load the data for the population of the dashboard.
   */
  loadDashboardData() {
    this.service.getDashboardData(this.id).subscribe({
      next:(res: any)=>{
        this.noOfCoach = res.coachs_size
        this.noOfGroups = res.groups_size
        this.noOfStudents = res.students_size
      },
      error:(error)=>{
        this.error_handler.handleError(error)
      }
    })
  }

  /**
   * Load the pending data.
   */
  loadPendingData() {
    this.service.getPending(this.id).subscribe({
      next:(res: any) => {
        this.pending = res
        if (this.pending == null || this.pending == undefined || !this.pending) {
          this.nopendingData = true
        }
      }
    })
  }

  /**
   * Fetch user feeds
   */
  getFeeds() {
    this.galleryLoading = true;
    this.api.fetchFeeds(this.id, this.id).subscribe({
      next:(res: any) => {
        this.user_feed_all = res?.data || [];
        this.filter('');
        this.galleryLoading = false;
        // console.log(this.user_feed)
      },
      error:(err) => {
        this.galleryLoading = false;
        this.error_handler.handleError(err)
      }
    })
  }

  /**
   * Filter data of the user feed.
   * @param filter the filter.
   */
  filter(filter: string) {
    this.selectedFilter = filter;
    this.user_feed = this.user_feed_all.filter((item: { uploads: { type: string; }[]; }) => {
      return item.uploads[0].type.toLowerCase().includes(filter.toLowerCase());
    });
  }

  loadPendingMembers() {
    this.memberApi.getMemberOfId(this.id, {
      filter: "",
      sports: "",
    }).subscribe({
      next:(res: any) => {
        if (res['members'] != null) {
          this.noData = false
          this.dataFetchSuccess = true
          for (const entry of res['members']) {
            const elem = {
              "id": entry['userid'],
              "name": entry['user']['basic_information']['name']['first_name'] + " " + entry['user']['basic_information']['name']['last_name'] || "Name",
              "username": entry['user']['user_name'],
              "dp": entry['user']['basic_information']['dp'],
              "dob": entry['user']['basic_information']['dob'] != "0001-01-01T00:00:00Z" ? this.datepipe.transform(entry['user']['basic_information']['dob'], 'YYYY-MM-dd') : "-",
              "parent": entry['user']['parent_1_id'],
              "page_role": this.getRoleText(entry['profile_type']),
              "invite_status": entry['user']['invite_status'],
              "parent_consent": entry['user']['parent_concern'],
              "isSelected": false
            }
            if (entry['user']["active_status"] == "pending verification") {
              this.member_pending.push(elem)
            }
          }
          this.isLoading = false
        } else{
          this.noData = true
          this.dataFetchSuccess = true
        }
        this.member_pending_all = [...this.member_pending]
      },
      error:(error)=>{
        this.error_handler.handleError(error)
      }
    })
  }

  getRoleText(role: string) {
    switch (role) {
      case "":
        return "Group"
      case "coach":
        return "Coach"
      case "admin":
        return "Admin"
      default:
        return "Student"
    }
  }

  generateEvents() {
    const todayDate = new Date().toISOString().split('T')[0];
    this.events.forEach((element: any) => {
      if (element.event_type === "lesson_plan") {
        if (element.start_date.split('T')[0] === todayDate) {
          this.sports.push(element)
          this.sportsCountToday = (this.sportsCountToday || 0) + 1;
        }
      } else if (element.event_type === "homework") {
        if (element.start_date.split('T')[0] === todayDate) {
          this.homeworks.push(element)
          this.homeworkCountToday = (this.homeworkCountToday || 0) + 1;
        }
      } else if (element.event_type == "personal"){
        if (element.start_date.split('T')[0] === todayDate) {
          this.pds.push(element)
          this.pdCountToday = (this.pdCountToday || 0) + 1;
        }
      }
    });
    this.calenderLoaded = true
  }

  // Image cropper support

  /**
   * Binds the selected image file to the cropper element.
   *
   * Saves the selected file for furthur use.
   * @param event File select event.
   */
  fileChangeEvent(event: any, isCoverImage: boolean): void {
    const fileName = (document.getElementById("myfileid") as HTMLInputElement).value;
    const idxDot = fileName.lastIndexOf(".") + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      this.fileSelect = false
      if (isCoverImage) {
        this.coverImageChangedEvent = event;
      } else {
        this.profileImageChangedEvent = event;
      }
      // this.imageChangedEvent = event;
      this.file = event.target.files
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  }

  /**
   * Opens a file browser
   *
   * @returns
   */
  openFileSelect() {
    // $('#myfileid').trigger('click');
    this.myButton.nativeElement.click();
    return true;
  }


  /**
   * Called after image has been cropped.
   *
   * Text on the button is changed.
   * Boolen is set to denote that image is cropped.
   * Image upload process is started.
   */
  async uploadCropped() {
    if (this.file) {
      this.upload = "Class Image Uploaded!"
      this.afterCrop = true
      // var date = new Date()
      // var filepath = this.storage.getJsonValue(USER_ID).concat(`/${this.upload_image_type}/${this.file[0].name}`)
      let filepath = this.storage.getJsonValue(USER_ID).concat(`/${this.upload_image_type}`)
      filepath = filepath.replace(/ /g, "_");
      // console.log(filepath, this.croppedImage, this.upload_image_type)
      const upload_response = await this.uploadApi.azureuploadCropped(this.file[0], storage_user, container_user, filepath, this.croppedImage)
      this.uploadPath = upload_response.uploadpath
      this.service.editUserImage(this.id, this.upload_image_type, this.uploadPath).subscribe({
        next:(res: any) => {
          this.error_handler.logger(res)
          if (this.upload_image_type === "dp") {
            this.user.basic_information.dp = this.uploadPath
            this.storage.setJsonValue(USER_DP, this.uploadPath)
          } else {
            this.user.basic_information.cover_photo = this.uploadPath
          }
          this.storage.setJsonValue(USER_SUPER, this.user)
        }
      })
    } else {
      this.toastr.warning(`Please select a image to upload.`, 'Choose a Image', {
        progressBar: true,
        closeButton: true,
      })
    }
  }


  imageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(!event.objectUrl?"": event.objectUrl);
    this.croppedImage = event.base64
    // event.blob can be used to upload the cropped image
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
      this.error_handler.logger(image)
  }
  cropperReady() {
      // cropper ready
      this.error_handler.logger('cropper component ready')
  }
  loadImageFailed() {
      // show message
      this.error_handler.handleError('image load error')
  }
}
