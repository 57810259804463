<div class="d-flex align-items-stretch auth auth-img-bg h-100">
  <div class="row flex-grow w-100 p-0 m-0">
      <div class="col-lg-6 d-flex align-items-center justify-content-center">
          <div class="auth-form-transparent text-left p-3" *ngIf="isAccpect">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Thank you!</h4>
              <h6 class="font-weight-light">Dartle thanks you for allowing us to save your child's data, be assured
                  that we will be doing our best to ensure your child data is most secure with us.
                  <br>
                  <br>
                  You can get Dartle here.
              </h6>
              <div class="mt-4 flex">
                  <a class="image-container clickable" href="{{pwa_url}}">
                      <img src="images/icons/web.png" class="platform-image" alt="">
                  </a>
                  <!-- <div class="image-container clickable">
                      <img src="images/icons/web.png" class="platform-image" routerLink="/">
                  </div> -->
              </div>
          </div>
          <div class="auth-form-transparent text-left p-3" *ngIf="isReject">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Oh.....</h4>
              <h6 class="font-weight-light">Sorry, but for your child to attend your choosen Academy we need to save
                  some personal data.
                  <br>And you have choosen to disagree with our privacy policy.<br>
                  Nevertheless you can request again if you change your mind.
                  <br>
                  <br>
                  You can see our privay policy <a href="https://dartle.app/privacy-policy.pdf"
                      style="color: #F36A3C;" target="_blank">here.</a>
              </h6>
          </div>
      </div>
      <div class="col-lg-6 forgot-reset-half-bg d-flex flex-row">
      </div>
  </div>
</div>
