/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembersService } from '../../members/members.service';
import { SharedModule } from '../../shared/shared.moudle';
import { ErrorHelpersService } from '../../../helpers/error-helpers.service';

@Component({
  selector: 'app-gallery',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss'
})
export class GalleryComponent implements OnInit {

  memberId = '';

  titles: any = {
    home_feed: "Homework Attachments",
    pd_feed: "Personal Development",
    tagged_feed: "Posts Tagged",
    user_feed: "Posts"
  }

  data: any = [];
  is_loading = false

  constructor(
    private router: Router,
    private memberApi: MembersService,
    private logger: ErrorHelpersService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.memberId = this.activatedRoute.snapshot.paramMap.get('id') || ""
    this.getGallerySummary();
  }

  onImgError(event: any, elem: any) {
    event.target.src = 'images/icons/gallery_empty.png'
    elem.is_load_error = true
  }

  redirectToGalleryDetails(type: string) {
    this.router.navigate([
      this.router.url +
      "/" +
      type.toLowerCase().split(" ").join("-")
    ]);
  }

  getGallerySummary() {
    this.memberApi.getGallerySummaryForMember(this.memberId).subscribe({
      next: (res: any) => {
        this.data = Object.entries(res.data).map((gm: any) => ({
          url: gm[1]?.feeds?.[0]?.uploads?.[0]?.thumbnail_url || gm[1]?.feeds?.[0]?.uploads?.[0]?.url,
          title: this.titles[gm[0]] || "Untitled Album",
          photo_count: gm[1].image_count,
          video_count: gm[1].video_count
        }));
        this.is_loading = true
        this.logger.logger(this.data)
      },
      error: (e) => {
        this.logger.handleError(e)
        this.is_loading = true
        this.data = []
      }
    })
  }
}
