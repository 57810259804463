/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  API_URL = `${environment.apiURL}`

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Get member of a given id.
   *
   * @param id user id of the user to be fetched.
   * @returns list of members.
   */
  getGroupsOfId(id: string) {
    return this.http.get(`${this.API_URL}/groups/${id}`);
  }

  /**
   * Get Associated groups of a ID
   *
   * @param id user id of the user to be fetched.
   * @returns list of Associated Groups.
   */
  getAssociatedGroupsOfId(id: string) {
    return this.http.get(`${this.API_URL}/groups/${id}/association`);
  }

  getCoachMembers(id: string, classid: string) {
    const params = new HttpParams()
      .append("classid", classid)
    return this.http.get(`${this.API_URL}/groups/${id}/members`, { params: params });
  }

  getMembersActivityScore(id: string, classid: string, module: string, session: string, activity_group: string, activity: string) {
    const params = new HttpParams()
      .append('module_id', module)
      .append('session_id', session)
      .append('activity_group_id', activity_group)
      .append('activity_id', activity)
    return this.http.get(`${this.API_URL}/lesson-scores/${id}/sports/${classid}`, { params: params });
  }

  editMembersActivityScore(id: string, classid: string, data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.put(`${this.API_URL}/lesson-scores/${id}/sports/${classid}`, data, config)
  }

  /**
   * Create a group from the given data.
   *
   * @param group json containing all the info to create a Group.
   * @param id id of the academy.
   * @returns status of the group creation.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createGroup(group: any, id: any) {
    const httpParams = new HttpParams()
      .append("dp", group.dp)
      .append("bio", group.bio)
      .append("name", group.name)
      .append("sport", group.sport)
      .append("supervisor", group.supervisor)
    return this.http.post(this.API_URL + `/groups/` + id, httpParams);
  }

  /**
   * Add a given member of the academy to the specified Group.
   *
   * @param id Group object id
   * @param mid Member object id
   * @param role role type ['member', 'coach']
   * @param academy Academy object id
   * @returns
   */
  addMemberToGroup(id: string, mid: string, role: string, academy: string) {
    const httpParams = new HttpParams()
      .append("mid", mid)
      .append("role", role)
      .append("academy", academy)
    return this.http.post(`${this.API_URL}/groups/${id}/add`, httpParams);
  }

  /**
   * Add a multiple members of the academy to the specified Group.
   *
   * @param id Group object id
   * @param data Json Object
   * @returns
   */
  addMemberToGroupMultiple(id: any, data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/groups/${id}/addmulti`, data, config);
  }

  /**
   * Swap a given coach of the academy to another.
   *
   * @param id User id of the academy.
   * @param mid User id of the Coach to be the supervisor.
   * @returns Status of the member allocation.
   */
  swapCoach(id: any, mid: any) {
    return this.http.get(this.API_URL + `/groups/` + id + `/` + mid);
  }

  /**
   * Removes a given member from the specific group.
   *
   * @param id User id of the academy.
   * @param mid User id of the member.
   * @returns Status of the member removal.
   */
  removeMemberToGroup(id: any, mid: string, academy: string) {
    const httpParams = new HttpParams()
      .append("mid", mid)
      .append("classid", id)
    return this.http.post(`${this.API_URL}/groups/${academy}/remove`, httpParams);
  }

  /**
   * Removes a given member from the specific group.
   *
   * @param id User id of the academy.
   * @param mid User id of the member.
   * @returns Status of the member removal.
   */
  removeGroup(mid: string, academy: string) {
    const httpParams = new HttpParams()
      .append("mid", mid)
    return this.http.post(`${this.API_URL}/groups/${academy}/remove_group`, httpParams);
  }

  /**
   * Gets the attendance of the session
   *
   * @param academy User id of the academy.
   * @param class_id User id of the class.
   * @param session session id of the lessonplan.
   * @returns
   */
  getAttendanceForSession(academy: string, class_id: string, session: string) {
    return this.http.get(`${this.API_URL}/lesson-attendance-class/${academy}/${class_id}/${session}`)
  }

  /**
   * Edit the attendance of the session
   *
   * @param academy User id of the academy.
   * @param class_id User id of the class.
   * @returns
   */
  editAttendanceForSession(data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/lesson-attendance-class`, data, config);
  }

  markAttendanceForSession(academy: string, class_id: string, user: string, module: string, session: string, is_present: string) {
    return this.http.get(`${this.API_URL}/lesson-attendance-class/${academy}/${class_id}/${user}/${module}/${session}/${is_present}`)
  }
}
