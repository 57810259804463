import { HttpInterceptorFn } from '@angular/common/http';
import { catchError } from 'rxjs';
import { ErrorHelpersService } from '../helpers/error-helpers.service';
import { inject } from '@angular/core';
import { SESSION_ID } from '../data-models/user-data';
import { StorageService } from '../services/storage.service';
import { AuthenticationService } from '../services/authentication.service';

// Method to obfuscate (Base64 encode) the request body
const obfuscateBase64 = (data: string) =>{
  return btoa(data);
}

// Method to unobfuscate (Base64 decode) the response body
const unobfuscateBase64 = (data: string) =>{
  return atob(data);
}

export const httpHelperInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(ErrorHelpersService);
  const storage = inject(StorageService);
  const auth = inject(AuthenticationService);

  var session = storage.getJsonValue(SESSION_ID)
  // console.log(session, this.auth.getLoginData() && session && !req.url.includes('zipcodebase'), this.auth.getLoginData(), session, !req.url.includes('zipcodebase'))
  if (auth.getLoginData() && session && !req.url.includes('zipcodebase')) {
    req = req.clone({
      setHeaders: { "Authorization": 'Bearer ' + `${session}` }
    });
    // console.log(req)
  }
  // let clonedRequest = req;

  // // Obfuscate the request body if it exists and is a string
  // if (req.body && typeof req.body === 'string') {
  //   const encodedBody = obfuscateBase64(req.body);
  //   clonedRequest = req.clone({ body: encodedBody });
  // }
  return next(req).pipe(
    catchError((err: any) => {
      authService.handleHttpsError(err)
      throw err;
    })
  );
};
