<p>dashboard works!</p>
<p>{{ today | dateTime }}</p>
<p>{{ today | dateTime: 'short':'UTC':'de-DE' }}</p>
<p>{{ today | dateTime: 'medium':'GMT':'de-DE' }}</p>
<p>{{ today | dateTime: 'shortDate':'America/New_York':'en-US' }}</p>
<p>{{ today | dateTime: 'shortDate':'Europe/London':'en-GB' }}</p>


<br>
<br>
<br>

<p>{{ amount | currencyLocale }}</p>
<p>{{ amount | currencyLocale:'EUR' }}</p>
<p>{{ amount | currencyLocale:'USD':"1.3-3":"en-US" }}</p>
<p>{{ amount | currencyLocale:'USD':"":"en-US" }}</p>
<p>{{ amount | currencyLocale:'INR' }}</p>

<br>
<br>
<br>

<p>{{ tile | string: 'uppercase'}}</p>
<p>{{ tile | string: 'lowercase'}}</p>
<p>{{ tile | string: 'title'}}</p>
<p>{{ tile | string: 'sentence'}}</p>
<p>{{ tile | string: 'camel'}}</p>

<br>
