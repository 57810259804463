<div class="strength">
  <ul class="strengthBar">
      <li class="point" [style.background-color]="bar0"></li>
      <li class="point" [style.background-color]="bar1"></li>
      <li class="point" [style.background-color]="bar2"></li>
      <li class="point" [style.background-color]="bar3"></li>
  </ul>
  <br>
</div>
<div>
  <p class="password-text">Password Strength : <span class="password-strength"
          [style.color]="bar0">{{strength}}</span> </p>
</div>

<div class="req-container">
  <div class="req" [ngClass]="upperLetters ? 'correct' : 'wrong' ">
      <!-- <input class="req-check" type="checkbox" [(ngModel)]="upperLetters" [ngModelOptions]="{standalone: true}" onclick="return false;" /> -->
      <label class="checkbox-label">
          <input type="checkbox" [(ngModel)]="upperLetters" [ngModelOptions]="{standalone: true}"
              onclick="return false;">
          <span class="checkbox-custom circular"></span>
      </label>
      <p class="req-text">Uppercase letter</p>
  </div>
  <div class="req" [ngClass]="lowerLetters ? 'correct' : 'wrong' ">
      <!-- <input class="req-check" type="checkbox" [(ngModel)]="upperLetters" [ngModelOptions]="{standalone: true}" onclick="return false;" /> -->
      <label class="checkbox-label">
          <input type="checkbox" [(ngModel)]="lowerLetters" [ngModelOptions]="{standalone: true}"
              onclick="return false;">
          <span class="checkbox-custom circular"></span>
      </label>
      <p class="req-text">Lowercase letter</p>
  </div>
  <div class="req" [ngClass]="numbers ? 'correct' : 'wrong' ">
      <!-- <input class="req-check" type="checkbox" [(ngModel)]="numbers" [ngModelOptions]="{standalone: true}" onclick="return false;" /> -->
      <label class="checkbox-label">
          <input type="checkbox" [(ngModel)]="numbers" [ngModelOptions]="{standalone: true}" onclick="return false;">
          <span class="checkbox-custom circular"></span>
      </label>
      <p class="req-text">Must contain a number</p>
  </div>
  <div class="req" [ngClass]="length ? 'correct' : 'wrong' ">
      <!-- <input class="req-check" type="checkbox" [(ngModel)]="length" [ngModelOptions]="{standalone: true}" onclick="return false;" /> -->
      <label class="checkbox-label">
          <input type="checkbox" [(ngModel)]="length" [ngModelOptions]="{standalone: true}" onclick="return false;">
          <span class="checkbox-custom circular"></span>
      </label>
      <p class="req-text">At least 6 characters long</p>
  </div>
  <div class="req" [ngClass]="symbols ? 'correct' : 'wrong' ">
      <!-- <input class="req-check" type="checkbox" [(ngModel)]="length" [ngModelOptions]="{standalone: true}" onclick="return false;" /> -->
      <label class="checkbox-label">
          <input type="checkbox" [(ngModel)]="symbols" [ngModelOptions]="{standalone: true}" onclick="return false;">
          <span class="checkbox-custom circular"></span>
      </label>
      <p class="req-text">Special characters</p>
  </div>
</div>
