/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MainComponent } from "./modules/main/main.component";
import { AuthenticationService } from './services/authentication.service';
import { CLASS, UserSuperData } from './data-models/user-data';
import { StorageService } from './services/storage.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MainComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'dartle';
  classe_name: UserSuperData | undefined;
  lessonPlan: any;
  activity: any;

  constructor(
    public auth: AuthenticationService,
    private updates: SwUpdate,
    private storage: StorageService
  ){
    // console.log("evt")
    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
        default:
          console.log(`Current app version:`, evt.version);
          break;
      }

      // console.log(evt)
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.auth.updatePermissionsForUser();

    // When role data in local storage changes, refresh user permissions
    window.addEventListener('onRoleUpdate', () => {
      this.auth.updatePermissionsForUser();
    });
    console.log("swUpdate:",this.updates.isEnabled)

    if (this.updates.isEnabled) {
      console.log("enabled")
      this.updates.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          const userConfirmed = confirm('A new version of the app is available. Load the new version?');
          if (userConfirmed) {
            window.location.reload();
          }
        }
      });
    }
  }

  setClasseName(classe_name: UserSuperData) {
    this.storage.setJsonValue(CLASS, classe_name)
    this.classe_name = classe_name
  }

  getClasseName() {
    return this.storage.getJsonValue(CLASS)
  }

  setLesson(lesson: any) {
    this.lessonPlan = lesson
  }

  getLesson() {
    return this.lessonPlan
  }

  setActivity(activity: any) {
    this.activity = activity
  }

  getActivity() {
    return this.activity
  }
}
