import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme = 'light';
  localStorage = document.defaultView?.localStorage;

  constructor() {
    this.loadTheme();
  }

  setTheme(theme: string) {
    document.documentElement.setAttribute('data-theme', theme);
    this.currentTheme = theme;
    if(this.localStorage){
      this.localStorage.setItem('theme', theme);
    }
  }

  private loadTheme() {
    if(this.localStorage){
      const savedTheme = this.localStorage.getItem('theme');
      this.setTheme(savedTheme || this.currentTheme);
    }
  }
}
