/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, computed, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { StorageService } from '../../../services/storage.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router, RouterModule } from '@angular/router';
import { USER_DISPLAY_NAME, USER_DP, USER_ID } from '../../../data-models/user-data';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
// import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {
  is_parent = false
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  dp: any
  display_name: any
  id: any;
  collapsed = signal(false);
  @Output() collapsedChange = new EventEmitter<boolean>();
  screenWidth = signal(0);
  isMobile = computed(()=>(this.screenWidth() <=1200))

  constructor(
    // config: NgbDropdownConfig,
    private storage: StorageService,
    private router: Router,
    private auth: AuthenticationService
  ) {
    // config.placement = 'bottom-right';
  }

  ngOnInit(): void {
    this.screenWidth.set(window.innerWidth);
    if(this.isMobile()){
      this.toggle()
    }
    this.auth.getEmittedValue().subscribe((res: any) => {
      this.is_parent = res
    })
    this.display_name = this.storage.getJsonValue(USER_DISPLAY_NAME)
    this.dp = this.storage.getJsonValue(USER_DP)
    this.id = this.storage.getJsonValue(USER_ID)

  }

  toggle() {
    this.collapsed.update(value => !value);
    this.collapsedChange.emit(this.collapsed());
  }

  onImgError(event: any) {
    event.target.src = 'images/placeholders/profile-pics/ph_user_square.png'
  }

  logout() {
    this.auth.logout()
  }

  switcher() {
    this.router.navigate(['/switch']);
  }

  toggleOffcanvas() {
    const side = document.querySelector('.sidebar-offcanvas')
    side?.classList.toggle('active');
  }
}
