import { Component, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MenuItem } from '../../sidebar.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { SharedModule } from '../../../shared.moudle';

@Component({
  selector: 'app-menu-item',
  standalone: true,
  animations:[
    trigger('exapandContractMenu',[
      transition(':enter',[
        style({opacity:0, height: '0px'}),
        animate('0.325s ease-in-out', style({opacity:1, height:'*'}))
      ]),
      transition(':leave', [
        animate('0.325s ease-in-out', style({opacity:0, height: '0px'}))
      ])
    ])
  ],
  imports: [
    MatIconModule,
    MatListModule,
    RouterModule,
    SharedModule
  ],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss'
})
export class MenuItemComponent {
  item = input.required<MenuItem>()
  collasped = input(false)
  nestedMenuOpen = signal(false)
  isMobileView = input(false)

  toggleNested() {
    if(!this.item().subItems){
      return
    }
    this.nestedMenuOpen.set(!this.nestedMenuOpen())
  }
}
