<div class="d-flex align-items-stretch auth auth-img-bg h-100">
  <div class="row flex-grow w-100 m-0">
      <div class="col-lg-6 d-flex align-items-center justify-content-center">
          <div class="auth-form-transparent text-left p-3">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Check your Mail</h4>
              <h6 class="font-weight-light">We have sent you the Instructions to reset your Password.</h6>
              <div>
                  <div class="my-3">
                      <button class="btn btn-block btn-outline-primary btn-lg font-weight-medium auth-form-btn webkit-btn" routerLink="/login"
                          type="submit">Go back to login</button>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-lg-6 forgot-landing-half-bg d-flex flex-row">
      </div>
  </div>
</div>
