import { Component } from '@angular/core';
import { SharedModule } from '../shared.moudle';

@Component({
  selector: 'app-playground',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './playground.component.html',
  styleUrl: './playground.component.scss'
})
export class PlaygroundComponent {
  today = new Date()
  amount = 5499000000.99
  tile = "We Build a New Program"
}
