/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environments';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../services/authentication.service';
import { SharedModule } from '../../shared/shared.moudle';
import { PasswordStrengthComponent } from '../password-strength/password-strength.component';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    SharedModule,
    PasswordStrengthComponent
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  token: any
  type: any
  isReset = false
  isGenerate = false
  isExpired = false
  isCompleted = false
  isCompletedGenerate = false

  lowerLetters = false
  upperLetters = false
  numbers = false
  symbols = false
  equals = false
  length = false
  id: any
  user: any

  resetForm: FormGroup | any;
  show = false;
  submmited = false;
  not_a_Player = true
  is_active = false;
  pwa_url: string = environment.pwa_url
  base_url: string = environment.base

  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private toastr: ToastrService
  ) { }


  ngOnInit(): void {

    this.token = this.route.snapshot.paramMap.get('token')
    this.type = this.route.snapshot.paramMap.get('type')
    this.isReset = this.type.toLowerCase() === "reset"
    this.isGenerate = !this.isReset

    this.resetForm = new FormGroup({
      newpassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      comfrimpassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    })

    this.auth.verifyToken(this.token).subscribe((res: any) => {
      if (res["token"] === "expired") {
        this.isExpired = true
        this.isReset = false
        this.isCompleted = false
        this.isGenerate = false
        this.toastr.error("Password reset link has been expired.", 'Reset Link Expired', {
          progressBar: true,
          closeButton: true
        })
      } else {
        this.auth.fetchUserId(res['token']["Claims"]["username"]).subscribe((res) => {
          this.id = res.id
          this.user = res
          this.not_a_Player = !(res.profile_type.some(e => e === 'Player' || e === "Parent"))
          this.is_active = res.active_status === "active"
          // console.log(this.not_a_Player)
          this.toastr.success("Password reset link is active.", 'Reset Link Active', {
            progressBar: true,
            closeButton: true
          })
        });
      }
    })
  }

  password() {
    this.show = !this.show;
  }

  onLogin() {
    this.submmited = true
    const isValid = this.checkStrength(this.resetForm.controls.newpassword.value, this.resetForm.controls.comfrimpassword.value)
    if (this.equals) {
      this.toastr.error("Passwords do not match.", 'Password Mismatch!', {
        progressBar: true,
        closeButton: true
      })
      return;
    }
    if (isValid) {
      if (this.is_active) {
        this.auth.changePasswordNotAcademy(this.id, this.resetForm.controls.newpassword.value).subscribe(() => {
          this.toastr.success("Your password has been changed sucessfully.", 'Password Changed!', {
            progressBar: true,
            closeButton: true
          })
          if (this.isReset) {
            this.isExpired = false
            this.isReset = false
            this.isCompleted = true
            this.isCompletedGenerate = false
            this.isGenerate = false
            // console.log(`isRest not academy`)
          } else {
            this.isExpired = false
            this.isReset = false
            this.isCompleted = false
            this.isCompletedGenerate = true
            this.isGenerate = false
            // console.log(`notRest not academy`)
          }
          // console.log({
          //   isExpired: this.isExpired,
          //   isReset: this.isReset,
          //   isCompleted: this.isCompleted,
          //   isCompletedGenerate: this.isCompletedGenerate,
          //   isGenerate: this.isGenerate,
          // })
        })
      } else {
        this.auth.changePassword(this.id, this.resetForm.controls.newpassword.value).subscribe(() => {
          this.toastr.success("Your password has been changed sucessfully.", 'Password Changed!', {
            progressBar: true,
            closeButton: true
          })
          if (this.isReset) {
            this.isExpired = false
            this.isReset = false
            this.isCompleted = true
            this.isCompletedGenerate = true
            this.isGenerate = false
            // console.log(`isREst Academy`)
          } else {
            this.isExpired = false
            this.isReset = false
            this.isCompleted = false
            this.isCompletedGenerate = true
            this.isGenerate = false
            // console.log(`not reset Acaemdy`)
          }
          // console.log({
          //   isExpired: this.isExpired,
          //   isReset: this.isReset,
          //   isCompleted: this.isCompleted,
          //   isCompletedGenerate: this.isCompletedGenerate,
          //   isGenerate: this.isGenerate,
          // })
        })
      }
    } else {
      this.toastr.error("Your password is not strong enough.", 'Weak Password!', {
        progressBar: true,
        closeButton: true
      })
    }
  }


  checkStrength(p: any, cp: any) {
    const regex = /[$-/:-?{-~!"^_@`[\]]/g;
    this.lowerLetters = /[a-z]+/.test(p);
    this.upperLetters = /[A-Z]+/.test(p);
    this.numbers = /[0-9]+/.test(p);
    this.symbols = regex.test(p);
    this.length = p.length > 6
    this.equals = !(cp === p)
    return this.lowerLetters && this.upperLetters && this.numbers && this.symbols && this.length && !this.equals
  }
}
