/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MemberAccessControl } from '../../auth/access-control';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
import { StorageService } from '../../../services/storage.service';
import { ReportsService } from '../../reports/reports.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { GroupsService } from '../../groups/groups.service';
import { MembersService } from '../members.service';
import _ from 'lodash';
import { LOCALIZATION, USER_ID } from '../../../data-models/user-data';
import { ErrorHelpersService } from '../../../helpers/error-helpers.service';
import { SharedModule } from '../../shared/shared.moudle';
import { LoaderService } from '../../../services/loader.service';
import { COUNTRY_CODES } from '../../../data-models/country-codes';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  memberAccessControl = MemberAccessControl;

  addEducation!: FormGroup;
  preferedFoods!: FormGroup;
  addAchievments!: FormGroup;
  editContact!: FormGroup;
  editProfile!: FormGroup;
  visible: boolean| any = false
  contact: boolean| any = false
  preference: boolean| any = false
  educationalQ: boolean | any= false
  // carrier:boolean = false
  achievment: boolean| any = false
  date: Date = new Date();
  username: any = ""; usernameValid: boolean| any = true;
  gender: any = ""; genderValid: boolean| any = true;
  fname: any = ""; fnameValid: boolean| any = true;
  lname: any = ""; lnameValid: boolean | any= true;
  dateValid: boolean | any = true;

  id: any
  userid: any
  user: any = {}
  achievement: any = {}
  homework: any = {}
  members: any = []
  coachs: any = []
  sports: any = []
  ach_limit: number | any = 2
  a_g_limit: number | any = 3
  a_c_limit: number | any = 3
  a_s_limit: number | any = 3
  datePickersettings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }

  personal_dev_hours: any = 0
  personal_dev_current: any = 0
  personal_dev_total: any = 0

  attendance_dev_percentage: any = 0
  attendance_dev_current: any = 0
  attendance_dev_total: any = 0

  homework_completed: any = []
  homework_pending: any = []

  user_feed_all: any = []
  user_feed: any = []
  user_feed_loading = true;
  vaildDate: boolean | any = true
  addressEmpty: boolean | any = false;

  attendenceDetails: any;

  academicStatsLoading = true;
  academicStats: any;
  selectedYear = new Date();
  selectedTerm: string | any = "";

  targetAchievedCountForGrades = 0;
  totalSubjectsCount = 0;
  pdStats: any;
  pdStatSessions: any;
  OverSession: any = [];
  OverSessionAll: any = [];
  _id: string | any;
  class_id: string | any = null;
  dev_hours: any = 0;

  is_loading_achievments: boolean | any = true;
  is_loading_preferences: boolean | any = true;
  is_loading_sports_info: boolean | any = true;
  is_loading_educational: boolean | any = true;
  // is_loading_contacts: boolean = true;
  is_loading_bio: boolean | any = true;

  prefered_position: any;
  educational: any = []
  education: any = []

  submitted: boolean | any = false
  dobEdit: boolean | any = true
  parent_edit: boolean | any = false
  contact_edit: boolean | any = false
  classes: any = []
  class_name: any = "Select a class"
  overall_info: any = {}

  maxYear: Date = new Date();

  @ViewChild('picker', { static: false })
  private picker!: MatDatepicker<Date>;
  profile_report: any = {};
  phone: any = {
    country_code: "",
    number: "",
    is_primary: true,
  };
  characteristics_edit: boolean | any = false;
  createClick: boolean | any = false;

  originalContactDetails: any = {};
  phoneCode: any = COUNTRY_CODES
  filteredOptionsPhoneCodes:  Observable<any[]> | undefined;
  phoneCodeControl = new FormControl('');
  locale_data: any

  constructor(
    private reportsapiService: ReportsService,
    public datepipe: DatePipe,
    private storage: StorageService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private titleService: Title,
    private groupApi: GroupsService,
    private api: ApiService,
    private memberApi: MembersService,
    private logger: ErrorHelpersService,
    private loadingService: LoaderService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // this.date.setFullYear(this.date.getFullYear() - 9);
    this.id = this.storage.getJsonValue(USER_ID)
    this.userid = this.route.snapshot.paramMap.get('id')
    this.class_id = this.route.snapshot.queryParamMap.get('class_id') || null
    this.user.basic_information = {
      email: "",
      gender: "",
      name: "",
      address: [{
        addr1: "",
        city: "",
        district: "",
        pincode: "",
        state: "",
      }],
    }
    this.user.sports = [{ sport_name: "" }]
    this.achievement.basic_information = {
      email: "",
      gender: "",
      address: [{
        addr1: "",
        city: "",
        district: "",
        pincode: "",
        state: "",
      }],
      achievement: []
    }
    this.locale_data = this.storage.getJsonValue(LOCALIZATION)
    this.getUserData()
    // this.getParentData()
    this.getUserAchievement()
    // this.getUserPdDetails();
    // this.getUserHomework()
    // this.getFeeds()
    // this.getUserAttendence();
    // this.getAcademicStats()

    this.formsCollection()
    this.loadEducational()
    if (this.class_id) {
      this.loadOverallReport()
    }
  }

  loadFilters(){
    this.initLocaleData()
    this.filteredOptionsPhoneCodes = this.phoneCodeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterPhoneCodes(value || '')),
    );
  }

  private _filterPhoneCodes(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.phoneCode.filter((option: any) => option.name.toLowerCase().includes(filterValue) || option.dial_code.toLowerCase().includes(filterValue));
  }

  initLocaleData() {
    this.phoneCodeControl.setValue(this.locale_data.phone)
    // console.log(this.locale_data)
  }

  loadOverallReport() {
    this.reportsapiService.getOverallReport(this.class_id, this.userid).subscribe((res: any) => {
      this.profile_report = res["profile_info"] || []
      // this.class_info = res["class_info"] || []
      // this.coach_infos = res["coach_infos"] || []
      // this.pd_info = res["pd_info"] || []
      // this.getGender(this.profile_report?.basic_information?.gender)
    })
  }

  formsCollection() {
    this.addEducation = new FormGroup({
      university: new FormControl('', [Validators.required]),
      course: new FormControl('', [Validators.required]),
      year_in: new FormControl('', [Validators.required]),
      year_out: new FormControl('', [Validators.required]),
    })

    // this.editContact = new FormGroup({
    //   phone: new FormControl('', [Validators.required]),
    //   mail: new FormControl('', [Validators.required]),
    //   address: new FormControl('', [Validators.required]),
    // })

    this.editProfile = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl(''),
      user_name: new FormControl(''),
      date_of_birth: new FormControl(new Date(), [Validators.required]),
      gender_: new FormControl('', [Validators.required]),
    })

    this.editProfile.get('user_name')?.disable();

    // this.addAchievments = new FormGroup({
    //   achievment_name: new FormControl('', [Validators.required]),
    //   year: new FormControl(new Date(), [Validators.required]),
    // })

    this.preferedFoods = new FormGroup({
      prefered_foods: new FormControl('', [Validators.required]),
    })

  }

  parentEdit() {
    this.parent_edit = !this.parent_edit
  }

  contactEdit() {
    if (!this.contact_edit) {
      this.originalContactDetails = {
        phone: { ...this.phone },
        alternative_email: this.user.basic_information.alternative_email,
        address: this.user.basic_information.address ? [...this.user.basic_information.address] : [{
          addr1: "",
          addr2: "",
          city: "",
          district: "",
          state: "",
          pincode: "",
          country_id: "5ddf772af7cbe64bdc7e0bbd",
          country: "",
        }],
      };
    } else {
      // Restore original values if cancelling
      this.phone = { ...this.originalContactDetails.phone };
      this.user.basic_information.alternative_email = this.originalContactDetails.alternative_email;
      this.user.basic_information.address = [...this.originalContactDetails.address];
    }
    this.contact_edit = !this.contact_edit;
  }

   contactEditDone(){
    this.contact_edit = !this.contact_edit;
    this.originalContactDetails = {
      phone: { ...this.phone },
      alternative_email: this.user.basic_information.alternative_email,
      address: [...this.user.basic_information.address],
    };
   }

  characteristicsEdit() {
    this.characteristics_edit = !this.characteristics_edit
  }

  updateBios() {
    const data = {
      dob: this.user.basic_information.dob,
      gender: this.user.basic_information.gender,
      height: +this.user.basic_information.height,
      weight: +this.user.basic_information.weight,
      bmi: +this.user.basic_information.bmi,
      blood_type: this.user.basic_information.blood_type,
    }
    // console.log(data)
    this.api.editBios(this.userid, data).subscribe({
      next: () => {
        this.characteristicsEdit()
      }
    })
  }

  updateParentEdit() {
    if(this.user.parent_1_info.basic_information.name.first_name.trim() === ""){
      this.toastr.warning(`Parent first name is mandatory for parent info.`, 'Parent name required!', {
        progressBar: true,
        closeButton: true,
      });
      return
    } else if(this.user.parent_1_info.basic_information.email.toLowerCase().trim() === ""){
      this.toastr.warning(`Parent email is mandatory for parent info.`, 'Parent email required!', {
        progressBar: true,
        closeButton: true,
      });
      return
    }
    const user_data = {
      "_id": this.user.parent_1_info._id,
      "basic_information": {
        "name": {
          "first_name": this.user.parent_1_info.basic_information.name.first_name.trim(),
          "middle_name": this.user.parent_1_info.basic_information.name.middle_name.trim(),
          "last_name": this.user.parent_1_info.basic_information.name.last_name.trim(),
          "display_name": this.user.parent_1_info.basic_information.name.display_name
        },
        "email": this.user.parent_1_info.basic_information.email.toLowerCase().trim()
      },
      "user_name": this.user.parent_1_info.user_name
    }
    // console.log(user_data)
    this.api.editPersonal(user_data, this.user.parent_1_info._id).subscribe(() => {
      this.toastr.success(`Parent info has been edited.`, 'Profile edited!', {
        progressBar: true,
        closeButton: true,
      })
      this.parent_edit = false
    })

  }

  preferenceEdit() {
    this.preference = !this.preference
  }
  educationalQuali() {
    this.educationalQ = !this.educationalQ
  }
  personalEdit() {
    this.visible = !this.visible
    this.getUserData();
    this.formsCollection()
  }

  onImgError(event: any) {
    event.target.src = 'images/samples/ic_profile.png'
  }


  generateUsername() {
    if (this.editProfile.valid) {
      if (this.editProfile.controls['last_name'].value && this.editProfile.controls['first_name'].value) {
        this.username = `${this.editProfile.controls['first_name'].value}.${this.editProfile.controls['last_name'].value}`;
      } else if (this.editProfile.controls['first_name'].value) {
        this.username = `${this.editProfile.controls['first_name'].value}`;
      } else {
        this.username = '';
      }
      this.username = this.username.replace(/\s+/g, '-').toLowerCase();
      this.checkUserName(true);
    }
  }


  checkUserName(generate = false) {
    this.auth.checkUsername(this.username).subscribe((res: any) => {
      if (res.success) {
        if (generate) {
          // console.log(this.usernameValid)
          this.username = `${this.username}${this.makeRandom(4)}`
          // console.log(this.username)
        } else {
          this.usernameValid = true
        }
      } else {
        this.usernameValid = false
      }
    })
  }

  makeRandom(lengthOfCode: number) {
    // let possible = "abcdefghijklmnooqrstuvwxyz1234567890.-&$!";
    const possible = "1234567890.-&$!";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


  editProfileInfo() {
    this.submitted = true
    this.fnameValid = this.editProfile.controls['first_name'].value.trim().length == 0
    this.lnameValid = this.editProfile.controls['last_name'].value.trim().length == 0
    const isUsername = this.editProfile.controls['user_name'].value.length == 0
    const dob = new Date(this.editProfile.controls['date_of_birth'].value);
    const today = new Date();
    if (dob < today) {
      this.dateValid = false
    }
    const isNameValid = !isUsername && !this.fnameValid

    const isoDob = this.datepipe.transform(dob, 'YYYY-MM-dd')
    if (this.submitted && isNameValid && !this.dateValid) {
      const data1 = {
        "dob": isoDob,
        "gender": this.editProfile.controls['gender_'].value,
      }
      // console.log(data1)
      this.api.editDobGender(data1, this.userid).subscribe(() => {
        // this.toastr.success(`Personal info has been edited.`, 'Profile edited!', {
        //   progressBar: true,
        //   closeButton: true,
        // })
      })
      const user_data = {
        "_id": this.userid,
        "basic_information": {
          "name": {
            "first_name": this.editProfile.controls['first_name'].value.trim(),
            "middle_name": "",
            "last_name": this.editProfile.controls['last_name'].value.trim(),
            "display_name": ""
          },
          "email": this.user.basic_information.email.toLowerCase().trim()
        },
        "user_name": this.editProfile.controls['user_name'].value
      }
      // console.log(user_data)
      this.api.editPersonal(user_data, this.userid).subscribe(() => {
        this.toastr.success(`Personal info has been edited.`, 'Profile edited!', {
          progressBar: true,
          closeButton: true,
        })
      })
      this.visible = false
      this.formsCollection()
    }else{
      this.toastr.warning(`Please enter required data`, `Data required.`)
      // console.log(this.submitted && isNameValid && !this.dateValid, this.submitted, isNameValid , !this.dateValid)
      // console.log(isUsername, this.fnameValid)
    }
  }


  loadEducational() {
    this.api.getEducational(this.userid).subscribe({
      next: (res: any) => {
        this.educational = res
        // console.log(this.educational)
        this.education = this.educational[0]?.basic_information?.education
        if (this.education == null || this.education == undefined || this.education.length == 0) {
          this.educational = []
        }
        // console.log(this.education)
      },
      error: (e) => {
        console.error(e)
        this.is_loading_educational = false
      },
      complete: () => {
        this.is_loading_educational = false
      }
    });
  }

  validateStartYear() {
    const yearInControl = this.addEducation.controls['year_in'];
    const yearInValue = parseInt(yearInControl.value, 10);

    if (yearInValue < 1900 || isNaN(yearInValue)) {
      yearInControl.setValue(1900);
      this.toastr.warning('Enter a valid year.', 'Validation Error', {
        progressBar: true,
        closeButton: true,
      });
    }
  }

  validateEndYear() {
    const yearOutControl = this.addEducation.controls['year_out'];
    const yearOutValue = parseInt(yearOutControl.value, 10);

    if (yearOutValue > 2900 || isNaN(yearOutValue)) {
      yearOutControl.setValue(2900);
      this.toastr.warning('Enter a valid year.', 'Validation Error', {
        progressBar: true,
        closeButton: true,
      });
    }
  }

  createEducation() {
    this.createClick = true;
    if (this.addEducation.valid) {
      const universityName = this.addEducation.controls['university'].value.trim();
      const courseName = this.addEducation.controls['course'].value.trim();
      const yearIn = this.addEducation.controls['year_in'].value;
      const yearOut = this.addEducation.controls['year_out'].value;

      if (universityName === "" || courseName === "" || yearIn === "" || yearOut === "") {
        this.toastr.warning('University Name, Course Name, Year In, and Year Out cannot be empty.', 'Validation Error', {
          progressBar: true,
          closeButton: true,
        });
        this.createClick = false;
        return;
      }

      if (yearIn >= yearOut) {
        this.toastr.error('Year In should be less than Year Out', 'Validation Error', {
          progressBar: true,
          closeButton: true,
        });
        this.createClick = false;
        return;
      }

      const data = {
        "id": this.userid,
        "university_name": universityName,
        "course_name": courseName,
        "year_in": yearIn,
        "year_out": yearOut,
      };

      this.api.createEducation(data).subscribe(() => {
        this.toastr.success(`Education qualification has been added.`, 'Education Added!', {
          progressBar: true,
          closeButton: true,
        });
        this.loadEducational();
        this.educationalQuali();
        this.addEducation.controls['university'].setValue("");
        this.addEducation.controls['course'].setValue("");
        this.addEducation.controls['year_in'].setValue("");
        this.addEducation.controls['year_out'].setValue("");
        this.createClick = false;
      });
    } else {
      this.toastr.warning('Fields cannot be empty.', 'Validation Error', {
        progressBar: true,
        closeButton: true,
      });
      this.createClick = false;
    }
  }

  deleteEducation(index: any) {
    const deleted = this.educational[0].basic_information.education[index]
    // console.log(deleted)
    this.api.deleteEducations(this.userid, deleted.id).subscribe(() => {
      this.toastr.success(`Selected education has been successfully deleted.`, 'Deleted successfully', {
        progressBar: true,
        closeButton: true,
      })
      this.loadEducational()
    })
  }




  checkboxNutrition(data: any) {
    const datas = {
      "prefered_position": this.user?.basic_information?.prefered_position,
      "is_nutritionist_needed": data,
      "prefered_foods": this.user?.basic_information?.prefered_foods,
    }
    this.api.preferenceChanging(datas, this.userid).subscribe(() => {
      this.getUserData()
      this.toastr.success(`The need for nutritionist status is updated.`, `Profile Updated.`)
    })
    // console.log(datas)
  }

  // To add new foods into the list
  preferedFoodsList() {
    if (this.preferedFoods.valid) {
      const foodslist = this.preferedFoods.controls['prefered_foods'].value.trim();
      if (foodslist != "") {
        let foodPass: string[] = foodslist.split(',').map((food: string) => food.trim());
        foodPass = foodPass.filter(food => food !== "");
        // Merge the existing preferred foods with the new foods
        const existingFoods = this.user?.basic_information?.prefered_foods || [];
        const mergedFoods = existingFoods.concat(foodPass);

        const data = {
          "prefered_position": this.user?.basic_information?.prefered_position,
          "is_nutritionist_needed": this.user?.basic_information?.is_nutritionist_needed,
          "prefered_foods": mergedFoods,
        };
        // console.log(data);
        this.api.preferenceChanging(data, this.userid).subscribe(() => {
          // Perform any actions upon successful update
          this.preferedFoods.controls['prefered_foods'].setValue("")
          this.toastr.success(`Prefered foods list is updated.`, `Profile Updated.`)
          this.getUserData();
        });
      }
    }
  }

  deleteFoods(index: any) {
    const existingFoods = this.user?.basic_information?.prefered_foods || [];
    // Remove the food item at the specified index
    existingFoods.splice(index, 1);
    const data = {
      "prefered_position": this.user?.basic_information?.prefered_position,
      "is_nutritionist_needed": this.user?.basic_information?.is_nutritionist_needed,
      "prefered_foods": existingFoods,
    };
    // console.log(data);
    this.api.preferenceChanging(data, this.userid).subscribe(() => {
      this.getUserData();
    });
  }



  getUserData() {
    this.auth.fetchUserData(this.userid).subscribe((res) => {
      this.user = res.data
      // console.log(this.user)
      this.gender = this.user.basic_information.gender
      this.fname = this.user.basic_information.name.first_name
      this.lname = this.user.basic_information.name.last_name
      this.username = this.user.user_name
      this.date = new Date(this.user.basic_information.dob.toString());
      if (this.user.sports == null || this.user.sports == null) {
        this.user.sports = []
      }
      if (this.user.basic_information.dob == "0001-01-01T00:00:00Z") {
        this.vaildDate = false
        this.user.basic_information.dob = new Date("1991-01-01T00:00:00Z")
      }

      try {
        this.phone = this.user?.basic_information?.phone?.filter((ele: any) => {
          return ele.is_primary = true
        })[0]
        if(this.phone === undefined){
          this.phone = {
            country_code: "",
            number: "",
            is_primary: true,
          }
        }
      } catch (error: any) {
        this.logger.handleError(error)
      }

      if (this.user?.basic_information?.address) {
        if (
          this.user?.basic_information?.address[0]?.addr1?.length == 0 &&
          this.user?.basic_information?.address[0]?.city?.length == 0 &&
          this.user?.basic_information?.address[0]?.district?.length == 0 &&
          this.user?.basic_information?.address[0]?.city?.length == 0 &&
          this.user?.basic_information?.address[0]?.state?.length == 0) {
          this.addressEmpty = true
        }else{
          this.addressEmpty = false
        }
      }
      this.titleService.setTitle(`Profile - ${this.user.basic_information.name.first_name}`)
      // console.log(this.user)
      this.loadFilters()
    })
  }

  sendParentMail(parent: string, id: string) {
    if (parent != "000000000000000000000000") {
      // console.log(id)
      this.memberApi.sendInvitaionMailParent(this.id, parent, id).subscribe(() => {
        this.toastr.success(`Parent consent mail have been sent.`, `Email sent!`)
      })
    }
  }

  onPhoneNumberInput(event: Event) {
    const inputValue: string = (event.target as HTMLInputElement).value;
    const cleanedValue: string = inputValue.replace(/[^0-9+ ]/g, '');
    this.phone.number = cleanedValue;
  }

  onKeyDown(event: KeyboardEvent) {
    const keyCode: number = event.keyCode || event.which;
    if ((keyCode >= 65 && keyCode <= 90)) {
      event.preventDefault();
    }
  }

  onKeyDownBMI(event: any) {
    const keyCode: number = event.keyCode || event.which;
    if ((keyCode >= 65 && keyCode <= 90)) {
      event.preventDefault();
    } else {
      if (this.user.basic_information.weight > 0 && this.user.basic_information.height > 0) {
        const height = +this.user.basic_information.height
        const weight = +this.user.basic_information.weight
        const bmi = (weight / ((height * height)
          / 10000)).toFixed(2);
        this.user.basic_information.bmi = bmi
        // console.log({
        //   height: height,
        //   weight: weight,
        //   bmi: bmi,
        // })
      }
    }
  }

  editContactDetails() {
    this.loadingService.show()
    this.phone.country_code = this.phoneCodeControl.value
    const data = {
      basic_information: {
        email: this.user.basic_information.email.toLowerCase().trim(),
        alternative_email: this.user.basic_information.alternative_email.toLowerCase().trim(),
        socialmedia_link: this.user.basic_information.socialmedia_link,
        address: this.user.basic_information.address,
        phone: [this.phone],
      }
    }
    let pphoneValid = (/^([- +()0-9]+)$/.test(this.phone.number) && this.phone.number.length >= 7)
    if (this.phone.number.length == 0) {
      pphoneValid = true
    }
    // console.log(data, pphoneValid)
    if (pphoneValid) {
      // console.log(data)
      this.api.editContact(this.userid, data).subscribe({
        next: () => {
          // console.log(res)
          this.contactEditDone()
          this.getUserData()
          this.loadingService.hide()
          this.toastr.success(`Contact information is saved.`,`Profile saved.`)
        },
        error: (error: any) => {
          console.log("error:", error)
          this.loadingService.hide()
        }
      })
    } else {
      this.toastr.warning(`Please enter a valid phone number.`, `Phone number invalid.`)
    }
  }

  // getParentData(){
  //   this.api.getParent(this.userid).subscribe((res) => {
  //     this.parent = res['data']
  //     if (this.parent == null || this.parent == undefined) {
  //       this.parent = []
  //     }
  //   })
  //   console.log(this.parent)
  // }

  printPdInfoEndTimes() {
    // const totalDuration: number = 0;
    // console.log(this.OverSession)
    // for (let i = 0; i < this.OverSession.length; i++) {
    //   const pdInfo = this.OverSession[i].pd_info;
    //   const endTime = pdInfo ? pdInfo.end_time : 'N/A';
    //   const startTime = pdInfo ? pdInfo.start_time : 'N/A';
    //   // console.log(startTime, endTime)
    //   totalDuration += this.calculateDuration(startTime, endTime);
    // }
    // console.log(totalDuration, this.OverSession)
    // console.log(this.overall_info)
    this.dev_hours = `${this.overall_info?.attended_hours || 0}`;
  }

  calculateDuration(startTime: string, endTime: string): number {
    if (startTime === 'N/A' || endTime === 'N/A') {
      return 0;
    }

    const startDate = new Date("01/01/2007 " + startTime);
    const endDate = new Date("01/01/2007 " + endTime);

    const duration = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(duration / (1000 * 60 * 60));

    return hours;
  }

  getUserAchievement() {
    this.api.fetchUserAchievement(this.userid).subscribe((res: any) => {
      this.achievement = res['data']
      if (this.achievement?.basic_information.achievement == null || this.achievement?.basic_information.achievement == undefined) {
        this.achievement.basic_information.achievement = []
      }
    })

    this.groupApi.getAssociatedGroupsOfId(this.userid).subscribe((res: any) => {
      for (const entry of (res['members'] || [])) {
        const elem = {
          "id": entry['_id'],
          "name": entry['basic_information']['name']['first_name'],
          "dp": entry['basic_information']['dp'],
          "coach": entry['associated_profiles'][0]['name'] || "Not Assigned",
          "coach_id": entry['profileid'],
        }
        this.members.push(elem)
      }

      this.coachs = res['coachs'] || []
      this.sports = res['sports'] || []
    })
  }


  getFeeds() {
    this.user_feed_loading = true;
    this.api.fetchFeeds(this.userid, this.id).subscribe((res: any) => {
      this.user_feed_loading = false;
      this.user_feed_all = res['data'] || []
      this.filterGallery('');
    }, () => {
      this.user_feed_loading = false;
    })
  }

  getGender(gender: string) {
    switch (gender.toLowerCase()) {
      case "M".toLowerCase():
        return "Male"
      case "F".toLowerCase():
        return "Female"
      default:
        return "Others"
    }
  }

  getUserPdDetails() {
    this.api.fetchProfilePdSessions(this.id, this.userid).subscribe((res: any) => {
      this.personal_dev_hours = res.attended_hours;
      this.pdStats = res;
      // console.log("pdStats", this.pdStats)
      this.pdStatSessions = [
        {
          type: "Pending Sessions",
          data: (res?.data || []).filter((pd: any) => {
            return !pd?.personality_dev?.is_present ||
              Date.parse(pd?.pd_info?.end_date) > Date.parse(new Date().toISOString());
          })
        },
        {
          type: "Completed Sessions",
          data: (res?.data || []).filter((pd: any) => {
            return pd?.personality_dev?.is_present &&
              Date.parse(pd?.pd_info?.end_date) <= Date.parse(new Date().toISOString());
          })
        }
      ]
      // console.log(this.pdStatSessions);
    })
  }


  getUserHomework() {
    this.api.fetchProfileHomework(this.id, this.userid).subscribe((res: any) => {
      if (res.pending_homeworks?.length > 0) {
        res.pending_homeworks.forEach((ph: any) => {
          ph.homeworks.forEach((h: any) => {
            this.homework_pending.push({
              ...h,
              _id: ph._id,
              classid: ph.classid
            })
          })
        });
      }
    })
  }

  getUserAttendence() {
    this.memberApi.getClassList(this.userid).subscribe((res: any) => {
      const classes = res?.map((r: any) => r.groupid) || [];
      this.classes = res
      this.memberApi.getAttendenceForUserClasses(this.id, this.userid, classes.join(",")).subscribe(res => {
        this.attendenceDetails = res;
        // console.log(this.attendenceDetails)
        if (this.classes !== null) {
          if (this.classes.length > 0) {
            this.class_name = this.classes[0].group_info.basic_information.name.first_name || 'select a class'
            this.setCurrentClass(this.classes[0])
          }
        }
      })
    })
    // for (let index = 0; index < classes.length; index++) {
    //   const element = classes[index];
    //   this.reportsapiServiceService.getOverAllList(element, this.userid).subscribe((res: any) => {
    //     if (res?.pd_info?.data) {
    //       this.OverSession = this.OverSession.concat([...res?.pd_info?.data])
    //       this.OverSessionAll = this.OverSessionAll.concat([...res?.pd_info?.data])
    //     }
    //     this.printPdInfoEndTimes()
    //   })
    // }
  }

  setCurrentClass(element: any) {
    this.class_name = element.group_info.basic_information.name.first_name
    this.reportsapiService.getOverAllList(element.groupid, this.userid).subscribe((res: any) => {
      if (res?.pd_info?.data) {
        this.OverSession = this.OverSession.concat([...res?.pd_info?.data])
        this.OverSessionAll = this.OverSessionAll.concat([...res?.pd_info?.data])
        this.overall_info = res?.pd_info
      }
      this.printPdInfoEndTimes()
    })
  }

  getAcademicStats() {
    this.academicStats = null;
    this.selectedTerm = "";
    this.academicStatsLoading = true;
    this.memberApi.getProfileAcademicStats(this.id, this.userid, { year: new Date(this.selectedYear).getFullYear() }).subscribe({
      next:(res: any)=>{
        if (res?.data?.length > 0) {
          this.academicStats = res.data[0];
          this.selectedTerm = this.academicStats?.academics?.course?.term?.[0]?.name || '';
          const subjects = this.academicStats?.academics?.course?.subjects || [];
          const scoring = this.academicStats?.academics?.course?.scoring;
          const grades = scoring?.grades || [];
          if (scoring.scoring_scheme === 'alphabetic') {
            if (grades && grades.length > 0) {
              const sortedGrades = _.orderBy(grades.map((g: any) => ({ ...g, priority: parseInt(g.priority) })), ['priority'], ['asc']);
              this.academicStats.academics.course.scoring.grades = sortedGrades.map((s, i) => {
                return {
                  ...s,
                  grade_offset: (() => {
                    if (i === 0) {
                      return 100;
                    } else if (i === (sortedGrades.length - 1)) {
                      return 0;
                    }
                    return Math.ceil((100 / (sortedGrades.length - 1)) * ((sortedGrades.length - 1) - i));
                  })()
                }
              });
            }
          } else if (scoring?.scoring_scheme === 'numerical') {
            this.academicStats.academics.course.scoring.grades = [
              {
                grade: scoring.max_score,
                grade_offset: 100,
                priority: 1
              },
              {
                grade: 0,
                grade_offset: 0,
                priority: 2
              }
            ]
          }
          if (subjects && subjects.length > 0) {
            const sortedGrades = this.academicStats?.academics?.course?.scoring.grades || [];
            this.academicStats.academics.course.subjects = subjects.map((s: any) => {
              const targetDetails = s.target_details?.[0] || {};
              return {
                ...s,
                target_details: {
                  ...targetDetails,
                  current_grade_offset: (() => {
                    if (scoring?.scoring_scheme === 'alphabetic') {
                      return (sortedGrades.find((f: any) => targetDetails.current_score === f.grade)?.grade_offset) || 0
                    } else if (scoring?.scoring_scheme === 'numerical') {
                      return Math.ceil((parseInt(targetDetails.current_score) / parseInt(scoring.max_score)) * 100)
                    }
                  })(),
                  target_grade_offset: (() => {
                    if (scoring?.scoring_scheme === 'alphabetic') {
                      return (sortedGrades.find((f: any) => s.target_grade === f.grade)?.grade_offset) || 0
                    } else if (scoring?.scoring_scheme === 'numerical') {
                      return Math.ceil((parseInt(s.target_grade) / parseInt(scoring.max_score)) * 100)
                    }
                  })()
                }
              }
            })
          }
        }
        this.updateTerm();
        this.academicStatsLoading = false;
      },
      error:()=>{
        this.academicStatsLoading = false;
      }
    })
  }

  updateTerm() {
    const subjectsInCurrentTerm = (this.academicStats?.academics?.course?.subjects || []).filter((f: any) =>
      f.target_details.term === this.selectedTerm
    )
    this.targetAchievedCountForGrades = subjectsInCurrentTerm.filter((f: any) => {
      return f.target_details.current_grade_offset >= f.target_details.target_grade_offset
    }).length;
    this.totalSubjectsCount = subjectsInCurrentTerm.length;
  }

  filterGallery(filter: any) {
    this.user_feed = this.user_feed_all.filter((item: any) => {
      return item.uploads[0].type.toLowerCase().includes(filter.toLowerCase());
    });
  }

  isPresent(attendance: any) {
    if (attendance == null || attendance.length == 0 || attendance == undefined) {
      return false
    } else {
      return attendance.includes(this.id)
    }
  }


  calcPercentage(current: any, total: any) {
    if (!current || !total) {
      return 0;
    }
    return Math.floor((parseInt(current) / parseInt(total)) * 100)
  }

  calcPercentageAnti(current: any, total: any) {
    return 100 - ((current / total) * 100)
  }

  /**
   * Calculate the duration with two times.
   *
   * @param time1 time one.
   * @param time2 tine two.
   * @returns duration in minutes.
   */
  calcDuration(time1: any, time2: any) {
    const date1 = new Date(time1);
    const date2 = new Date(time2);
    const difference = date2.getTime() - date1.getTime();
    const resultInMinutes = Math.round(difference / 60000);
    // console.log(time1, time2)
    return (resultInMinutes == 0 ? 0 : resultInMinutes) + " minutes"

  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectedYearHandler(ev: any, input: any) {
    const { _d } = ev;
    _d.setMonth(11);
    _d.setDate(31);
    this.selectedYear = _d;
    this.getAcademicStats();
    this.picker.close()
  }
}
