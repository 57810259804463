/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { MembersService } from '../../members/members.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ErrorHelpersService } from '../../../helpers/error-helpers.service';
import { SharedModule } from '../../shared/shared.moudle';

@Component({
  selector: 'app-gallery-datails',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './gallery-datails.component.html',
  styleUrl: './gallery-datails.component.scss'
})
export class GalleryDatailsComponent implements OnInit {
  memberId!: string;
  type!: string;
  title: any;
  galleryTypeParam!: string;
  galleryTypes: any;
  enableCarousel = false;
  dateFormat = "format";
  galleryDetailsLoading = true;
  commentsLoading = true;
  carouselOption1 = {
      autoWidth: true,
      loop: false,
      margin: 10,
      items: 1,
      nav: false,
      autoplay: false
  }
  data: any = []
  selectedGalleryDetails: any;
  is_loading = false


  constructor(
    private router: Router,
    private modalService: NgbModal,
    private memberApi: MembersService,
    private activatedRoute: ActivatedRoute,
    private logger: ErrorHelpersService,
    private datepipe: DatePipe,
    // private lessonApi: LessonApiService,
    // private personalDevApi: PersonalDevService
  ) { }

  onImgError(event: any) {
    event.target.src = 'images/icons/ic_gallery_not_loaded.png'
  }

  ngOnInit(): void {
    this.memberId = this.activatedRoute.snapshot.paramMap.get('id') || '';
    this.galleryTypeParam = this.activatedRoute.snapshot.paramMap.get('type') || '';
    this.galleryTypes = {
        "homework-attachments": {
            identifier: "homework",
            handle: (lessonPlanId: string) => this.memberApi.getHomeworkIndi(lessonPlanId)
        },
        "personal-development": {
            identifier: "pd",
            handle: (lessonPlanId: string) => this.memberApi.getPesonalDevelopmentWithId(lessonPlanId)
        },
        "posts-tagged": {
            identifier: "tagged"
        },
        posts: {
            identifier: "user"
        }
    }
    this.type = this.galleryTypes[this.galleryTypeParam].identifier;
    this.title = this.activatedRoute.snapshot.paramMap.get('type')!.split("-").join(" ") || {};
    this.getGalleryDetails();

    // close all open modals
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            this.modalService.dismissAll();
        }
    });
  }

  getGalleryDetails() {
    this.memberApi.getGalleryDetailsForMember(
        this.memberId,
        this.type
    ).subscribe({
        next: (res: any) => {
            this.data = res?.data?.map((gd: any) => {
                return {
                    lessonplan_id: gd.event_id,
                    org_post_id: gd.org_post_id,
                    url: gd.uploads?.[0]?.thumbnail_url || gd.uploads?.[0]?.url || null,
                    type: gd.uploads?.[0].type || null,
                    details: {
                        title: gd.sport_name,
                        date: this.datepipe.transform(new Date(gd.timestamp), "dd-MM-yyyy"),
                        uploads: gd.uploads || []
                    },
                    user: {
                        _id: gd.user_details?._id,
                        display_name: gd.user_details?.basic_information?.name?.display_name,
                        profile_pic: gd.user_details?.basic_information?.dp,
                        location: gd.location?.location_name
                    },
                    post: {
                        id: gd._id,
                        description: gd.description
                    },
                    data: [],
                    comments: []
                }
            }) || [];
            this.is_loading = true
            // console.log(this.data)
        },
        error: (e) => {
            console.error(e)
            this.is_loading = true
            this.data = []
        },
        complete: () => {
            // console.log(this.data)
        }
    })
  }

  viewGalleryDetail(id: string) {
    this.galleryDetailsLoading = true;
    const { handle } = this.galleryTypes[this.galleryTypeParam];
    if (handle) {
        handle(id).subscribe((res: any) => {
            this.selectedGalleryDetails = {
                ...this.selectedGalleryDetails,
                data: this.parseGalleryDataByType(res),
                class_id: res.classid || res.pd?.classid
            }
            this.galleryDetailsLoading = false;
        }, () => {
            this.selectedGalleryDetails = {
                ...this.selectedGalleryDetails,
                data: this.parseGalleryDataByType({}),
                class_id: null
            }
            this.galleryDetailsLoading = false;
            // this.logger.logger(err)
        });
    } else {
        this.galleryDetailsLoading = false;
    }
}

getCommentsForPost(memberId: string, postId: string) {
    this.commentsLoading = true;
    this.memberApi.getCommentsForPost(memberId, postId).subscribe((res: any) => {
        this.selectedGalleryDetails.comments = (res && res.map((comment: any) => {
            const name = comment.CommentedUser?.[0]?.basic_information?.name;
            return {
                display_name: name?.display_name || (name?.first_name + " " + name?.last_name).trim(),
                profile_pic: comment.CommentedUser?.[0]?.basic_information?.dp,
                comment: comment.comment
            }
        })) || [];
        this.commentsLoading = false;
    }, err => {
        console.log(err);
        this.selectedGalleryDetails.comments = [];
        this.commentsLoading = false;
    });
}

parseGalleryDataByType(data: any): any {
    if (this.type === "pd") {
        return [
            ["Session Name", data?.pd?.session_name || "NA"],
            ["Session Objective", data?.pd?.objectives || "NA"],
            ["No of Members", data?.users?.length || 0],
            ["Date", data.pd.date ? this.datepipe.transform(new Date(data.pd.date), "dd-MM-yyyy") : "NA"],
            ["Conducted By", data?.pd?.conducted_by || "NA"]
        ];
    } else if (this.type === "homework") {
        const homework = data?.homeworks?.[0] || {};
        return [
            ["Homework Name", homework?.name || "NA"],
            ["Homework Type", homework?.type || "NA"],
            ["Activity", homework?.tasks ? homework.tasks.map((task: any) => task.task).join(", ") : "NA"],
            ["Start Date", homework?.start_date ? this.datepipe.transform(new Date(homework.start_date), "dd-MM-yyyy") : "NA"],
            ["Due Date", homework?.end_date ? this.datepipe.transform(new Date(homework.end_date), "dd-MM-yyyy") : "NA"],
            ["Status", homework?.tasks ? (homework.tasks.includes((task: any) => !task.is_completed) ? "Complete" : "Incomplete") : "NA"]
        ];
    }
}

openGalleryDetailModal(type: any, data: any) {
    this.selectedGalleryDetails = data;
    // console.log(this.selectedGalleryDetails.details.uploads)
    const modalRef = this.modalService.open(type, { size: 'xl', windowClass: 'gallery-modal-content',backdrop: 'static', centered: true });

    this.viewGalleryDetail(this.type == 'pd' ? data.org_post_id : data.lessonplan_id);

    if (!['homework', 'pd'].includes(this.type)) {
        this.getCommentsForPost(this.memberId, this.selectedGalleryDetails.post.id);
    }

    /* Carousel injects animation to the modal container which causes issues. Adding a small timeout to render carousel fixes it */

    setTimeout(() => {
        this.enableCarousel = true;
    }, 100);

    modalRef.result.then((data: any) => {
      //
      this.logger.logger(data)
    },
    () => {
        this.enableCarousel = false
        this.selectedGalleryDetails = undefined;
        // this.logger.handleError(error)
    });

    /* *************************************** */
}

redirectToMoreInfo(member: string) {
    if (this.type === "pd") {
        // this.router.navigate([`/lesson/pd/${this.selectedGalleryDetails.class_id}/${this.selectedGalleryDetails.org_post_id}/${this.selectedGalleryDetails.lessonplan_id}`]);
        this.router.navigate(['/personaldevs/personal/', this.selectedGalleryDetails.class_id, this.selectedGalleryDetails.org_post_id, this.selectedGalleryDetails.lessonplan_id]);

    } else if (this.type === "homework") {
        // this.router.navigate([`/lesson/homework/individual/${this.selectedGalleryDetails.class_id}/${this.selectedGalleryDetails.lessonplan_id}/${member}`]);
        this.router.navigate(['/homeworks/individual/homework/', this.selectedGalleryDetails.class_id, this.selectedGalleryDetails.lessonplan_id, member]);
    }
}
}
