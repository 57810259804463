/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SharedModule } from '../../shared/shared.moudle';
import { MembersService } from '../members.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services/api.service';
import { StorageService } from '../../../services/storage.service';
import { UploadService } from '../../../services/upload.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { USER_ID, SUCCESS, UserSuperData, LOCALE_INFO, LOCALIZATION, } from '../../../data-models/user-data';
import { STATUS_ACTIVE, STATUS_PENDING_CONSENT, STATUS_PENDING_PASSWORD } from '../../../data-models/user-login';
import { environment } from '../../../../environments/environments';
import { startYearLessThanEndYear, startDateBeforeEndDate } from '../add-new-staff/add-new-staff.component';


const storage_user = environment.storage_user
const container_user = environment.container_user

@Component({
  selector: 'app-self-registration',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './self-registration.component.html',
  styleUrl: './self-registration.component.scss'
})
export class SelfRegistrationComponent implements OnInit {
  @ViewChild('leftContainer') leftContainer: ElementRef | any;
  @ViewChild('card1') card1!: ElementRef;
  @ViewChild('card2') card2!: ElementRef;
  @ViewChild('card3') card3!: ElementRef;
  @ViewChild('card4') card4!: ElementRef;
  @ViewChild('card5') card5!: ElementRef;
  @ViewChild('card6') card6!: ElementRef;

  locale: LOCALE_INFO | any = {
    currency: '',
    date: '',
    locale: '',
    region: '',
    timezone: '',
    country: '',
    time: ''
  }

  array: number[] = [1, 2, 3, 4, 5, 6, 7, 8];
  array2: number[] = [1, 2, 3, 4, 5];

  current_step  = 0
  show_additional_info  = false
  id: string | any;
  countryName = "Select country"
  countryList: any[] = []
  countryListAll: any = []
  searchTerm = ""
  countryId = ""
  countryIso = ""

  stateList: any = []
  stateListAll: any = []
  searchTermState = ""
  stateName = "Select state"

  pincodeList: any = []
  pincodeListAll: any = []
  searchTermPincode = ""
  pincodeName = "Select pincode"

  addPersonalInfo!: FormGroup | any;
  addPhysicalChara: FormGroup | any;
  addSportInfo: FormGroup | any;
  addAddressInfo: FormGroup | any;
  addEducation: FormGroup | any  = new FormGroup({});
  addCareer: FormGroup | any;
  addAchievement: FormGroup | any;
  date: Date = new Date();
  submitted = false;

  email: any = "";
  already_a_academy_member = false
  existingUser = false
  existingUserID: any = ""
  academy_id: any = ""
  isUnder13 = false

  memberships: any[] = [];
  products: any = [];
  lookup = new Map();

  username: any = ""; usernameValid = true;
  sportsList: any = [];
  educationList: any[] = [];
  careerList : any[] = [];
  achievementList: any[] = [];
  licenseList: any[] = [];
  isValid: boolean | any;

  playersAddedList: any[] = [];
  country : any = ""
  state: any = ""
  postcode: any = ""

  editMode = false;
  editingIntex: number | any;

  modalReference: any;
  imageChangedEvent: any = '';
  file: File | any;
  fileSelect = true
  afterImageUpload = false
  fileSelectDone: boolean | any;
  croppedImage: any = '';
  afterCrop = false
  uploadfiles: any[] = []
  uploadPath = ""
  generatedId = ""

  academy_name = ""
  academy_dp: any = ""
  is_parent_invite = false


  constructor(
    private memberApi: MembersService,
    private api: ApiService,
    private storage: StorageService,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private uploadApi: UploadService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.educationList = [];
    // this.careerList = [];
    // this.achievementList = [];
    // this.playersAddedList = [];
    this.locale = this.storage.getJsonValue(LOCALIZATION)
    this.isValid = true
    this.date.setFullYear(this.date.getFullYear() - 9);
    this.id = this.storage.getJsonValue(USER_ID)
    this.getCountryList()
    this.formsCollection()
    this.loadProducts()
    this.getSportsList()
    this.generateUniqueId()
    // this.country = "5ddf772af7cbe64bdc7e0bbd"
    this.countryId = "5ddf772af7cbe64bdc7e0bbd"
    this.academy_id = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.academy_id) {
      this.is_parent_invite = true
    }
    this.getAcademyInfo()
  }

  getAcademyInfo() {
    this.auth.fetchUserData(this.academy_id).subscribe(user => {
      this.academy_name = user.data.basic_information.name.first_name.concat(' ').concat(user.data.basic_information.name.last_name.toString())
      this.academy_dp = user.data.basic_information.dp
    })
  }

  formsCollection(){
    this.addPersonalInfo = new FormGroup({
      email: new FormControl('', [Validators.email]), // Email field without Validators.required
      dob: new FormControl(this.date , [Validators.required]),
      fname: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      lname: new FormControl(''), // Last name field without Validators.required
      username: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      plan: new FormControl(''), // Membership plan field without Validators.required
      pemail: new FormControl('', [Validators.required, Validators.email]),
      pfname : new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      plname : new FormControl(''),
      pphonenumber : new FormControl('', [Validators.required, Validators.pattern('^\\+?[1-9]\\d{1,14}$')]),
      prelation : new FormControl(''),
    });

    this.addPersonalInfo.patchValue({
      lname: "",
      plan: "",
      prelation: ""
    });

    this.checkAge();

    this.addPhysicalChara = new FormGroup({
      height: new FormControl('', [
        Validators.pattern(/^\d*\.?\d+$/) // Validates positive numbers with optional decimals
      ]),
      weight: new FormControl('', [
        Validators.pattern(/^\d*\.?\d+$/)
      ]),
      bmi: new FormControl('', [
        Validators.pattern(/^\d*\.?\d+$/)
      ])
    });
    this.addPhysicalChara.get('height').valueChanges.subscribe(() => this.calculateBMI());
    this.addPhysicalChara.get('weight').valueChanges.subscribe(() => this.calculateBMI());

    this.addSportInfo = new FormGroup({
      sport_name: new FormControl(''),
      position: new FormControl('')
    });

    this.addAddressInfo = new FormGroup({
      address: new FormControl(''),
      city: new FormControl(''),
    });

    this.addEducation = new FormGroup({
      institute: new FormControl('', [this.noWhitespaceValidator]),
      discipline: new FormControl('', [this.noWhitespaceValidator]),
      start_Year: new FormControl('', [
        Validators.pattern(/^(19|20)\d{2}$/), this.noWhitespaceValidator
      ]),
      end_Year: new FormControl('', [
        Validators.pattern(/^(19|20)\d{2}$/), this.noWhitespaceValidator
      ])
    },  { validators: startYearLessThanEndYear() });

    this.addEducation.statusChanges.subscribe(() => {
      this.checkFormValidity();
    });

    this.addCareer = new FormGroup({
      academy: new FormControl('', [this.noWhitespaceValidator]),
      role: new FormControl('', [this.noWhitespaceValidator]),
      start_date: new FormControl(''),
      end_date: new FormControl('')
    }, { validators: startDateBeforeEndDate() });

    this.addCareer.statusChanges.subscribe(() => {
      this.checkCareerValidity();
    });

    this.addAchievement = new FormGroup({
      event: new FormControl('', [this.noWhitespaceValidator]),
      achievement: new FormControl('', [this.noWhitespaceValidator]),
      year: new FormControl('', [
        this.noWhitespaceValidator,
        Validators.pattern(/^(19|20)\d{2}$/)
      ])
    });
    this.licenseList = []

    this.addAchievement.statusChanges.subscribe(() => {
      this.checkAchievementValidity();
    });


  }

  clearForm() {
    this.isValid = true
    this.date = new Date();
    this.date.setFullYear(this.date.getFullYear() - 9)
    this.addPersonalInfo.patchValue({
      dob: this.date, // Assuming this.date is the default value you want to set
      fname: "",
      lname: "",
      username: "",
      gender: "",
      plan: "",
      pemail: "",
      pfname: "",
      plname: "",
      pphonenumber: "",
      prelation: ""
    });
    this.addPhysicalChara.reset();
    this.addSportInfo.patchValue({
      sport_name: "",
      position: ""
    });
    this.addAddressInfo.reset();
    this.addAddressInfo.patchValue({
      address: "",
      city: ""
    });
    this.addEducation.reset();
    this.addCareer.reset();
    this.addAchievement.reset();
    this.educationList = [];
    this.achievementList =[];
    this.careerList = [];
    this.licenseList = [];
    this.countryId = "5ddf772af7cbe64bdc7e0bbd"
    this.state = ""
    this.postcode = ""
    this.checkAge()
  }

  clearFullForm(){
    this.date = new Date();
    this.date.setFullYear(this.date.getFullYear() - 9)
    this.addPersonalInfo.patchValue({
      email: "",
      dob: this.date, // Assuming this.date is the default value you want to set
      fname: "",
      lname: "",
      username: "",
      gender: "",
      plan: "",
      pemail: "",
      pfname: "",
      plname: "",
      pphonenumber: "",
      prelation: ""
    });
    this.addPhysicalChara.reset();
    this.addSportInfo.patchValue({
      sport_name: "",
      position: ""
    });
    this.addAddressInfo.reset();
    this.addAddressInfo.patchValue({
      address: "",
      city: ""
    });
    this.addEducation.reset();
    this.addCareer.reset();
    this.addAchievement.reset();
    this.educationList = [];
    this.achievementList =[];
    this.careerList = [];
    this.licenseList = [];
    this.countryId = "5ddf772af7cbe64bdc7e0bbd"
    this.state = ""
    this.postcode = ""
    this.generatedId= ""
    this.checkAge()
  }

  // debugFormValidity() {
  //   Object.keys(this.addPersonalInfo.controls).forEach(key => {
  //     const control = this.addPersonalInfo.get(key);
  //     console.log(`Control: ${key}, Valid: ${control.valid}, Value: ${control.value}, Errors: ${control.errors}`);
  //   });
  // }

  generateUniqueId() {
    this.generatedId = ""
    this.generatedId = '_' + Math.random().toString(36).substr(2, 9);
  }

  onSubmit() {
    this.submitted = true;
    this.checkAge();
    // this.debugFormValidity()
    if (this.addPersonalInfo.valid && this.isValid && !this.usernameValid) {
      const dobValue = this.addPersonalInfo.get('dob').value;
      const dob = new Date(dobValue);
      const formattedDob = `${dob.getDate().toString().padStart(2, '0')} ${(dob.getMonth() + 1).toString().padStart(2, '0')} ${dob.getFullYear()}`;

      const email = this.addPersonalInfo.get('email').value.trim();
      const username = this.addPersonalInfo.get('username').value.trim();

      // Check if the email or username already exists in the playersAddedList array
      const emailExists = email &&  this.playersAddedList.some(player => player.email === email);
      const usernameExists = username && this.playersAddedList.some(player => player.user_name === username);

      if ((emailExists || usernameExists) && !this.existingUser) {
        let errorMessage = 'Duplicate entry: ';
        if (emailExists) {
          errorMessage += 'Email already exists. ';
        }
        if (usernameExists) {
          errorMessage += 'Username already exists. ';
        }

        this.toastr.warning(errorMessage, 'Invalid Data', {
          progressBar: true,
          closeButton: true
        });

        return; // Prevent further execution if duplicates are found
      }
      //  if(this.existingUser){
      //   this.toastr.warning(`User already exist in Dartle cannot add now, contact admin.`, 'User exist', {
      //     progressBar: true,
      //     closeButton: true
      //   })
      //   return;
      //  }
      const tempPassword = `${this.addPersonalInfo.get('fname').value.trim().toUpperCase()}${this.datepipe.transform(this.date, 'ddMMYYYY')}P@ssw0rd`
      const salt = this.storage.getSalt();
      const pass = this.auth.generatePasswordHash(salt, tempPassword)
      const newSalt = this.auth.convertByteToSalt(salt)

      const data = {
        self_registration : true,
        address: this.addAddressInfo.get('address').value.trim(),
        city: this.addAddressInfo.get('city').value.trim(),
        country: this.countryId,
        dob: formattedDob,
        email: this.addPersonalInfo.get('email').value.toLowerCase().trim(),
        first_name: this.addPersonalInfo.get('fname').value.trim(),
        gender: this.addPersonalInfo.get('gender').value,
        last_name: this.addPersonalInfo.get('lname').value.trim(),
        pemail: this.addPersonalInfo.get('pemail').value.toLowerCase().trim(),
        pfirst_name: this.addPersonalInfo.get('pfname').value.trim(),
        phone: this.addPersonalInfo.get('pphonenumber').value.trim(),
        pincode: this.postcode,
        plast_name: this.addPersonalInfo.get('plname').value.trim(),
        relation : this.addPersonalInfo.get('prelation').value.trim(),
        profile: "Player",
        state: this.state,
        underage: this.isUnder13,
        user_name: this.username,
        unique_id : this.generatedId,
        product_role : this.addPersonalInfo.get('plan').value.trim(),
        password : pass,
        tempPassword : tempPassword,
        salt : newSalt,
        physical_charateristics: {
          height: +this.addPhysicalChara.get('height').value || 0,
          weight: +this.addPhysicalChara.get('weight').value || 0,
        },
        sport_info:{
          sports_id : this.addSportInfo.get('sport_name').value,
          prefered_position : this.addSportInfo.get('position').value
        },
        education: this.educationList,
        careers: this.careerList,
        achievements: this.achievementList,
        licenses: this.licenseList,
      };
      this.playersAddedList.push(data);
      this.toastr.success(`The member named ${data.first_name} ${data.last_name} added to the list successfully.`, 'Member added', {
        progressBar: true,
        closeButton: true
      });
      // console.log('Form Submitted', this.playersAddedList);
      this.clearFullForm();
      this.generateUniqueId();
      this.formDisableClear();
    } else{
      this.toastr.warning(`Please check the entered form for any invalid data.`, 'Invalid Data', {
        progressBar: true,
        closeButton: true
      })
    }
  }


  saveAllPlayerData(){
    this.memberApi.addMemberMultipleMembers(this.playersAddedList, this.academy_id).subscribe((res) => {
      // console.log('Response:', res);
      if (res[0]["response"] === "success") {
        this.toastr.success(`Mail has been sent to students.`, 'Invitation Sent!', {
          progressBar: true,
          closeButton: true
        });
        this.router.navigate(['/members']);
        // setTimeout(() => {
        //   this.router.navigate(['/member']);
        // }, 3000);
      } else {
        this.toastr.warning("Members was not added to the academy. Please try again after sometime.", 'Server error', {
          progressBar: true,
          closeButton: true
        });
      }
    });
  }


  editPlan(pos: number, plan: any) {
    this.playersAddedList[pos].product_role = plan._id;
    // console.log(this.playersAddedList[pos].product_role);
    this.cdr.detectChanges();
  }

  getMembershipName(id: string): string {
    const member = this.memberships.find(m => m._id === id);
    return member ? member.name : "";
  }

  // getMembershipId(name: string): string {
  //   const member = this.memberships.find(m => m.name === name);
  //   return member ? member._id : "";
  // }

  getSportsId(name: string): string {
    const sport = this.sportsList.find((m: any) => m.sports === name);
    return sport ? sport._id : "";
  }

  removeSelectedMember(pos: number){
    this.playersAddedList.splice(pos, 1);
    if(this.playersAddedList.length === 0){
      this.previousStep()
      this.clearFullForm()
    }
  }

  editIndividualMember(pos: number){
    this.editingIntex = pos
    this.editMode = true
    this.clearFullForm()
    // Fetch the member data at the given position
    const memberData = this.playersAddedList[pos];
    // Patch the form values with the fetched data
    this.addPersonalInfo.patchValue({
        email: memberData.email || "",
        dob: this.formatDateForInput(memberData.dob),
        fname: memberData.first_name || "",
        lname: memberData.last_name || "",
        username: memberData.user_name || "",
        gender: memberData.gender || "",
        plan: memberData.product_role  || "",
        pemail: memberData.pemail || "",
        pfname: memberData.pfirst_name || "",
        plname: memberData.plast_name || "",
        pphonenumber: memberData.phone || "",
        prelation: memberData.relation || "",
    });
    this.username = memberData.user_name || ""
    this.countryId = memberData.country
    this.state = memberData.state
    this.isUnder13 = memberData.underage
    this.postcode = memberData.pincode
    this.generatedId = memberData.unique_id

    this.addAddressInfo.patchValue({
        address: memberData.address || "",
        city: memberData.city || "",
    });

    this.addPhysicalChara.patchValue({
        height: memberData.physical_charateristics.height || 0,
        weight: memberData.physical_charateristics.weight || 0,
    });

    this.addSportInfo.patchValue({
        sport_name: this.getSportsId(memberData.sport_info.sports_id) || "",
        position: memberData.sport_info.prefered_position || "",
    });

    // Assuming educationList, careerList, and achievementList are already defined arrays
    this.educationList = memberData.education || [];
    this.careerList = memberData.careers || [];
    this.achievementList = memberData.achievements || [];
    this.licenseList = memberData.licenses

    this.previousStep()
    this.cdr.detectChanges();
    this.checkAge();
  }

  formatDateForInput(dateStr: string): string {
    const [day, month, year] = dateStr.split(' ');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  confirmIndividualEdit() {
    this.submitted = true;
    this.checkAge();
    // Check if the form is valid before saving the edits
    if (this.addPersonalInfo.valid && this.isValid && !this.usernameValid) {
      const dobValue = this.addPersonalInfo.get('dob').value;
      const dob = new Date(dobValue);
      const formattedDob = `${dob.getDate().toString().padStart(2, '0')} ${(dob.getMonth() + 1).toString().padStart(2, '0')} ${dob.getFullYear()}`;

      const email = this.addPersonalInfo.get('email').value.trim();
      const username = this.addPersonalInfo.get('username').value.trim();

      // Get original email and username
      const originalEmail = this.playersAddedList[this.editingIntex].email;
      const originalUsername = this.playersAddedList[this.editingIntex].user_name;

      // Check if the email or username already exists in the playersAddedList array, excluding the current index
      const emailExists = email && email !== originalEmail && this.playersAddedList.some((player, index) => player.email === email && index !== this.editingIntex);
      const usernameExists = username && username !== originalUsername && this.playersAddedList.some((player, index) => player.user_name === username && index !== this.editingIntex);

      if ((emailExists || usernameExists) && !this.existingUser) {
        let errorMessage = 'Duplicate entry: ';
        if (emailExists) {
          errorMessage += 'Email already exists. ';
        }
        if (usernameExists) {
          errorMessage += 'Username already exists. ';
        }

        this.toastr.warning(errorMessage, 'Invalid Data', {
          progressBar: true,
          closeButton: true
        });

        return; // Prevent further execution if duplicates are found
      }

      // if(this.existingUser){
      //   this.toastr.warning(`User already exist in Dartle cannot add now, contact admin.`, 'User exist', {
      //     progressBar: true,
      //     closeButton: true
      //   })
      //   return;
      //  }
      const tempPassword = `${this.addPersonalInfo.get('fname').value.trim().toUpperCase()}${this.datepipe.transform(this.date, 'ddMMYYYY')}P@ssw0rd`
      const salt = this.storage.getSalt();
      const pass = this.auth.generatePasswordHash(salt, tempPassword)
      const newSalt = this.auth.convertByteToSalt(salt)

      const updatedData = {
        self_registration : true,
        address: this.addAddressInfo.get('address').value.trim(),
        city: this.addAddressInfo.get('city').value.trim(),
        country: this.countryId,
        dob: formattedDob,
        email: email.toLowerCase(),
        first_name: this.addPersonalInfo.get('fname').value.trim(),
        gender: this.addPersonalInfo.get('gender').value,
        last_name: this.addPersonalInfo.get('lname').value.trim(),
        pemail: this.addPersonalInfo.get('pemail').value.toLowerCase().trim(),
        pfirst_name: this.addPersonalInfo.get('pfname').value.trim(),
        phone: this.addPersonalInfo.get('pphonenumber').value.trim(),
        pincode: this.postcode,
        plast_name: this.addPersonalInfo.get('plname').value.trim(),
        relation: this.addPersonalInfo.get('prelation').value.trim(),
        profile: "Player",
        state: this.state,
        underage: this.isUnder13,
        user_name: username,
        unique_id : this.generatedId,
        product_role: this.addPersonalInfo.get('plan').value.trim(),
        password : pass,
        tempPassword : tempPassword,
        salt : newSalt,
        physical_charateristics: {
          height: +this.addPhysicalChara.get('height').value || 0,
          weight: +this.addPhysicalChara.get('weight').value || 0,
        },
        sport_info: {
          sports_id: this.addSportInfo.get('sport_name').value,
          prefered_position: this.addSportInfo.get('position').value
        },
        education: this.educationList,
        careers: this.careerList,
        achievements: this.achievementList,
        licenses: this.licenseList
      };

      // Update the edited data back to the array
      this.playersAddedList[this.editingIntex] = updatedData;

      // Exit edit mode
      this.editMode = false;
      this.toastr.success('Player details updated successfully.', 'Success', {
        progressBar: true,
        closeButton: true
      });
      this.clearFullForm();
      this.formDisableClear();
      this.current_step = 1
    } else {
      this.toastr.warning('Please check the entered form for any invalid data.', 'Invalid Data', {
        progressBar: true,
        closeButton: true
      });
    }
  }

  CancelEdit(){
    this.editMode = false
    this.nextStep();
    this.clearFullForm();
  }


  onPlanChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (selectedValue === 'remove') {
      this.addPersonalInfo.get('plan').setValue("");
    } else {
      this.addPersonalInfo.get('plan').setValue(selectedValue);
    }
    // console.log(this.addPersonalInfo.get('plan').value);
  }

  checkAchievementValidity() {
    const controls = this.addAchievement.controls;
    return controls.event.value && controls.achievement.value &&
           controls.year.value && this.addAchievement.valid;
  }

  saveAchievement() {
    if (this.addAchievement.valid) {
      const trimmedValues = {
        event_name: this.addAchievement.get('event').value.trim(),
        achievement: this.addAchievement.get('achievement').value.trim(),
        date : this.addAchievement.get('year').value.toString()
      };
      this.achievementList.push(trimmedValues);
      // console.log('Achievement List:', this.achievementList);
      this.addAchievement.reset(); // Reset the form after saving
    }
  }

  removeAchievement(index: number) {
    this.achievementList.splice(index, 1);
  }

  noWhitespaceValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  startDateBeforeEndDate() {
    const startDate = this.addCareer?.get('start_date')?.value;
    const endDate = this.addCareer?.get('end_date')?.value;
    // console.log(startDate, endDate)
    if (!startDate || !endDate) {
      return null; // No error if either start date or end date is empty
    }
    return new Date(startDate) < new Date(endDate) ? null : { startDateBeforeEndDate: true };
  }

  checkCareerValidity() {
    const controls = this.addCareer.controls;
    return controls.academy.value && controls.role.value &&
           controls.start_date.value && controls.end_date.value &&
           this.addCareer.valid;
  }

  createCareer() {
    if (this.addCareer.valid) {
      const trimmedValues = {
        academy_name : this.addCareer.get('academy').value.trim(),
        role: this.addCareer.get('role').value.trim(),
        start_date: this.addCareer.get('start_date').value,
        end_date: this.addCareer.get('end_date').value
      };
      this.careerList.push(trimmedValues);
      // console.log('Career List:', this.careerList);
      this.addCareer.reset(); // Reset the form after saving
    }
  }

  removeCareer(index: number) {
    this.careerList.splice(index, 1);
  }

  startYearLessThanEndYear() {
    const startYear = this.addEducation?.get('start_Year')?.value;
    const endYear = this.addEducation?.get('end_Year')?.value;
    if (!startYear || !endYear) {
      return null; // No error if either start year or end year is empty
    }
    return startYear < endYear ? null : { startYearLessThanEndYear: true };
  }

  checkFormValidity() {
    const controls = this.addEducation.controls;
    return controls.institute.value && controls.discipline.value &&
           controls.start_Year.value && controls.end_Year.value &&
           this.addEducation.valid;
  }

  createEducation() {
    if (this.addEducation.valid) {
      const trimmedValues = {
        institute: this.addEducation.get('institute').value.trim(),
        discipline: this.addEducation.get('discipline').value.trim(),
        start_date: this.addEducation.get('start_Year').value.toString(),
        end_date: this.addEducation.get('end_Year').value.toString()
      };
      this.educationList.push(trimmedValues);
      // console.log('Education List:', this.educationList);
      this.addEducation.reset(); // Reset the form after saving
    }
  }

  removeEducation(index: number) {
    this.educationList.splice(index, 1);
  }

  calculateBMI() {
    const height = this.addPhysicalChara.get('height').value;
    const weight = this.addPhysicalChara.get('weight').value;

    if (height && weight && !isNaN(height) && !isNaN(weight)) {
      const heightInMeters = height / 100; // Convert cm to meters
      const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      this.addPhysicalChara.get('bmi').setValue(bmi);
    } else {
      this.addPhysicalChara.get('bmi').setValue('');
    }
  }

  updateParentalValidators(isUnder13: boolean) {
    if (isUnder13) {
      this.addPersonalInfo.controls['pemail'].setValidators([Validators.required, Validators.email]);
      this.addPersonalInfo.controls['pfname'].setValidators([Validators.required]);
      this.addPersonalInfo.controls['pphonenumber'].setValidators([Validators.required, Validators.pattern('^\\+?[1-9]\\d{1,14}$')]);
    } else {
      this.addPersonalInfo.controls['pemail'].clearValidators();
      this.addPersonalInfo.controls['pfname'].clearValidators();
      this.addPersonalInfo.controls['pphonenumber'].clearValidators();
    }

    // Update the validity of the controls
    this.addPersonalInfo.controls['pemail'].updateValueAndValidity();
    this.addPersonalInfo.controls['pfname'].updateValueAndValidity();
    this.addPersonalInfo.controls['pphonenumber'].updateValueAndValidity();
  }

  generateUsername() {
    if (this.addPersonalInfo.controls['lname'].value.length > 0)
      this.username = `${this.addPersonalInfo.controls['fname'].value}.${this.addPersonalInfo.controls['lname'].value}`
    else {
      this.username = `${this.addPersonalInfo.controls['fname'].value}`
    }
    this.username = this.username.replace(/\s+/g, '-').toLowerCase();
    this.checkUserName(true)
  }

  checkUserName(generate = false) {
    this.usernameValid = true
    this.auth.checkUsername(this.username).subscribe((res: any) => {
      if (res.success) {
        if (generate) {
          this.username = `${this.username}${this.makeRandom(4)}`
        } else {
          this.usernameValid = true
        }
      } else {
        this.usernameValid = false
      }
    })
  }

  makeRandom(lengthOfCode: number) {
    // let possible = "abcdefghijklmnooqrstuvwxyz1234567890.-&$!";
    const possible = "1234567890.-&$!";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  onPhoneNumberInput(event: Event) {
    const inputValue: string = (event.target as HTMLInputElement).value;
    const cleanedValue: string = inputValue.replace(/[^0-9+ ]/g, '');
    this.addPersonalInfo.patchValue({
      pphonenumber: cleanedValue
    });
    // this.pphone = cleanedValue;
  }

  onKeyDown(event: KeyboardEvent) {
    const keyCode: number = event.keyCode || event.which;
    if ((keyCode >= 65 && keyCode <= 90)) {
      event.preventDefault();
    }
  }

  loadProducts() {
    this.memberApi.getProducts(this.id).subscribe({
      next: (res: any) => {
        if (res.status === SUCCESS) {
          this.products = res.data;
          this.memberships = []
          for (let index = 0; index < this.products?.length; index++) {
            const element = this.products[index];
            if (element.is_active) {
              this.memberships.push({
                name: element.name,
                _id: element._id,
                ...element,
              })
            }
            this.lookup.set(element._id, element.name)
          }
        }
      },
      error: (err: any) => {
        console.log(err)
        this.products = null
      },
      complete: () => {
        // console.log(this.memberships)
      }
    })
  }

  getSportsList() {
    this.api.getSportList().subscribe((res: any) => {
      this.sportsList = res;
      // console.log(this.sportsList)
    })
  }

  formDisableClear(){
    this.addPersonalInfo.get('fname')?.enable();
    this.addPersonalInfo.get('lname')?.enable();
    this.addPersonalInfo.get('gender')?.enable();
    this.addPersonalInfo.get('dob')?.enable();
    this.addPersonalInfo.get('username')?.enable();
    // this.addPersonalInfo.get('pemail')?.enable();
    // this.addPersonalInfo.get('pfname')?.enable();
    // this.addPersonalInfo.get('plname')?.enable();
    // this.addPersonalInfo.get('pphonenumber')?.enable();
    // this.addPersonalInfo.get('prelation')?.enable();
  }
  

  checkUser() {
    this.existingUser = false
    this.formDisableClear()
    this.isValid = true
    this.email = this.addPersonalInfo.controls['email'].value.trim();
    this.already_a_academy_member = false
    // console.log(this.email, this.emailFormatValid, this.email != "")
    if (!this.addPersonalInfo.controls['email'].errors?.email && this.email != "") {
      this.auth.checkEmail(this.email.toLowerCase()).subscribe((result: any) => {
        if (result.active_status === STATUS_ACTIVE
          || result.active_status === "new"
          || result.active_status === STATUS_PENDING_CONSENT
          || result.active_status === STATUS_PENDING_PASSWORD) {
          this.auth.fetchUserId(this.email.toLowerCase()).subscribe({
            next:(res: any) => {
              this.memberApi.isMember(this.id, res.id).subscribe((members: any) => {
                this.already_a_academy_member = members["members"]
                this.auth.fetchUserData(res.id).subscribe(user => {
                  if (user.data.profile_type.includes("Academy")) {
                    this.toastr.warning("This email owner is a academy, a academy cannot be a member to another academy.", 'Email owner is a academy', {
                      progressBar: true,
                      closeButton: true
                    })
                    this.isValid = false
                  } else if (this.already_a_academy_member) {
                    this.toastr.warning("This email owner is already a member of the academy.", 'Email owner is a member', {
                      progressBar: true,
                      closeButton: true
                    })
                    this.isValid = false
                  }
                  else {
                    this.existingUser = true
                    this.populateForm(user.data)
                    this.checkAge()
                    this.showInfoToast()
                  }
                })
              })
            }
          })
        } else {
          this.clearForm()
        }
      },
        (error: any) => {
          console.log(error)
        })
    }
  }

  populateForm(user: UserSuperData) {
    this.isValid = false
    this.existingUserID = user._id.toString()
    if (user.basic_information.dob.toString() === "0001-01-01T00:00:00Z") {
      this.date = new Date();
    } else {
      this.date = new Date(user.basic_information.dob.toString());
    }
    this.addPersonalInfo.patchValue({
      fname: user.basic_information.name.first_name,
      lname: user.basic_information.name.last_name,
      gender: user.basic_information.gender,
      dob : this.date,
      username : user.user_name
    });
    this.usernameValid = false;
    this.isValid = true;
  }

  public ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  checkAge() {
    this.date = this.addPersonalInfo.controls['dob'].value
    // console.log("Check Age")
    // const timeDiff = Math.abs(Date.now() - new Date(this.date).getTime());
    // var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    const age = this.ageFromDateOfBirthday(this.date)
    // console.log(age, new Date(this.date))
    this.isUnder13 = false
      this.isUnder13 = age <= 13
      // this.show_parent = this.isUnder13
      if (age < 3) {
        this.date.setFullYear(this.date.getFullYear() - 9);
        this.toastr.warning("Member must be atleast 3 years old.", 'Underage!', {
          progressBar: true,
          closeButton: true
        })
        return false
      }
      this.updateParentalValidators(this.isUnder13);
      return true
   }


  showInfoToast() {
    this.toastr.info("This email owner is already a Dartle member. We have fetched their data for you.", 'Info', {
      progressBar: true,
      closeButton: true
    });
    this.addPersonalInfo.get('fname')?.disable();
    this.addPersonalInfo.get('lname')?.disable();
    this.addPersonalInfo.get('gender')?.disable();
    this.addPersonalInfo.get('dob')?.disable();
    this.addPersonalInfo.get('username')?.disable();
    // this.addPersonalInfo.get('pemail')?.disable();
    // this.addPersonalInfo.get('pfname')?.disable();
    // this.addPersonalInfo.get('plname')?.disable();
    // this.addPersonalInfo.get('pphonenumber')?.disable();
    // this.addPersonalInfo.get('prelation')?.disable();
  }


  nextStep(){
    this.current_step = this.current_step + 1;
    this.clearFullForm()
  }

  previousStep(){
    this.current_step = this.current_step - 1;
  }

  onImgError(event: any) {
    event.target.src = 'images/samples/ic_profile.png'
  }

  showAdditional(){
    this.show_additional_info = !this.show_additional_info
  }

  scrollToCard(cardId: string): void {
    let target = this.card1.nativeElement
    switch(cardId){
      case 'card1':
        target = this.card1.nativeElement
        break
      case 'card2':
        target = this.card2.nativeElement
        break
      case 'card3':
        target = this.card3.nativeElement
        break
      case 'card4':
        target = this.card4.nativeElement
        break
      case 'card5':
        target = this.card5.nativeElement
        break
      case 'card6':
        target = this.card6.nativeElement
        break
      default:
        target = this.card1.nativeElement
        break
    }

    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  getCountryList() {
    this.api.getCountryList().subscribe((res: any) => {
      this.countryList = [...res]
      this.countryListAll = res
      // console.log(this.countryList)
    })
  }

  search() {
    this.countryList = this.countryListAll.filter((element: any) => {
      return element.country.toLowerCase().includes(this.searchTerm.toLowerCase());
    })
  }

  searchState() {
    this.stateList = this.stateListAll.filter((element: any) => {
      return element.toLowerCase().includes(this.searchTermState.toLowerCase());
    })
  }

  searchPostcode() {
    this.pincodeList = this.pincodeListAll.filter((element: any) => {
      return element.toLowerCase().includes(this.searchTermPincode.toLowerCase());
    })
  }

  selectCountry(country: any) {
    this.countryName = country.country
    this.countryId = country._id
    this.countryIso = country.iso

    this.memberApi.getStateList(this.countryIso).subscribe((res: any) => {
      this.stateList = res['result']
      this.stateListAll = res['result']
      this.stateName = "Select State"
      this.pincodeName = "Select Pincode"
    })
  }

  selectState(country: any) {
    this.stateName = country
    this.state = this.stateName
    this.memberApi.getPincodes(this.stateName, this.countryIso).subscribe((res: any) => {
      this.pincodeList = res['result']
      this.pincodeListAll = res['result']
      this.pincodeName = "Select Pincode"
    })
  }

  selectPostcode(elem: any) {
    this.pincodeName = elem
    this.postcode = this.pincodeName
  }


  openMediumModal(mediumModalContent: any) {
    this.modalReference = this.modalService.open(mediumModalContent, { windowClass: "myCustomModalClass" });
    this.modalReference.result.then(() => {
      //
    },
      () => {
        this.afterImageUpload = false
        this.fileSelect = true
        this.fileSelectDone = false
        this.file = null;
      });
  }

  fileChangeEvent(event: any): void {
    const fileName = (document.getElementById("myfileid") as HTMLInputElement).value;
    const idxDot = fileName.lastIndexOf(".") + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    this.file = event.target.files;

    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png" || extFile === "pdf") {
      this.fileSelect = false;
      this.imageChangedEvent = event;
      this.afterImageUpload = extFile === "jpg" || extFile === "jpeg" || extFile === "png";
      this.fileSelectDone = extFile === "pdf";
    } else {
      this.afterImageUpload = false;
      this.fileSelectDone = false;
      this.toastr.warning(`Please select a proper file type (JPG, PNG, PDF).`, 'File type invalid.', {
        progressBar: true,
        closeButton: true,
      });
    }
  }

  openFileSelect() {
    $('#myfileid').trigger('click');
    return true;
  }

  openAttachment(url: any) {
    // console.log(url)
    window.open(url, '_blank')
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  /**
   * Image is loaded.
   */
  imageLoaded() {
    // show cropper
  }

  /**
 * cropper is ready.
 */
  cropperReady() {
    // cropper ready
  }

  /**
  * loadImage Failed.
  */
  loadImageFailed() {
    //
  }

  // uploadAttachment() {
  //   if (this.file) {
  //     const fileName = this.file[0].name;
  //     if (/[\/\\:?*"<>|#]/.test(fileName)) {
  //       this.toastr.warning(`File name contains special characters (/\\:?*"<>|#). Please change the file name before uploading.`, 'Invalid File Name', {
  //         progressBar: true,
  //         closeButton: true,
  //       });
  //       return;
  //     }
  //     this.afterCrop = true
  //     this.personal_service.initializePost({
  //       id: this.id,
  //       post_type: this.postType,
  //       privacy_type: this.privacyType,
  //       locationid: "",
  //       location: "",
  //       location_name: "",
  //       description: "",
  //       sports: ""
  //     }).subscribe(async (res: any) => {
  //       const postId = res.data.InsertedID;
  //       var upload_path = `${this.classid}/${this.id}/pd/${this.pd.session_name}`
  //       upload_path = upload_path.replace(/ /g, "_");

  //       const upload_status = await this.uploadApi.azureupload(this.file[0], storage_user, container_user, upload_path)
  //       if (upload_status.upload_status == 200) {
  //         this.personal_service.updatePost({
  //           uploads: JSON.stringify([
  //             {
  //               url: upload_status.uploadpath,
  //               type: upload_status.upload_file_type,
  //               thumbnail: res.type === 'image' ? res.path : ''
  //             }
  //           ]),
  //           tagged_users: JSON.stringify((this.pd_membersAll || []).map(m => m.userid)),
  //           status: "Completed",
  //           id: postId,
  //           profile_id: this.id
  //         }).subscribe(res =>{
  //             this.toastr.success(`Session Attachment Uploaded Successfully`, `Attachment Uploaded`)
  //             // this.getPersonalData();
  //             this.afterImageUpload = false
  //             this.fileSelect = true
  //             this.fileSelectDone = false
  //             this.modalService.dismissAll();
  //           })
  //         })
  //       }
  //     }, () => {
  //       this.toastr.warning(`Session Attachment could not be uploaded. Please verify your file before uploading`, 'Upload Failed', {
  //         progressBar: true,
  //         closeButton: true,
  //       })
  //     });
  //   } else {
  //     this.toastr.warning(`Please select a file to attach.`, 'Choose a attachment', {
  //       progressBar: true,
  //       closeButton: true,
  //     })
  //   }

  async uploadAttachment() {
    if (this.file) {
      const fileName = this.file[0].name;
      if (/[\\/\\:?*"<>|#]/.test(fileName)) {
        this.toastr.warning(`File name contains special characters (/\\:?*"<>|#). Please change the file name before uploading.`, 'Invalid File Name', {
          progressBar: true,
          closeButton: true,
        });
        return;
      }
      this.afterCrop = true;
      const uploadPath = `${this.id}/${this.generatedId}/licence`;
      try {
        const endPath = await this.uploadApi.azureuploadCropped(this.file[0], storage_user, container_user, uploadPath, this.croppedImage);

        // console.log("endPath:", endPath);

        // Extracting file type from endPath
        const fileType = endPath.upload_file_type.split('/')[0]; // "image" for "image/png", etc.

        // Adding the uploaded file info to licenseList
        this.licenseList.push({
          name : endPath.upload_file,
          path: endPath.uploadpath,
          type: fileType
        });

        // Show success message
        this.toastr.success('File uploaded successfully.', 'Upload Successful', {
          progressBar: true,
          closeButton: true,
        });
        // console.log(this.licenseList)
        this.modalService.dismissAll();
      } catch (error) {
        console.error("Upload failed:", error);
        this.toastr.error('File upload failed. Please try again.', 'Upload Failed', {
          progressBar: true,
          closeButton: true,
        });
      }
    }
  }

  async removeAttachment(path: string) {
    try {
      const filepath = path.split(`windows.net/${container_user}/`)[1];
      await this.uploadApi.azuredelete(storage_user, container_user, filepath);

      // Remove from licenseList after successful deletion
      this.licenseList = this.licenseList.filter(license => license.path !== path);

      // Show success message
      this.toastr.success('Attachment deleted successfully.', 'Deletion Successful', {
        progressBar: true,
        closeButton: true,
      });
    } catch (error) {
      console.error('Error deleting attachment:', error);

      if (error instanceof Error && error.message.includes('The specified blob does not exist')) {
        this.toastr.warning('The specified attachment does not exist.', 'Attachment Not Found');
      } else {
        this.toastr.error('An error occurred while deleting the attachment.', 'Error');
      }
    }
  }

}
