const PdAccessControl = {
    view: {
        permissions: [
            'pdsession-view',
            'pd-access-to-all'
        ]
    },
    create: {
        permissions: [
            'pdsession-create',
            'pd-access-to-all'
        ]
    },
    delete: {
        permissions: [
            'pdsession-delete',
            'pd-access-to-all'
        ]
    },
    view_detail: {
        permissions: [
            'pdsession-view-detail',
            'pd-access-to-all'
        ]
    },
    edit: {
        permissions: [
            'pdsession-edit',
            'pd-access-to-all'
        ]
    },

    view_attachment: {
        permissions: [
            'pdsession-view-attachment',
            'pd-access-to-all'
        ]
    },
    edit_attachment: {
        permissions: [
            'pdsession-edit-attachment',
            'pd-access-to-all'
        ]
    },
    create_attachment: {
        permissions: [
            'pdsession-create-attachment',
            'pd-access-to-all'
        ]
    },
    delete_attachment: {
        permissions: [
            'pdsession-delete-attachment',
            'pd-access-to-all'
        ]
    },
    view_attendance: {
        permissions: [
            'pdsession-view-attendance',
            'pd-access-to-all'
        ]
    },
    edit_attendance: {
        permissions: [
            'pdsession-edit-attendance',
            'pd-access-to-all'
        ]
    },
    create_member: {
        permissions: [
            'pdsession-create-member',
            'pd-access-to-all'
        ]
    },
    view_member: {
        permissions: [
            'pdsession-view-member',
            'pd-access-to-all'
        ]
    },
    delete_member: {
        permissions: [
            'pdsession-delete-member',
            'pd-access-to-all'
        ]
    },
    view_member_report: {
        permissions: [
            'pdsession-view-member-report',
            'pd-access-to-all'
        ]
    },
    edit_member_report: {
        permissions: [
            'pdsession-edit-member-report',
            'pd-access-to-all'
        ]
    },
}

export{
    PdAccessControl
};