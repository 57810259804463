const CourseAccessControl = {
    view_academic_management: {
        permissions: [
            'academic-management-view',
            'course-access-to-all'
        ]
    },
    create: {
        permissions: [
            'course-create',
            'course-access-to-all'
        ]
    },
    view : {
        permissions: [
            'course-view',
            'course-access-to-all'
        ]
    },
    edit : {
        permissions: [
            'course-edit',
            'course-access-to-all'
        ]
    },
    delete: {
        permissions: [
            'course-delete',
            'course-access-to-all'
        ]
    },

    view_course : {
        permissions: [
            'course-view-detail',
            'course-access-to-all'
        ]
    },

    // edit_name : {
    //     permissions: [
    //         'course-name-edit',
    //         'course-access-to-all'
    //     ]
    // },
    create_numerical_course: {
        permissions: [
            'create-numerical-course-grade',
            'course-access-to-all'
        ]
    },
    edit_numerical_course: {
        permissions: [
            'edit-numerical-course-grade',
            'course-access-to-all'
        ]
    },
    create_alphabetic_course: {
        permissions: [
            'create-alphabetic-course-grade',
            'course-access-to-all'
        ]
    },
    edit_alphabetic_course: {
        permissions: [
            'edit-alphabetic-course-grade',
            'course-access-to-all'
        ]
    },
    view_subject : {
        permissions: [
            'course-view-subject',
            'course-access-to-all'
        ]
    },
    create_subject: {
        permissions: [
            'course-create-subject',
            'course-access-to-all'
        ]
    },
    edit_subject : {
        permissions: [
            'course-edit-subject',
            'course-access-to-all'
        ]
    },
    delete_subject: {
        permissions: [
            'course-delete-subject',
            'course-access-to-all'
        ]
    },
    create_numerical_subject: {
        permissions: [
            'create-numerical-subject-grade',
            'course-access-to-all'
        ]
    },
    edit_numerical_subject: {
        permissions: [
            'edit-numerical-subject-grade',
            'course-access-to-all'
        ]
    },
    create_alphabetic_subject: {
        permissions: [
            'create-alphabetic-subject-grade',
            'course-access-to-all'
        ]
    },
    edit_alphabetic_subject: {
        permissions: [
            'edit-alphabetic-subject-grade',
            'course-access-to-all'
        ]
    },
    view_metric : {
        permissions: [
            'course-view-metric',
            'course-access-to-all'
        ]
    },
    create_metric: {
        permissions: [
            'course-create-metric',
            'course-access-to-all'
        ]
    },
    edit_metric : {
        permissions: [
            'course-edit-metric',
            'course-access-to-all'
        ]
    },
    delete_metric: {
        permissions: [
            'course-delete-metric',
            'course-access-to-all'
        ]
    },
    create_numerical_metric: {
        permissions: [
            'create-numerical-metric-grade',
            'course-access-to-all'
        ]
    },
    edit_numerical_metric: {
        permissions: [
            'edit-numerical-metric-grade',
            'course-access-to-all'
        ]
    },
    create_alphabetic_metric: {
        permissions: [
            'create-alphabetic-metric-grade',
            'course-access-to-all'
        ]
    },
    edit_alphabetic_metric: {
        permissions: [
            'edit-alphabetic-metric-grade',
            'course-access-to-all'
        ]
    },
    
    // **Students listing

    view_student : {
        permissions: [
            'course-view-student',
            'course-access-to-all'
        ]
    },

    create_assignable_course: {
        permissions: [
            'course-create-assign',
            'course-access-to-all'
        ]
    },
    view_associated_course : {
        permissions: [
            'course-view-associated',
            'course-access-to-all'
        ]
    },
    delete_associated_course : {
        permissions: [
            'course-delete-associated',
            'course-access-to-all'
        ]
    },
    create_target_grade: {
        permissions: [
            'course-create-target-grade',
            'course-access-to-all'
        ]
    },
    delete_target_grade: {
        permissions: [
            'course-delete-target-grade',
            'course-access-to-all'
        ]
    },
    create_overall_grade: {
        permissions: [
            'course-create-overall-grade',
            'course-access-to-all'
        ]
    },
    delete_overall_grade: {
        permissions: [
            'course-delete-overall-grade',
            'course-access-to-all'
        ]
    },
    create_course_certificate: {
        permissions: [
            'course-create-certificate',
            'course-access-to-all'
        ]
    },
    edit_course_certificate: {
        permissions: [
            'course-edit-certificate',
            'course-access-to-all'
        ]
    },
    delete_course_certificate: {
        permissions: [
            'course-delete-certificate',
            'course-access-to-all'
        ]
    },
    view_subject_list : {
        permissions: [
            'course-view-subject-list',
            'course-access-to-all'
        ]
    },
    view_metric_list : {
        permissions: [
            'course-view-metric-list',
            'course-access-to-all'
        ]
    },
    create_review: {
        permissions: [
            'course-create-review',
            'course-access-to-all'
        ]
    },
    view_review : {
        permissions: [
            'course-view-review',
            'course-access-to-all'
        ]
    },
    edit_review : {
        permissions: [
            'course-edit-review',
            'course-access-to-all'
        ]
    },
    delete_review: {
        permissions: [
            'course-delete-review',
            'course-access-to-all'
        ]
    },
}

export { 
    CourseAccessControl 
};