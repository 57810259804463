import { Component } from '@angular/core';
import { SharedModule } from '../shared.moudle';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent {
  pdfSrc = "pdf/terms.pdf";
  // pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  // pdfSrc = "https://dartle.app/Terms_and_Conditions.pdf";
}
