import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.moudle';

@Component({
  selector: 'app-mail-send',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './mail-send.component.html',
  styleUrl: './mail-send.component.scss'
})
export class MailSendComponent {

}
