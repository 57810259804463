/* eslint-disable @typescript-eslint/no-unused-vars */
import { CalenderService } from './../calender.service';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../../services/storage.service';
import { CalendarsAccessControl } from '../../auth/access-control';
import { SharedModule } from '../../shared/shared.moudle';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { USER_ID } from '../../../data-models/user-data';
import { GroupsService } from '../../groups/groups.service';
import { LoaderService } from '../../../services/loader.service';
import { head } from 'lodash';

@Component({
  selector: 'app-calender',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './calender.component.html',
  styleUrl: './calender.component.scss'
})
export class CalenderComponent implements OnInit, AfterViewInit {
  calendarsAccessControl = CalendarsAccessControl;
  @Input() type = '';
  @Input() c_user_id = '';
  @ViewChild('fullcalendar', {}) calendarComponent: FullCalendarComponent | any; // the #calendar in the template
  @ViewChild('content', { static: false }) private content: any;
  model: any;

  constructor(
    private calender: CalenderService,
    private storage: StorageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private groupApi: GroupsService,
    private loadingService: LoaderService,
    public datepipe: DatePipe
  ) { }

  options: any;
  calendarApi: any
  eventsModel: any[] = [];
  calendarVisible = true;
  calendarWeekends = true;
  calendarEvents:any[] = [];
  // calendarEvents: EventInput[] = [
  //   // { title: 'Social Media Management', type: "personal", className: "upcoming", start: new Date() }
  // ];
  currentMonth: any
  currentYear: any
  date: Date = new Date();
  datePickersettings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  lastDay = ""
  firstday = ""
  id = ""
  events: any
  classes: any = []
  classesAll: any = []
  searchTerm = ""
  pd_filt = true
  hw_filt = true
  lp_filt = true
  view_class = true
  tempEvents: any = []
  selectedDate: Date = new Date()
  selectedDateEvents: any = []

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: false,
    events: this.calendarEvents,
    eventClick: this.handleEventClick.bind(this),
  };

  ngOnInit() {
    window.scroll(0, 0);
    this.options = {
      editable: false,
      disableDragging: true,
      selectable: true,
      theme: 'standart',
      height: 'auto',
      windowResize:  true,
      aspectRatio: 2,
      headerToolbar: {
        start: '', // will normally be on the left. if RTL, will be on the right
        center: 'title',
        end: '' // will normally be on the right. if RTL, will be on the left
      },
      dateClick: this.handleDateClick.bind(this),
      eventClick: this.eventClick.bind(this),
      plugins: [
        dayGridPlugin,
        interactionPlugin
      ],
      eventRender: function (event: any, element: { find: (arg0: string) => { (): any; new(): any; removeClass: { (arg0: string): void; new(): any; }; }; }, view: any) {
        element.find('a.fc-day-grid-event').removeClass('fc-draggable');
      }
    };
    this.id = this.storage.getJsonValue(USER_ID)
    this.route.queryParams.subscribe(params => {
      this.type = params['type'] != undefined ? params['type'] : "";
      this.c_user_id = params['id'] != undefined ? params['id'] : "";
      if (this.id == null)
        this.id = params['academy'] != undefined ? params['academy'] : "";
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.type == "" ? this.type = "admin" : this.type

    this.loadGroups()
    this.dateRange(new Date())
    this.view_class = this.type === "student" ? false : true
  }

  ngAfterViewInit() {
    this.getCalendarApi()
  }

  handleEventClick(clickInfo: EventClickArg) {
    console.log('Event clicked:', clickInfo.event.title);
    // Additional logic to handle event click
  }

  /**
 * Load members from the database.
 */
  loadGroups() {
    this.groupApi.getGroupsOfId(this.id).subscribe((res: any) => {
      if (res?.members) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const entry of res?.members) {
          // let elem = {
          //   "id": entry['userid'],
          //   "name": entry['user']['basic_information']['name']['first_name'],
          //   "dp": entry['user']['basic_information']['dp'],
          //   "page_role": entry['roles']['page_role'],
          //   "sport_name": entry['user']['sports'][0]['sport_name'] || "Not Assigned",
          //   "user": entry['user'],
          // }
          this.classes.push(entry['user'])
          this.classesAll.push(entry['user'])
        }

        this.classes.map((obj: { isSelected: boolean; }) => {
          obj.isSelected = true;
          return obj;
        })
      }
    })
  }

  /**
   * Get calender events
   * @param firstDay
   * @param lastDay
   */
  getCalenderEvents(firstDay: string, lastDay: string) {
    if (this.type === "admin") {
      this.calender.getCalenderEvents(this.id, firstDay, lastDay).subscribe((res: any) => {
        this.events = res['academy_events']
        // this.classes = res['classes']
        // this.classesAll = res['classes']
        // console.log(this.classes)
        // console.log(res?.academy_events && res?.classes)
        if (res?.academy_events && res?.classes) {
          this.generateData()
        }
      })
    } else if (this.type === "student") {
      this.calender.getCalenderEventsOfType(this.id, this.c_user_id, "member", firstDay, lastDay).subscribe((res: any) => {
        this.events = res['academy_events']
        // this.classes = res['classes']
        // this.classesAll = res['classes']
        if (res?.academy_events && res?.classes) {
          this.generateData()
        }
      })
    } else if (this.type === "coach") {
      this.calender.getCalenderEventsOfType(this.id, this.c_user_id, "Coach", firstDay, lastDay).subscribe((res: any) => {
        this.events = res['academy_events']
        // this.classes = res['classes']
        // this.classesAll = res['classes']
        if (res?.academy_events && res?.classes) {
          this.generateData()
        }
      })
    }
  }

  generateData() {
    // console.log(this.events)
    if (this.events == null || this.events == undefined) {
      return
    } else if (this.events.length > 0) {
      this.calendarEvents = []
      let arrayEvents: any = []
      this.events.forEach((event: any) => {
        arrayEvents = arrayEvents.concat({ // add new event data. must create new array
          title: event.name,
          coach: event.coach,
          className: event.event_type,
          type: event.event_type,
          classid: event.classid,
          module: event.module,
          module_id: event.module_id,
          session_id: event.session_id,
          session: event.name,
          event_id: event._id,
          parent_id: event.parent_id,
          is_child: event.is_child,
          start: this.datepipe.transform(new Date(event.start_date), 'yyyy-MM-dd'),
          end: this.datepipe.transform(new Date(event.end_date), 'yyyy-MM-dd'),
        })
      });

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < arrayEvents.length; index++) {
        if (!this.conatains(this.tempEvents, arrayEvents[index].start)) {
          this.tempEvents = this.tempEvents.concat({
            events: arrayEvents.filter((t: any) => t.start === arrayEvents[index].start),
            length: arrayEvents.filter((t: any) => t.start === arrayEvents[index].start).length,
            date: arrayEvents[index].start,
            type: arrayEvents[index].type,
            classid: arrayEvents[index].classid,
            event_id: arrayEvents[index].event_id,
          })
        }
      }

      this.tempEvents.forEach((element: any) => {
        if (element.length < 4) {
          this.calendarEvents = this.calendarEvents.concat(element.events)
        } else if (element.length >= 4) {
          this.calendarEvents = this.calendarEvents.concat(element.events[0])
          this.calendarEvents = this.calendarEvents.concat(element.events[1])
          this.calendarEvents = this.calendarEvents.concat(element.events[2])
          this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
            title: (element.length - 3) + " more events",
            className: "loadmore",
            type: "loadmore",
            start: element.date,
            end: element.date,
            classid: element.classid,
            event_id: element.event_id,
          })
        }
      });

      // console.log(this.tempEvents)
      // console.log(this.calendarEvents)
    }
  }

  conatains(array: any, date: any) {
    if (array.filter((e: any) => e.date === date).length > 0) {
      return true
    }
    return false
  }

  getCalendarApi() {
    setTimeout(() => {
      this.calendarApi = this.calendarComponent.getApi();
      // this.currentMonth = this.calendarComponent.getApi().getNow().getMonth()
      // this.currentYear = this.calendarComponent.getApi().getNow().getFullYear()
    });
  }

  toggleWeekends() {
    this.calendarWeekends = !this.calendarWeekends;
  }

  eventClick(model: any) {
    const data = model.event.extendedProps
    switch (data.type) {
      case "homework":
        this.router.navigate(['/homeworks/individual/homework/', data.classid, data.event_id]);
        break;
      case "lesson_plan":
        if (data.is_child) {
          this.router.navigate([`/sessions/list/sessions/${data.classid}/${data.module_id}/${data.parent_id}`], { queryParams: { submodule: data.session_id } });
        } else {
          this.router.navigate([`/sessions/list/sessions/${data.classid}/${data.module_id}/${data.session_id}`]);
        }
        break;
      case "personal":
        this.router.navigate(['/personaldevs/personal/', data.classid, data.event_id]);
        break;
      default:
        this.selectedDate = model.event.start
        // eslint-disable-next-line no-var
        var selectedDate = new Date(model.event.start)
        // eslint-disable-next-line no-var
        var lastDay = this.datepipe.transform(selectedDate.setDate(selectedDate.getDate() + 1), 'dd-MM-yyyy')||""
        // eslint-disable-next-line no-var
        var firstDay = this.datepipe.transform(selectedDate.setDate(selectedDate.getDate() - 2), 'dd-MM-yyyy')||""

        this.calender.getCalenderEvents(this.id, firstDay, lastDay).subscribe((res) => {
          this.selectedDateEvents = res
          // console.log(res)
          this.openMediumModal(this.content);
        })
        break;
    }
  }

  dateClick(model: any) {
    // console.log("dateClick")
  }

  eventDragStop(model: any) {
    //
  }

  gotoPast() {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
    this.currentMonth = this.calendarComponent.getApi().getNow().getMonth()
    this.currentYear = this.calendarComponent.getApi().getNow().getFullYear()
  }

  setdate(event: MatDatepickerInputEvent<Date>) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(event.value);
    this.dateRange(this.calendarComponent.getApi().getDate())
  }

  previousDate() {
    this.calendarApi.prev();
    this.dateRange(this.calendarComponent.getApi().getDate())
  }

  nextDate() {
    this.calendarApi.next();
    this.dateRange(this.calendarComponent.getApi().getDate())
  }

  dateRange(date: Date) {
    this.firstday = this.datepipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'dd-MM-yyyy')||"";
    this.lastDay = this.datepipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'dd-MM-yyyy')||"";
    this.getCalenderEvents(this.firstday, this.lastDay)
  }

  currentDate() {
    this.calendarApi.gotoDate(new Date());
    this.dateRange(this.calendarApi.getDate())
  }

  handleDateClick(arg: any) {
    this.selectedDate = new Date(arg.dateStr)
    const selectedDate = new Date(arg.dateStr)
    const lastDay = this.datepipe.transform(selectedDate.setDate(selectedDate.getDate() + 1), 'dd-MM-yyyy')||""
    const firstDay = this.datepipe.transform(selectedDate.setDate(selectedDate.getDate() - 2), 'dd-MM-yyyy')||""
    this.loadingService.show()
    this.calender.getCalenderEventsSingle(this.id, firstDay, lastDay).subscribe((res) => {
      this.selectedDateEvents = res
      this.loadingService.hide()
      this.openMediumModal(this.content);
    })
  }

  /**
  * Open the passed popup model.
  *
  * @param mediumModalContent the model to be opened.
  */
  openMediumModal(mediumModalContent: any) {
    this.model = this.modalService.open(mediumModalContent);
  }

  goto(data: any) {
    this.model.close()
    switch (data.event_type) {
      case "homework":
        this.router.navigate(['/homeworks/individual/homework/', data.classid, data._id]);
        break;
      case "lesson_plan":
        // this.router.navigate(['/lesson/class/', data.classid, "sports", data.module_id, data.session_id]);
        if (data.is_child) {
          this.router.navigate([`/sessions/list/sessions/${data.classid}/${data.module_id}/${data.parent_id}`], { queryParams: { submodule: data.session_id } });
        } else {
          this.router.navigate([`/sessions/list/sessions/${data.classid}/${data.module_id}/${data.session_id}`]);
        }
        break;
      case "personal":
        this.router.navigate(['/personaldevs/personal/', data.classid, data._id]);
        break;
      default:
        break;
    }
  }

  filter() {
    this.calendarEvents = []
    this.events.forEach((event:any) => {
      if (this.hw_filt && event.event_type === "homework") {
        this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
          title: event.name,
          className: event.event_type,
          type: event.event_type,
          start: new Date(event.start_date),
          end: new Date(event.end_date),
        })
      }
      else if (this.lp_filt && event.event_type === "lesson_plan") {
        this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
          title: event.name,
          coach: event.coach,
          className: event.event_type,
          type: event.event_type,
          classid: event.classid,
          module: event.module,
          module_id: event.module_id,
          session_id: event.session_id,
          session: event.name,
          event_id: event._id,
          parent_id: event.parent_id,
          is_child: event.is_child,
          start: this.datepipe.transform(new Date(event.start_date), 'yyyy-MM-dd'),
          end: this.datepipe.transform(new Date(event.end_date), 'yyyy-MM-dd'),
        })
        // console.log(this.datepipe.transform(new Date(event.start_date), 'yyyy-MM-dd'), new Date(event.start_date), event.start_date)
      }
      else if (this.pd_filt && event.event_type === "personal") {
        this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
          title: event.name,
          className: event.event_type,
          type: event.event_type,
          start: new Date(event.start_date),
          end: new Date(event.end_date),
        })
      }

    });
  }

  filterClass() {
    this.calendarEvents = []
    this.classes.forEach((element: any) => {
      this.events.forEach((event: any) => {
        if (element.isSelected && event.classid === element._id && this.hw_filt && event.event_type === "homework") {
          this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
            title: event.name,
            coach: event.coach,
            className: event.event_type,
            type: event.event_type,
            classid: event.classid,
            module: event.module,
            module_id: event.module_id,
            session_id: event.session_id,
            session: event.name,
            event_id: event._id,
            start: this.datepipe.transform(new Date(event.start_date), 'yyyy-MM-dd'),
            end: this.datepipe.transform(new Date(event.end_date), 'yyyy-MM-dd'),
          })
        }
        if (element.isSelected && event.classid === element._id && this.lp_filt && event.event_type === "lesson_plan") {
          this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
            title: event.name,
            coach: event.coach,
            className: event.event_type,
            type: event.event_type,
            classid: event.classid,
            module: event.module,
            module_id: event.module_id,
            session_id: event.session_id,
            session: event.name,
            event_id: event._id,
            parent_id: event.parent_id,
            is_child: event.is_child,
            start: this.datepipe.transform(new Date(event.start_date), 'yyyy-MM-dd'),
            end: this.datepipe.transform(new Date(event.end_date), 'yyyy-MM-dd'),
          })
        }
        if (element.isSelected && event.classid === element._id && this.pd_filt && event.event_type === "personal") {
          this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
            title: event.name,
            coach: event.coach,
            className: event.event_type,
            type: event.event_type,
            classid: event.classid,
            module: event.module,
            module_id: event.module_id,
            session_id: event.session_id,
            session: event.name,
            event_id: event._id,
            start: this.datepipe.transform(new Date(event.start_date), 'yyyy-MM-dd'),
            end: this.datepipe.transform(new Date(event.end_date), 'yyyy-MM-dd'),
          })
        }
      });
    });
  }

  search() {
    this.classes = this.classesAll.filter((element: any) => {
      return element.basic_information.name.first_name.toLowerCase().includes(this.searchTerm.toLowerCase());
    })

  }
}
