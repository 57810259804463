<div class="page-header">
  <h3 class="page-title">
      <i class="bi bi-card-checklist menu-icon header-icon"></i>
      End User Liscense Agreement (EULA)
  </h3>
</div>
<div class="card">
  <div class="card-body">
      <div class="row">
          <div class="col-12">
              <div class="table-responsive">

                  <div class="row pb-4" style="margin: 0;">
                      <p>{{eula.value}}</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


