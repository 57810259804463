const HwAccessControl = {
    create: {
        permissions: [
            'hw-create',
            'hw-access-to-all'
        ]
    },
    view : {
        permissions: [
            'hw-view',
            'hw-access-to-all'
        ]
    },
    delete: {
        permissions: [
            'hw-delete',
            'hw-access-to-all'
        ]
    },
    view_homework_details : {
        permissions: [
            'hw-view-details',
            'hw-access-to-all'
        ]
    },
    create_task: {
        permissions: [
            'hw-add-new-task',
            'hw-access-to-all'
        ]
    },
    view_task: {
        permissions: [
            'hw-view-task',
            'hw-access-to-all'
        ]
    },
    edit_complete_status: {
        permissions: [
            'hw-complete-status',
            'hw-access-to-all'
        ]
    },
    view_student_update_indi: {
        permissions: [
            'hw-student-view',
            'hw-access-to-all'
        ]
    },
    approve_homework: {
        permissions: [
            'hw-approve',
            'hw-access-to-all'
        ]
    },
}

export { 
    HwAccessControl 
};