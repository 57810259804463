<a mat-list-item class="w-100 menu" [routerLink]="item().route"
  routerLinkActive="selected-menu-item"
  #rla = "routerLinkActive"
  [activated]="rla.isActive"
  (click)="toggleNested()"
  matTooltip="{{item().label}}"
  [matTooltipPosition]="'right'"
  >
    <mat-icon [fontSet]="rla.isActive?'material-icons':'material-icons-outlined' " matListItemIcon>{{ item().icon }}</mat-icon>
    @if (!collasped()) {
      <span matListItemTitle>{{ item().label }}</span>
    }
    @if (item().subItems) {
      <span matListItemMeta>
        @if (nestedMenuOpen()) {
          <mat-icon>expand_less</mat-icon>
        }@else {
          <mat-icon>expand_more</mat-icon>
        }
      </span>
    }
</a>

@if (item().subItems && nestedMenuOpen()) {
    <div @exapandContractMenu>
      @for (subItem of item().subItems; track subItem.label) {
        @if (subItem.access | module: subItem.access_label) {
        <a mat-list-item class="w-100 menu" [class.indented]="!collasped() && !isMobileView()"
          [routerLink]="item().route+'/'+subItem.route "
          routerLinkActive="selected-submenu-item"
          #rla = "routerLinkActive"
          [activated]="rla.isActive"
          matTooltip="{{subItem.label}}"
          [matTooltipPosition]="'right'"
          >
            <mat-icon [fontSet]="rla.isActive?'material-icons':'material-icons-outlined' " matListItemIcon>{{ subItem.icon }}</mat-icon>
            @if (!collasped()) {
              <span matListItemTitle>{{ subItem.label }}</span>
            }
        </a>
        }
      }
    </div>
}
