import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatusCodes } from '../data-models/https-reponse-code';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root'
})
export class ErrorHelpersService {

  handleHttpsError(err: HttpErrorResponse){
    if (err instanceof HttpErrorResponse) {
      switch(err.status){
        case HttpStatusCodes.Unauthorized:
          this.errorPrinter('Unauthorized request', err)
          break;
        case HttpStatusCodes.UnavailableForLegalReasons:
          this.errorPrinter('UnavailableForLegalReasons request', err)
          break;
        case HttpStatusCodes.BadRequest:
          this.errorPrinter('BadRequest request', err)
          break;
        case HttpStatusCodes.Forbidden:
          this.errorPrinter('Forbidden request', err)
          break;
        case HttpStatusCodes.NotFound:
          this.errorPrinter('NotFound request', err)
          break;
        case HttpStatusCodes.MethodNotAllowed:
          this.errorPrinter('MethodNotAllowed request', err)
          break;
        case HttpStatusCodes.NotAcceptable:
          this.errorPrinter('NotAcceptable request', err)
          break;
        case HttpStatusCodes.RequestTimeout:
          this.errorPrinter('RequestTimeout request', err)
          break;
        case HttpStatusCodes.InternalServerError:
          this.errorPrinter('InternalServerError request', err)
          break;
        case HttpStatusCodes.NotImplemented:
          this.errorPrinter('NotImplemented request', err)
          break;
        case HttpStatusCodes.BadGateway:
          this.errorPrinter('BadGateway request', err)
          break;
        case HttpStatusCodes.ServiceUnavailable:
          this.errorPrinter('ServiceUnavailable request', err)
          break;
        case HttpStatusCodes.GatewayTimeout:
          this.errorPrinter('GatewayTimeout request', err)
          break;
        case HttpStatusCodes.HttpVersionNotSupported:
          this.errorPrinter('HttpVersionNotSupported request', err)
          break;
        case HttpStatusCodes.VariantAlsoNegotiates:
          this.errorPrinter('VariantAlsoNegotiates request', err)
          break;
        case HttpStatusCodes.InsufficientStorage:
          this.errorPrinter('InsufficientStorage request', err)
          break;
        case HttpStatusCodes.LoopDetected:
          this.errorPrinter('LoopDetected request', err)
          break;
        case HttpStatusCodes.NotExtended:
          this.errorPrinter('NotExtended request', err)
          break;
        case HttpStatusCodes.NetworkAuthenticationRequired:
          this.errorPrinter('NetworkAuthenticationRequired request', err)
          break;
        default:
          this.errorPrinter('HTTP error', err);
          break
      }
    } else {
      this.errorPrinter('An error occurred', err);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logger(msg: any){
    if(!environment.production){
      console.log(msg)
    }
  }

  handleError(err: string, egg?: boolean){
    if(egg){
    //   console.log(`
    //     ☠☠☠☠☠☠☠
    // `);
    }
    if(environment.production){
      // console.log("%c ", "font-size: 1px; padding: 166.5px 250px; background-size: 500px 333px; background: no-repeat url(https://i.pinimg.com/236x/36/ab/81/36ab81cd8d63cf7c4a08f39403698c77--python-programming-programming-humor.jpg);");
      console.log(`
        ☠☠☠☠☠☠☠
    `);

    }else{
      console.error(`error:`, err)
    }
  }

  private errorPrinter(error_text: string, err: HttpErrorResponse){
    if(environment.production){
      const error_group = Number((err.status/100).toFixed())
      if(error_group == 3){
        //Handle 3xx Redirection Error
        console.log("3xx")
      }else if(error_group == 4){
        //Handle 4xx Client Error
        console.log("4xx")
      }else if(error_group == 5){
        //Handle 5xx Server Error
        console.log("5xx")
      }
      console.log(error_text)
    }else{
      console.error(`${error_text}:`, err)
    }
  }
}
