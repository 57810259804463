/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environments';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  API_URL = `${environment.apiURL}`
  API_KEY_PINCODE = `${environment.pincode_api}`

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Get Individual Academic report details.
   *
   * @param class_id Object Id of the class.
   * @param id Object Id of the student.
   * @returns
   */
  getAcademicReport(class_id: any, id: any, start_date: any = "", end_date: any = "") {
    if (start_date == "" || end_date == "") {
      return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/academic`);
    }
    else {
      const params = new HttpParams().append('start_date', this.formatDate(start_date)).append('end_date', this.formatDate(end_date))
      return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/academic`, { params: params });
    }
  }

  /**
   * Get Individual Assessment report details.
   *
   * @param class_id Object Id of the class.
   * @param id Object Id of the student.
   * @returns
   */
  getAssementReport(class_id: any, id: any, start_date: any = "", end_date: any = "", assessmet_id: any = "null") {
    if (start_date == "" || end_date == "") {
      if (assessmet_id != "null") {
        const params = new HttpParams().append('assessment', assessmet_id)
        return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/assessment`, { params: params });
      } else {
        return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/assessment`);
      }
    }
    else {
      let params = new HttpParams().append('start_date', this.formatDate(start_date)).append('end_date', this.formatDate(end_date))
      if (assessmet_id != "null") {
        params = params.append('assessment', assessmet_id)
      }
      return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/assessment`, { params: params });
    }

  }

  /**
   * Get Individual Assessment list report details.
   *
   * @param class_id Object Id of the class.
   * @param id Object Id of the student.
   * @returns
   */
  getAssementReportList(class_id: any, id: any) {
    return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/assessment-list`);
  }

  /**
   * Get Individual Overall report details.
   *
   * @param class_id Object Id of the class.
   * @param id Object Id of the student.
   * @returns
   */
  getOverallReport(class_id: any, id: any) {
    // let params = new HttpParams().append('id', id)
    // return this.http.get(`${this.API_URL}/user/get-bio`, { params: params });
    return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/overall`);
  }

  /**
   *
   * @param date
   * @returns date in yyyy-mm-dd format
   */
  formatDate(date: any) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  /**
   * Get session list
   * @param class_id Objectid of the class
   * @param id Objectid of the user
   * @param start_date Date for filter
   * @param end_date Date for filter
   * @returns
   */
  getSessionList(class_id: any, id: any, start_date: any = "", end_date: any = "") {
    if (start_date == "" || end_date == "") {
      return this.http.get(this.API_URL + `/reports/${class_id}/${id}/sessions`);
    }
    else {
      const params = new HttpParams().append('start_date', this.formatDate(start_date)).append('end_date', this.formatDate(end_date))
      return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/sessions`, { params: params });
    }
  }

  /**
   * Get Personnel development list
   * @param class_id Objectid of the class
   * @param id Objectid of the user
   * @param start_date Date for filter
   * @param end_date Date for filter
   * @returns
   */
  getPdList(class_id: any, id: any, start_date: any = "", end_date: any = "") {
    if (start_date == "" || end_date == "") {
      return this.http.get(this.API_URL + `/reports/${class_id}/${id}/pd`);
    }
    else {
      const params = new HttpParams().append('start_date', this.formatDate(start_date)).append('end_date', this.formatDate(end_date))
      return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/pd`, { params: params });
    }
  }

  /**
   *
   * @param class_id Objectid of the class
   * @param id Objectid of the user
   * @param start_date Date for filter
   * @param end_date Date for filter
   * @returns
   */
  getOverAllList(class_id: any, id: any, start_date: any = "", end_date: any = "") {
    if (start_date == "" || end_date == "") {
      return this.http.get(this.API_URL + `/reports/${class_id}/${id}/overall`);
    }
    else {
      const params = new HttpParams().append('start_date', this.formatDate(start_date)).append('end_date', this.formatDate(end_date))
      return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/overall`, { params: params });
    }
  }

  /**
   * Get all the members of id
   * @param id
   * @returns
   */
  getMembersOfId(id: any) {
    return this.http.get(this.API_URL + `/members/${id}`);
  }

  /**\
   * Get feedback
   */
  getFeedback(class_id: any, id: any, start_date: any = "",) {
    return this.http.get(`${this.API_URL}/reports/${class_id}/${id}/feedback`)
  }

  /**
   * Create feedback of a report
   * @param class_id Objectid of the class of the user.
   * @param id Object of the user.
   * @param data Json data to be stored.
   * @returns
   */
  createFeedback(class_id: string, id: string, data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/reports/${class_id}/${id}/feedback`, data, config)
  }

  /**
   * Update feedback of a report
   * @param class_id Objectid of the class of the user.
   * @param id Object of the user.
   * @param data Json data to be stored.
   * @returns
   */
  editFeedback(class_id: string, id: string, data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.put(`${this.API_URL}/reports/${class_id}/${id}/feedback`, data, config)
  }

  /**
   * Delete a feedback
   * @param feedback_id Objectid of the user.
   * @returns
   */
  deleteFeedback(feedback_id: string) {
    return this.http.delete(`${this.API_URL}/reports/${feedback_id}/feedback`)
  }
}
