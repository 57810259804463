import { ApplicationConfig, provideZoneChangeDetection, isDevMode, enableProdMode } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideToastr } from 'ngx-toastr';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environments';
import { provideAnimations } from '@angular/platform-browser/animations';
import { httpHelperInterceptor } from './interceptors/http-helper.interceptor';
import { Ability, PureAbility } from '@casl/ability';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { CUSTOM_DATE_FORMATS } from './data-models/my-date-formats';
import { MAT_DATE_FORMATS } from '@angular/material/core';


if (environment.production) {
  enableProdMode();
}
export const appConfig: ApplicationConfig = {
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    provideZoneChangeDetection(
      { eventCoalescing: true }),
      provideRouter(routes, withInMemoryScrolling({
        scrollPositionRestoration: 'top', // Scroll to the top on navigation
        anchorScrolling: 'enabled' // Optional: enable anchor scrolling
      })),
      provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        // registrationStrategy: 'registerWhenStable:30000'
        registrationStrategy: 'registerImmediately'
    }),
    provideHttpClient(withInterceptors([httpHelperInterceptor])),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnimations(),
    provideToastr(),
    provideAnimationsAsync(),
    provideAnimationsAsync(),
    provideCharts(withDefaultRegisterables()),
  ]
};
