/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environments';
import { AuthenticationService } from '../../../services/authentication.service';
import { SharedModule } from '../../shared/shared.moudle';

@Component({
  selector: 'app-consent',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './consent.component.html',
  styleUrl: './consent.component.scss'
})
export class ConsentComponent implements OnInit {
  token: any
  type: any
  isAccpect = false
  isReject = false
  id: string | any
  pwa_url: string = environment.pwa_url


  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token')
    this.type = this.route.snapshot.paramMap.get('type')
    this.isAccpect = this.type.toLowerCase() === "agree"
    this.isReject = !this.isAccpect
    this.auth.verifyToken(this.token).subscribe((res: any) => {
      if (res["token"] === "expired") {
        //
      } else {
        this.auth.changeParentConsent(res['token']["Claims"]["academy"], this.isAccpect, res['token']["Claims"]["id"]).subscribe(() => {
          // console.log(res)
        })
      }
    })
  }
}
