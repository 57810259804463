<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="d-flex align-items-stretch auth auth-img-bg h-100">
  <div class="row flex-grow w-100 m-0">
      <div class="col-lg-6 d-flex align-items-center justify-content-center">
          <div class="auth-form-transparent text-left p-3" *ngIf="isReset">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4 *ngIf="isReset">Password reset,</h4>
              <h6 class="font-weight-light" *ngIf="isReset">Enter your new password for your dartle account.</h6>
              <h4 *ngIf="isGenerate">Generate password,</h4>
              <h6 class="font-weight-light" *ngIf="isGenerate">Enter your new password for your dartle account.</h6>
              <form class="pt-3" [formGroup]="resetForm" (ngSubmit)="onLogin()">
                  <div class="form-group">
                      <label for="exampleInputPassword">New password</label>
                      <div class="input-group">
                          <div class="input-group-prepend bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-right-0" (click)="password()">
                                  <i class="text-primary bi bi-lock"></i>
                              </span>
                          </div>
                          <input type="password" class="form-control form-control-lg border-left-0 border-right-0"
                              id="exampleInputPassword" [type]="show ? 'text' : 'password'"
                              formControlName="newpassword" placeholder="Password">
                          <div class="input-group-append bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-left-0" (click)="password()">
                                  <i class="geryscale"
                                      [ngClass]="show ? 'bi bi-eye-slash' : 'bi bi-eye' "></i>
                              </span>
                          </div>
                      </div>
                      <app-password-strength
                          [passwordToCheck]="resetForm.get('newpassword').value"></app-password-strength>
                      <div class="input-group pt-2"
                          *ngIf="resetForm.get('newpassword').errors?.required && submmited">
                          <small class="text-danger">*Password is required.</small>
                      </div>
                  </div>
                  <div class="form-group">
                      <label for="exampleInputPassword">Confirm password</label>
                      <div class="input-group">
                          <div class="input-group-prepend bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-right-0" (click)="password()">
                                  <i class="text-primary bi bi-lock"></i>
                              </span>
                          </div>
                          <input type="password" class="form-control form-control-lg border-left-0 border-right-0"
                              id="exampleInputPassword" [type]="show ? 'text' : 'password'"
                              formControlName="comfrimpassword" placeholder="Password">
                          <div class="input-group-append bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-left-0" (click)="password()">
                                  <i class="geryscale"
                                      [ngClass]="show ? 'bi bi-eye-slash' : 'bi bi-eye' "></i>
                              </span>
                          </div>
                      </div>
                      <div class="input-group pt-2"
                          *ngIf="resetForm.get('comfrimpassword').errors?.required && submmited">
                          <small class="text-danger">*Password is required.</small>
                      </div>
                      <div class="input-group pt-2" *ngIf="equals && submmited">
                          <small class="text-danger">Passwords do not match</small>
                      </div>
                  </div>
                  <div class="my-3">
                      <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn webkit-btn"
                          type="button" (click)="onLogin()">Confirm password</button>
                  </div>
              </form>
          </div>
          <div class="auth-form-transparent text-left p-3" *ngIf="isGenerate">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4 *ngIf="isReset">Password reset,</h4>
              <h6 class="font-weight-light" *ngIf="isReset">Enter your new password for your dartle account.</h6>
              <h4 *ngIf="isGenerate">Generate password,</h4>
              <h6 class="font-weight-light" *ngIf="isGenerate">Enter your new password for your dartle account.</h6>
              <form class="pt-3" [formGroup]="resetForm" (ngSubmit)="onLogin()">
                  <div class="form-group">
                      <label for="exampleInputPassword">New password</label>
                      <div class="input-group">
                          <div class="input-group-prepend bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-right-0" (click)="password()">
                                  <i class="text-primary bi bi-lock"></i>
                              </span>
                          </div>
                          <input type="password" class="form-control form-control-lg border-left-0 border-right-0"
                              id="exampleInputPassword" [type]="show ? 'text' : 'password'"
                              formControlName="newpassword" placeholder="Password">
                          <div class="input-group-append bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-left-0" (click)="password()">
                                  <i class="geryscale"
                                      [ngClass]="show ? 'bi bi-eye-slash' : 'bi bi-eye' "></i>
                              </span>
                          </div>
                      </div>
                      <app-password-strength
                          [passwordToCheck]="resetForm.get('newpassword').value"></app-password-strength>
                      <div class="input-group pt-2"
                          *ngIf="resetForm.get('newpassword').errors?.required && submmited">
                          <small class="text-danger">*Password is required.</small>
                      </div>
                  </div>
                  <div class="form-group">
                      <label for="exampleInputPassword">Confrim password</label>
                      <div class="input-group">
                          <div class="input-group-prepend bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-right-0" (click)="password()">
                                  <i class="text-primary bi bi-lock"></i>
                              </span>
                          </div>
                          <input type="password" class="form-control form-control-lg border-left-0 border-right-0"
                              id="exampleInputPassword" [type]="show ? 'text' : 'password'"
                              formControlName="comfrimpassword" placeholder="Password">
                          <div class="input-group-append bg-transparent clickable">
                              <span class="input-group-text bg-transparent border-left-0" (click)="password()">
                                  <i class="geryscale"
                                      [ngClass]="show ? 'bi bi-eye-slash' : 'bi bi-eye' "></i>
                              </span>
                          </div>
                      </div>
                      <div class="input-group pt-2"
                          *ngIf="resetForm.get('comfrimpassword').errors?.required && submmited">
                          <small class="text-danger">*Password is required.</small>
                      </div>
                      <div class="input-group pt-2" *ngIf="equals && submmited">
                          <small class="text-danger">Passwords do not match</small>
                      </div>
                  </div>
                  <div class="my-3">
                      <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn webkit-btn"
                          type="button" (click)="onLogin()">Confirm Password</button>
                  </div>
              </form>
          </div>
          <div class="auth-form-transparent text-left p-3" *ngIf="isExpired">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Oh no, This link has been expired!</h4>
              <h6 class="font-weight-light">To recreate a new password reset link, please go to the forgot password
                  page.</h6>
              <div>
                  <div class="my-3">
                      <button
                          class="btn btn-block btn-outline-primary btn-lg font-weight-medium auth-form-btn webkit-btn"
                          routerLink="/password" type="submit">Go back to forgot password</button>
                  </div>
              </div>
          </div>
          <div class="auth-form-transparent text-left p-3" *ngIf="isCompleted">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Password changed!</h4>
              <h6 class="font-weight-light">Your password has been changed sucessfully, Use your new password to log
                  in.</h6>
              <div>
                  <div class="my-3">
                      <button
                          class="btn btn-block btn-outline-primary btn-lg font-weight-medium auth-form-btn webkit-btn"
                          routerLink="/login" type="submit">Go back to login</button>
                  </div>
              </div>
          </div>
          <div class="auth-form-transparent text-left p-3" *ngIf="isCompletedGenerate && !not_a_Player">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Password created!</h4>
              <h6 class="font-weight-light">You can get Dartle here.</h6>
              <div class="mt-4 flex">
                  <a class="image-container clickable" href="{{pwa_url}}">
                      <img src="images/icons/web.png" class="platform-image" alt="icon">
                  </a>
                  <!-- <div class="image-container clickable" *ngIf="not_a_Player">
                      <img src="images/icons/web.png" class="platform-image" routerLink="/">
                  </div> -->
              </div>
          </div>
          <div class="auth-form-transparent text-left p-3" *ngIf="isCompletedGenerate && not_a_Player">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Password created!</h4>
              <h6 class="font-weight-light">You can get Dartle here.</h6>
              <div class="mt-4 flex">
                  <a class="image-container clickable" href="{{base_url}}">
                      <img src="images/icons/web.png" class="platform-image" alt="icon">
                  </a>
                  <!-- <div class="image-container clickable" *ngIf="not_a_Player">
                      <img src="images/icons/web.png" class="platform-image" routerLink="/">
                  </div> -->
              </div>
          </div>
      </div>
      <div class="col-lg-6 forgot-reset-half-bg d-flex flex-row">
      </div>
  </div>
</div>
