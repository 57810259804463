<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="row pl-3 pr-3">
  <div class="col-12">
    <div class="row" style="padding-bottom: 7rem;">
      <div class="col-12 profile-container">
        <!-- <div class="cover-bg"></div> -->
        <div class="cover-bg">
          <img src="{{user.basic_information.cover_photo}}" loading="lazy" (error)="onCoverImgError($event)" class="cover-bg-img">
          <!-- <button class="btn btn-outline-primary cover-bg-add"
            (click)="openCoverUploadModal(coverUploadModel, 'coverphoto')">hi</button> -->
          <img src="images/icons/Add_coverpic_button.png" class="cover-bg-add"
            (click)="openCoverUploadModal(coverUploadModel, 'coverphoto')">
        </div>
        <div class="baisc-info col-12">
          <img src="{{user.basic_information.dp}}" class="profile-pic-main" loading="lazy" (error)="onImgError($event)">
          <div class="profile-add-contain">
            <!-- <img class="profile-add" src="images/icons/ic_plus_white.png"> -->
            <img class="profile-add" (click)="openCoverUploadModal(dpUploadModel, 'dp')"
               src="images/icons/profile_photo_camera.png">
          </div>
          <div class="profile-type" style="min-width: 3rem;">
            <div class="profile-name over-flow-word">{{academy_name || ""}}</div>
            <div class="type-container">
              <div class="profile-type-text over-flow-word" *ngIf="vaildDate">
                Academy: ESTD. {{user.basic_information.dob | dateTime:'YYYY'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3 profile-pad">
      <div class="col-12 mb-3 flex grid-gap mobile">
        <div class="col-lg-6 flex flex-c p-0 grid-gap" style="height: fit-content;">
          <div class="col-lg-12 p-0">
            <div class="card  mobile-card">
              <div class="header pb-4" style="display: flex;justify-content: space-between;">{{'Academy Info'| string:'title'}}<span
                  class="pending-see-more" *ngIf="false">Edit</span></div>
              <div class="personal-info">
                <img src="images/icons/ic_email_orange.png" class="icon">
                <span class="over-flow-word">{{user.basic_information.email}}</span>
              </div>
              <div class="flex personal-info" style="margin: .5rem;grid-gap:1rem;">
                <div *ngFor="let sport of user.sports">
                  <img src="images/icons/ic_sport_football.png" class="icon" style="transform: translateY(0%); margin-right: 0.5rem;">
                  <span>{{sport.sport_name || 'Not Given'}}</span>
                </div>
              </div>
              <div class="personal-info" *ngIf="user.basic_information.address">
                <img src="images/icons/ic_location_orange.png" class="icon">
                <p style="text-transform: capitalize;">
                  <span
                    *ngIf="user.basic_information.address[0].addr1.length > 0">{{user.basic_information.address[0].addr1}},</span>
                  <span
                    *ngIf="user.basic_information.address[0].city.length > 0">{{user.basic_information.address[0].city}},</span><br>
                  <span
                    *ngIf="user.basic_information.address[0].district.length > 0">{{user.basic_information.address[0].district}},</span>
                  <span
                    *ngIf="user.basic_information.address[0].pincode.length > 0">{{user.basic_information.address[0].pincode}},</span><br>
                  <span
                    *ngIf="user.basic_information.address[0].state.length > 0">{{user.basic_information.address[0].state}},</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-12 p-0" style="display: none;">
            <div class="card ">
              <div class="header pb-4">Latest updates</div>
              <div class="updates-info">
                <div class="update-elem">
                  <h6 class="update-header">25 new students joined the academy.</h6>
                  <span class="update-text">09-04-2022</span>
                </div>
                <div class="update-elem">
                  <h6 class="update-header">3 new personnel development sessions created on 09-04-2022.</h6>
                  <span class="update-text">09-04-2022</span>
                </div>
                <div class="update-elem">
                  <h6 class="update-header">3 new coaches accepted invites.</h6>
                  <span class="update-text">09-04-2022</span>
                </div>
                <div class="update-elem">
                  <h6 class="update-header">3 new coaches accepted invites.</h6>
                  <span class="update-text">09-04-2022</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 p-0" style="display: none;">
            <div class="card ">
              <div class="header pb-4"><img src="images/icons/ic_crown.png" class="calender"> Top 25 students in
                Academics</div>
              <div class="top-info">
                <div class="top-elem">
                  <span class="top-serial">1</span>
                  <div class="top-contain">
                    <div>
                      <img src="{{user.basic_information.dp}}" class="profile-top">
                    </div>
                    <div>
                      <h6 class="top-header">Steve Buscemi</h6>
                      <span class="top-text">Class 10: Football</span>
                    </div>
                  </div>
                  <div class="top-percentage">96.03%</div>
                </div>
                <div class="top-elem">
                  <span class="top-serial">2</span>
                  <div class="top-contain">
                    <div>
                      <img src="{{user.basic_information.dp}}" class="profile-top">
                    </div>
                    <div>
                      <h6 class="top-header">Tilda Swinton</h6>
                      <span class="top-text">Class 7: Cricket</span>
                    </div>
                  </div>
                  <div class="top-percentage">91.52%</div>
                </div>
                <div class="top-elem">
                  <span class="top-serial">3</span>
                  <div class="top-contain">
                    <div>
                      <img src="{{user.basic_information.dp}}" class="profile-top">
                    </div>
                    <div>
                      <h6 class="top-header">Ryan Gosling</h6>
                      <span class="top-text">Class 10: Cricket</span>
                    </div>
                  </div>
                  <div class="top-percentage">87.00%</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 p-0" *ngIf="dashboardAccessControl.view_invites_pending.permissions | module: 'dashboard'">
            <div class="card  mobile-card">
              <div class="header pb-4" style="display: flex;justify-content: space-between;">{{'Pending invites' | string:'title'}}<span class="pending-see-more" *ngIf="false">See all</span></div>
              <div class="top-info">
                <div class="top-elem" *ngFor="let elem of member_pending">
                  <div class="pending-contain">
                    <div>
                      <img src="{{elem.dp}}" (error)="onImgError($event)" class="profile-top">
                    </div>
                    <div>
                      <h6 class="top-header mb-0" style="text-transform: capitalize;">{{elem.name}}</h6>
                      <span class="top-text">{{elem.page_role}}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="member_pending.length === 0 && dataFetchSuccess" class="no-data">
                  No pending invites.
                </div>
                <div *ngIf="isLoading && !dataFetchSuccess" class="no-data">
                  Loading pending invites...
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-6 p-0 flex flex-c  grid-gap" style="height: fit-content;">

          <div class="col-lg-12 flex-elem p-0">
            <div class="card  mobile-card">
              <div class="stats-contain">
                <div class="stats-conatin-text student-color">
                  <img src="images/icons/ic_student.png" class="stats-icon">
                  <div class="stats-text">
                    <span style="font-size: var(--fs-header); margin-right: 10px;">{{noOfStudents}}</span>
                    <span class="over-flow-word">Students</span>
                  </div>
                </div>
                <!-- <div class="stats-add-contain student-color-plus"> -->
                  <div class="stats-add-contain student-color-plus" [routerLink]="['/members/list/add_player']" [queryParams]="{ type: 'Player'}"
                    *ngIf="memberAccessControl.create_member_listing.permissions | module: 'member management'">
                    <img src="images/icons/ic_plus_white.png"  class="stats-add">
                  </div>
                <!-- </div> -->
              </div>
              <div class="stats-contain">
                <div class="stats-conatin-text coach-color">
                  <img src="images/icons/ic_coach.png " class="stats-icon">
                  <div class="stats-text">
                    <span style="font-size: var(--fs-header); margin-right: 10px;">{{noOfCoach}}</span>
                    <span class="over-flow-word">Coaches</span>
                  </div>
                </div>
                <!-- <div class="stats-add-contain coach-color-plus"> -->
                  <div class="stats-add-contain coach-color-plus" [routerLink]="['/members/list/add_coach']" [queryParams]="{ type: 'Coach'}"
                    *ngIf="memberAccessControl.create_member_listing.permissions | module: 'member management'">
                    <img src="images/icons/ic_plus_white.png" class="stats-add">
                  </div>
                <!-- </div> -->
              </div>
              <div class="stats-contain">
                <div class="stats-conatin-text class-color">
                  <img src="images/icons/ic_class.png" class="stats-icon">
                  <div class="stats-text">
                    <span style="font-size: var(--fs-header); margin-right: 10px;">{{noOfGroups}}</span>
                    <span class="over-flow-word">Groups</span>
                  </div>
                </div>
                <div class="stats-add-contain class-color-plus">
                  <img src="images/icons/ic_plus_white.png" [routerLink]="['/groups/add']" class="stats-add"
                    *ngIf="classAccessControl.create.permissions | module: 'groups management'">
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 flex-elem p-0" *ngIf="dashboardAccessControl.view_today_events.permissions | module: 'dashboard'">
            <div class="card  mobile-card">
              <div class="calender-contain">
                <div class="calender-header-contain over-flow-word">
                  <div class="header" style="padding-bottom: 5px;">{{'Today' | string:'title'}}</div>
                  <span class="calender-text">{{today | dateTime:'dd | MMM | yyyy | EEEE'}}</span>
                </div>
                <div class="calendar-view" [routerLink]="['/calendar']">
                  <i class="bi bi-calendar"></i>
                  <!-- <img src="images/icons/calander_new.png"class="calender"> -->
                  </div>
              </div>
              <div class="calender-info mt-4">
                <div class="calender-elem calender-sports" *ngIf="sports.length > 0">
                  <h6 class="calender-elem-header calender-elem-texts1">Sports sessions: {{sportsCountToday}}</h6>
                  <span class="calender-elem-text calender-elem-texts1">{{classes[0].basic_information.name.first_name}}
                    <span *ngIf="classes.length > 1">and {{classes.length -1}} other Groups</span>
                  </span>
                </div>
                <div class="calender-elem calender-pd" *ngIf="pds.length > 0">
                  <h6 class="calender-elem-header calender-elem-texts2">P.D sessions: {{pdCountToday}}</h6>
                  <span class="calender-elem-text calender-elem-texts2">{{classes[0].basic_information.name.first_name}}
                    <span *ngIf="classes.length > 1">and {{classes.length -1}} other Groups</span>
                  </span>
                </div>
                <div class="calender-elem calender-homework" *ngIf="homeworks.length > 0">
                  <h6 class="calender-elem-header calender-elem-texts3">Homeworks: {{homeworkCountToday}}</h6>
                  <span class="calender-elem-text calender-elem-texts3">{{classes[0].basic_information.name.first_name}}
                    <span *ngIf="classes.length > 1">and {{classes.length -1}} other Groups</span>
                  </span>
                </div>
                <div class="calender-elem no-data"
                  *ngIf="homeworks.length === 0 && sports.length === 0 && pds.length === 0 && calenderLoaded">
                  No events scheduled.
                </div>
                <div class="calender-elem no-data" *ngIf="!calenderLoaded">
                  Loading events...
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 flex-elem p-0" style="display: none;">
            <div class="card ">
              <div class="header pb-4"><img src="images/icons/ic_crown.png" class="calender"> Top 5 classes
              </div>
              <div class="top-info">
                <div class="top-elem">
                  <span class="top-serial">1</span>
                  <div class="top-contain">
                    <div>
                      <img src="{{user.basic_information.dp}}" class="profile-top">
                    </div>
                    <div>
                      <h6 class="top-header">Steve Buscemi</h6>
                      <span class="top-text">Class 10: Football</span>
                    </div>
                  </div>
                  <!-- <div class="top-percentage">96.03%</div> -->
                </div>
                <div class="top-elem">
                  <span class="top-serial">2</span>
                  <div class="top-contain">
                    <div>
                      <img src="{{user.basic_information.dp}}" class="profile-top">
                    </div>
                    <div>
                      <h6 class="top-header">Tilda Swinton</h6>
                      <span class="top-text">Class 7: Cricket</span>
                    </div>
                  </div>
                  <!-- <div class="top-percentage">91.52%</div> -->
                </div>
                <div class="top-elem">
                  <span class="top-serial">3</span>
                  <div class="top-contain">
                    <div>
                      <img src="{{user.basic_information.dp}}" class="profile-top">
                    </div>
                    <div>
                      <h6 class="top-header">Ryan Gosling</h6>
                      <span class="top-text">Class 10: Cricket</span>
                    </div>
                  </div>
                  <!-- <div class="top-percentage">87.00%</div> -->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row pl-3 pr-3" *ngIf="dashboardAccessControl.view_gallery.permissions | module: 'dashboard'">
  <div class="col-lg-12 grid-margin stretch-card gallery-pad">
    <div class="col-lg-12 flex-elem p-0 w-100">
      <div class="card  mobile-card">
        <div class="header pb-4 w-100 d-flex justify-content-between">
          <span>{{'Gallery' | string:'title'}}</span>
          <p class="text-base c-secondary cursor-pointer" [routerLink]="['/gallery/' + id]">
            View all
          </p>
        </div>

        <div class="gallery-contain-filter mb-4">
          <div class="type-filter">
            <div class="gallery-header">Type</div>
            <div class="upload-contain">
              <ul ngbNav [activeId]="1">
                  <li [ngbNavItem]="1" [ngClass]="{'activee': selectedFilter === ''}">
                      <a ngbNavLink class="item mr-3 mb-2" (click)="filter(''); selectedFilter = ''">All</a>
                  </li>
                  <li [ngbNavItem]="2" [ngClass]="{'activee': selectedFilter === 'image'}">
                      <a ngbNavLink class="item mr-3 mb-2" (click)="filter('image'); selectedFilter = 'image'">Images</a>
                  </li>
                  <li [ngbNavItem]="3" [ngClass]="{'activee': selectedFilter === 'video'}">
                      <a ngbNavLink class="item mb-2" (click)="filter('video'); selectedFilter = 'video'">Videos</a>
                  </li>
              </ul>
          </div>
          </div>
        </div>
        <div class="gallery">
          <ng-container *ngIf="galleryLoading">
            <div class="gallery-item shimmer" *ngFor="let _ of [1,2,3,4,5,6]"></div>
          </ng-container>
          <div *ngFor="let feeds of user_feed" class="contain-image">
            <div [ngClass]="{'image': feeds.uploads[0].type === 'image', 'video' : feeds.uploads[0].type === 'video'}"
              (click)="openMediumModal(feedModel, feeds)"></div>
            <img src="{{feeds.uploads[0].thumbnail_url|| feeds.uploads[0].url}}" class="gallery-item"
              title="{{feeds.uploads[0].type}}" (click)="openMediumModal(feedModel, feeds)">
          </div>
        </div>
        <ng-container *ngIf="!galleryLoading">
          <div class="col-12 no-data w-100" *ngIf="user_feed === null || user_feed.length === 0">
            No posts yet
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->


<ng-template #feedModel let-modal class="modal fade" id="exampleModal-2" aria-labelledby="exampleModalLabel-2">
  <div class="modal-content">
    <div class="modal-body" style="display: grid;grid-gap: 15px;">
      <div class="col-12">
        <div class="row template-container pl-0 pr-0">
          <ngb-carousel *ngIf="current_feed.uploads" #carousel class="f-width">
            <ng-template ngbSlide *ngFor="let posts of current_feed.uploads; let i = index;">
              <div class="picsum-img-wrapper" *ngIf="posts.type === 'image'">
                <img [src]="posts.url" alt="Image {{i+1}}" class="post-feed">
              </div>
              <div class="model-img" *ngIf="posts.type === 'video'">
                <video controls class="post-feed">
                  <source src="{{posts.url}}" type="video/mp4">
                  <source src="{{posts.url}}" type="video/ogg">
                  Your browser does not support the video tag.
                </video>
              </div>
              <img class="model-close" src="images/icons/ic_close_circle.png" (click)="modal.dismiss()">
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="modal fade" #coverUploadModel let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title bd-7" id="exampleModalLabel">Choose cover picture</h6>
      <img class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()"
        class="model-close" src="images/icons/cancel.png">
    </div>
    <input type="file" (change)="fileChangeEvent($event, true)" accept="image/png, image/jpeg" #myfileid id="myfileid"
      style="display: none;" />
    <div class="modal-body">
      <button type="button" class="col-12 btn btn-outline-dark btn-lg btn-block add-cover" (click)="openFileSelect()"
        style="padding: 0.3rem 1rem 0.3rem 1rem;border: 2px solid black;">
        <img class="close" style="width: 1.8rem; margin-bottom: 3px; margin-right: 15px;" src="images/icons/add_circle.png">
        <span class="ml-2">Upload cover picture</span>
      </button>
      <a class="image-placeholder" href="javascript:void(0)" *ngIf="fileSelect" (click)="openFileSelect()">
        <h3>Please select a image.</h3>
        <img src="samples/ic_add_image.png" alt="icon" />
      </a>
      <div class="mt-2" style="display: block;  margin: auto;">
        <image-cropper [imageChangedEvent]="coverImageChangedEvent" [maintainAspectRatio]="true" [resizeToWidth]="1920"
          [resizeToHeight]="800" [aspectRatio]="4 / 1" format="png" (imageCropped)="imageCropped($event)"
          [output]="'base64'"
          (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
          class="cropper-maxh"></image-cropper>
      </div>
    </div>
    <div class="modal-footer">
      <div class="w-100 d-flex justify-content-between" style="gap: 3rem;">
        <button matRipple class="w-100 btn btn-outline-dark main-height" data-dismiss="modal" (click)="modal.dismiss()">Cancel</button>
        <button matRipple type="button" class="w-100 btn btn-primary main-height" data-dismiss="modal" (click)="uploadCropped(); modal.close()">Save</button>
      </div>
      <!-- <button type="button" class="btn btn-dark  al-start" (click)="openFileSelect()">Choose File</button>
      <button type="button" class="btn btn-success" (click)="uploadCropped(); modal.close()">Submit</button>
      <button type="button" class="btn btn-light" data-dismiss="modal" (click)="modal.close()">Cancel</button> -->
    </div>
  </div>
</ng-template>

<ng-template class="modal fade" #dpUploadModel let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title bd-7" id="exampleModalLabel">Choose profile picture</h6>
      <img class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()"
        class="model-close" src="images/icons/cancel.png">
    </div>
    <input type="file" (change)="fileChangeEvent($event, false)" accept="image/png, image/jpeg" #myfileid id="myfileid"
      style="display: none;" />
    <div class="modal-body">
      <button type="button" class="col-12 btn btn-outline-dark btn-lg btn-block add-cover" (click)="openFileSelect()"
        style="padding: 0.3rem 1rem 0.3rem 1rem;border: 2px solid black;">
        <img class="close" style="width: 1.8rem; margin-bottom: 3px; margin-right: 15px;" src="images/icons/add_circle.png">
        <span class="ml-2">Upload profile picture</span>
      </button>
      <div class="image-placeholder" *ngIf="fileSelect" (click)="openFileSelect()">
        <h3>Please select a image.</h3>
        <img src="images/samples/ic_add_image.png" />
      </div>
      <div class="mt-2" style="display: block; margin: auto;">
        <image-cropper [imageChangedEvent]="profileImageChangedEvent" [maintainAspectRatio]="true" [resizeToWidth]="1200"
          [resizeToHeight]="1200" [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)"
          [output]="'base64'"
          (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
          class="cropper-maxh"></image-cropper>
      </div>
    </div>
    <div class="modal-footer">
      <div class="w-100 d-flex justify-content-between" style="gap: 3rem;">
        <button matRipple class="w-100 btn btn-outline-dark main-height" data-dismiss="modal" (click)="modal.dismiss()">Cancel</button>
        <button matRipple type="button" class="w-100 btn btn-primary main-height" data-dismiss="modal" (click)="uploadCropped(); modal.close()">Save</button>
      </div>
      <!-- <button type="button" class="btn btn-dark  al-start" (click)="openFileSelect()">Choose File</button>
      <button type="button" class="btn btn-success" (click)="uploadCropped(); modal.close()">Submit</button>
      <button type="button" class="btn btn-light" data-dismiss="modal" (click)="modal.close()">Cancel</button> -->
    </div>
  </div>
</ng-template>




<!-- <div class="row mob" style="display: none;">
  <div class="col-lg-7 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="d-flex flex-wrap justify-content-between">
          <h4 class="card-title">Visit And Sales Statistics</h4>
          <div id="visit-sale-chart-legend" class="rounded-legend legend-horizontal">
            <ul>
              <li>
                <span class="legend-dots"></span>CHN
              </li>
              <li>
                <span class="legend-dots"></span>USA
              </li>
              <li class="mr-0">
                <span class="legend-dots"></span>UK
              </li>
            </ul>
          </div>
        </div>
        <canvas baseChart #visitSaleChart [chartType]="'bar'" [datasets]="visitSaleChartData"
          [labels]="visitSaleChartLabels" [options]="visitSaleChartOptions" [colors]="visitSaleChartColors"></canvas>
      </div>
    </div>
  </div>
  <div class="col-lg-5 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Traffic Sources</h4>
        <canvas baseChart [chartType]="'doughnut'" [datasets]="trafficChartData" [labels]="trafficChartLabels"
          [options]="trafficChartOptions" [colors]="trafficChartColors"></canvas>
        <div id="traffic-chart-legend" class="rounded-legend legend-vertical legend-bottom-left pt-4">
          <ul>
            <li>
              <span class="legend-dots"></span>Search Engines
              <span class="float-right">30%</span>
            </li>
            <li>
              <span class="legend-dots"></span>Direct Click
              <span class="float-right">30%</span>
            </li>
            <li>
              <span class="legend-dots"></span>Bookmarks Click
              <span class="float-right">40%</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="row mob" style="display: none;">
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Recent Tickets</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  Assignee
                </th>
                <th>
                  Subject
                </th>
                <th>
                  Status
                </th>
                <th>
                  Last Update
                </th>
                <th>
                  Tracking ID
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src="images/faces/face1.jpg" class="mr-2" alt="image">
                  David Grey
                </td>
                <td>
                  Fund is not recieved
                </td>
                <td>
                  <label class="badge badge-gradient-success">DONE</label>
                </td>
                <td>
                  Dec 5, 2017
                </td>
                <td>
                  WD-12345
                </td>
              </tr>
              <tr>
                <td>
                  <img src="images/faces/face2.jpg" class="mr-2" alt="image">
                  Stella Johnson
                </td>
                <td>
                  High loading time
                </td>
                <td>
                  <label class="badge badge-gradient-warning">PROGRESS</label>
                </td>
                <td>
                  Dec 12, 2017
                </td>
                <td>
                  WD-12346
                </td>
              </tr>
              <tr>
                <td>
                  <img src="images/faces/face3.jpg" class="mr-2" alt="image">
                  Marina Michel
                </td>
                <td>
                  Website down for one week
                </td>
                <td>
                  <label class="badge badge-gradient-info">ON HOLD</label>
                </td>
                <td>
                  Dec 16, 2017
                </td>
                <td>
                  WD-12347
                </td>
              </tr>
              <tr>
                <td>
                  <img src="images/faces/face4.jpg" class="mr-2" alt="image">
                  John Doe
                </td>
                <td>
                  Loosing control on server
                </td>
                <td>
                  <label class="badge badge-gradient-danger">REJECTED</label>
                </td>
                <td>
                  Dec 3, 2017
                </td>
                <td>
                  WD-12348
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="row mob" style="display: none;">
  <div class="col-md-5 grid-margin stretch-card">
    <div class="card custom-dashboard">
      <ngb-datepicker #dp [(ngModel)]="date" (navigate)="date = $event.next" class="w-100 border-0"></ngb-datepicker>
    </div>
  </div>
  <div class="col-md-7 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Recent Updates</h4>
        <div class="d-flex">
          <div class="d-flex align-items-center mr-4 text-muted font-weight-light">
            <i class="mdi mdi-account-outline icon-sm mr-2"></i>
            <span>jack Menqu</span>
          </div>
          <div class="d-flex align-items-center text-muted font-weight-light">
            <i class="mdi mdi-clock icon-sm mr-2"></i>
            <span>October 3rd, 2018</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 pr-1">
            <img src="images/dashboard/img_1.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
            <img src="images/dashboard/img_4.jpg" class="mw-100 w-100 rounded" alt="image">
          </div>
          <div class="col-6 pl-1">
            <img src="images/dashboard/img_2.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
            <img src="images/dashboard/img_3.jpg" class="mw-100 w-100 rounded" alt="image">
          </div>
        </div>
        <div class="d-flex mt-5 align-items-top">
          <img src="images/faces/face3.jpg" class="img-sm rounded-circle me-3" alt="image">
          <div class="mb-0 flex-grow">
            <h5 class="mr-2 mb-2">School Website - Authentication Module.</h5>
            <p class="mb-0 font-weight-light">It is a long established fact that a reader will be distracted by the
              readable
              content of a page.</p>
          </div>
          <div class="ml-auto">
            <i class="mdi mdi-heart-outline text-muted"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="row mob" style="display: none;">
  <div class="col-md-7 grid-margin grid-margin-md-0 stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Project Status</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  Name
                </th>
                <th>
                  Due Date
                </th>
                <th>
                  Progress
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  1
                </td>
                <td>
                  Herman Beck
                </td>
                <td>
                  May 15, 2015
                </td>
                <td>
                  <div class="progress">
                    <div class="progress-bar bg-gradient-success" role="progressbar" style="width: 25%"
                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  2
                </td>
                <td>
                  Messsy Adam
                </td>
                <td>
                  Jul 01, 2015
                </td>
                <td>
                  <div class="progress">
                    <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 75%"
                      aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  3
                </td>
                <td>
                  John Richards
                </td>
                <td>
                  Apr 12, 2015
                </td>
                <td>
                  <div class="progress">
                    <div class="progress-bar bg-gradient-warning" role="progressbar" style="width: 90%"
                      aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  4
                </td>
                <td>
                  Peter Meggik
                </td>
                <td>
                  May 15, 2015
                </td>
                <td>
                  <div class="progress">
                    <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 50%"
                      aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  5
                </td>
                <td>
                  Edward
                </td>
                <td>
                  May 03, 2015
                </td>
                <td>
                  <div class="progress">
                    <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 35%"
                      aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  5
                </td>
                <td>
                  Ronald
                </td>
                <td>
                  Jun 05, 2015
                </td>
                <td>
                  <div class="progress">
                    <div class="progress-bar bg-gradient-info" role="progressbar" style="width: 65%" aria-valuenow="65"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="row mob" >
  <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Academy Calendar</h4>
        <app-calendar [type]="currentItem" [c_user_id]=""></app-calendar>
      </div>
    </div>
  </div>
</div> -->
