const SessionAccessControl = {
    create: {
        permissions: [
            'session-create-module',
            'session-access-to-all'
        ]
    },
    edit: {
        permissions: [
            'session-edit-module',
            'session-access-to-all'
        ]
    },
    delete: {
        permissions: [
            'session-delete-module',
            'session-access-to-all'
        ]
    },
    view: {
        permissions: [
            'session-view-module',
            'session-access-to-all'
        ]
    },
    create_session: {
        permissions: [
            'session-create-session',
            'session-access-to-all'
        ]
    },
    view_session: {
        permissions: [
            'session-view-session',
            'session-access-to-all'
        ]
    },
    edit_session: {
        permissions: [
            'session-edit-session',
            'session-access-to-all'
        ]
    },
    delete_session: {
        permissions: [
            'session-delete-session',
            'session-access-to-all'
        ]
    },
    view_session_details: {
        permissions: [
            'session-view-details',
            'session-access-to-all'
        ]
    },
    edit_session_complete: {
        permissions: [
            'session-edit-complete',
            'session-access-to-all'
        ]
    },
    view_session_calendar: {
        permissions: [
            'session-view-calendar',
            'session-access-to-all'
        ]
    },
    view_session_attendence: {
        permissions: [
            'session-view-attendance',
            'session-access-to-all'
        ]
    },
    create_session_attendence: {
        permissions: [
            'session-add-attendance',
            'session-access-to-all'
        ]
    },
    edit_session_attendence: {
        permissions: [
            'session-edit-attendance',
            'session-access-to-all'
        ]
    },
    create_session_activity: {
        permissions: [
            'session-add-activity',
            'session-access-to-all'
        ]
    },
    view_session_activity: {
        permissions: [
            'session-view-activities',
            'session-access-to-all'
        ]
    },
    view_session_activity_detail: {
        permissions: [
            'session-view-activities-detail',
            'session-access-to-all'
        ]
    },
    delete_session_activity: {
        permissions: [
            'session-delete-activity',
            'session-access-to-all'
        ]
    },
    edit_session_activity_status: {
        permissions: [
            'session-edit-activity-status',
            'session-access-to-all'
        ]
    },
    view_indi_recurring_session: {
        permissions: [
            'session-view-indi-recurring',
            'session-access-to-all'
        ]
    },
    /** these edit and delete not used, session edit delete permissions used.*/
    edit_indi_recurring_session: {
        permissions: [
            'session-edit-indi-recurring',
            'session-access-to-all'
        ]
    },
    delete_indi_recurring_session: {
        permissions: [
            'session-delete-indi-recurring',
            'session-access-to-all'
        ]
    },
    
}

export { 
    SessionAccessControl 
};