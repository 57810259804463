const CalendarsAccessControl = {
    view : {
        permissions: [
            'calendar-view',
            'calendar-access-to-all'
        ]
    },
    view_events : {
        permissions: [
            'calendar-view-events',
            'calendar-access-to-all'
        ]
    },
    view_events_details : {
        permissions: [
            'calendar-view-events-details',
            'calendar-access-to-all'
        ]
    },
}

export {
    CalendarsAccessControl
};