import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environments';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CalenderService {

  API_URL = `${environment.apiURL}`

  constructor(
    private http: HttpClient,
  ) { }



  /**
   * Get the calender events of the Academy
   *
   * @param id Object Id, userid of the academy
   * @param start_date Start Date of the range (DD-MM-YYYY)
   * @param end_start End Date of the range (DD-MM-YYYY)
   * @returns Array of Calender Events
   */
  getCalenderEvents(id: string, start_date: string, end_start: string) {
    const params = new HttpParams()
      .set("firstDate", start_date)
      .set("lastDate", end_start);
    return this.http.get(this.API_URL + `/lesson-events/${id}`, { params: params });
  }

  /**
   * Get the calender events of the Academy of a single day.
   *
   * @param id Object Id, userid of the academy
   * @param start_date Start Date of the range (DD-MM-YYYY)
   * @param end_start End Date of the range (DD-MM-YYYY)
   * @returns Array of Calender Events
   */
  getCalenderEventsSingle(id: string, start_date: string, end_start: string) {
    const params = new HttpParams()
      .set("firstDate", start_date)
      .set("lastDate", end_start);
    return this.http.get(this.API_URL + `/lesson-events/${id}/single`, { params: params });
  }

  /**
   * Get the calender events of the Academy
   *
   * @param id Object Id, userid of the academy
   * @param userid Object Id, userid of the academy
   * @param type string, type of the  user
   * @param start_date Start Date of the range (DD-MM-YYYY)
   * @param end_start End Date of the range (DD-MM-YYYY)
   * @returns Array of Calender Events
   */
  getCalenderEventsOfType(id: string, userid: string, type: string, start_date: string, end_start: string) {
    const params = new HttpParams()
      .set("firstDate", start_date)
      .set("lastDate", end_start);
    return this.http.get(this.API_URL + `/lesson-events/${id}/${userid}/${type}`, { params: params });
  }

}
