import { Component } from '@angular/core';
import { SharedModule } from '../shared.moudle';

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {
  pdfSrc = "pdf/privacy.pdf";
}
