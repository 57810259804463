const AssessmentAccessControl = {

    create_assessment: {
        permissions: [
            'assessment-create',
            'assessment-access-to-all'
        ]
    },
    view_assessment: {
        permissions: [
            'assessment-view',
            'assessment-access-to-all'
        ]
    },
    view_assessment_details: {
        permissions: [
            'assessment-view-details',
            'assessment-access-to-all'
        ]
    },
    edit_assessment: {
        permissions: [
            'assessment-edit',
            'assessment-access-to-all'
        ]
    },
    delete_assessment: {
        permissions: [
            'assessment-delete',
            'assessment-access-to-all'
        ]
    },
    view_assessment_associated_group: {
        permissions: [
            'assessment-view-associated-group',
            'assessment-access-to-all'
        ]
    },
    view_assessment_associated_metric: {
        permissions: [
            'assessment-view-associated-metric',
            'assessment-access-to-all'
        ]
    },
    view_assessment_upcoming: {
        permissions: [
            'assessment-view-upcoming',
            'assessment-access-to-all'
        ]
    },
    create_assessment_shedule: {
        permissions: [
            'assessment-create-shedule',
            'assessment-access-to-all'
        ]
    },
    edit_assessment_shedule: {
        permissions: [
            'assessment-edit-shedule',
            'assessment-access-to-all'
        ]
    },
    delete_assessment_shedule: {
        permissions: [
            'assessment-delete-shedule',
            'assessment-access-to-all'
        ]
    },
    view_assessment_report: {
        permissions: [
            'assessment-view-report',
            'assessment-access-to-all'
        ]
    },
    view_assessment_history: {
        permissions: [
            'assessment-view-history',
            'assessment-access-to-all'
        ]
    },
    edit_assessment_report: {
        permissions: [
            'assessment-edit-report',
            'assessment-access-to-all'
        ]
    },
    delete_assessment_report: {
        permissions: [
            'assessment-delete-report',
            'assessment-access-to-all'
        ]
    },

    view_assessment_metrics_list: {
        permissions: [
            'assessment-view-metrics-list',
            'assessment-access-to-all'
        ]
    },
    view_assessment_metrics_system: {
        permissions: [
            'assessment-view-metrics-system',
            'assessment-access-to-all'
        ]
    },
    view_assessment_metrics_custom: {
        permissions: [
            'assessment-view-metrics-custom',
            'assessment-access-to-all'
        ]
    },
    create_assessment_metrics_custom: {
        permissions: [
            'assessment-create-metrics-custom',
            'assessment-access-to-all'
        ]
    },
    edit_assessment_metrics_custom: {
        permissions: [
            'assessment-edit-metrics-custom',
            'assessment-access-to-all'
        ]
    },
    delete_assessment_metrics_custom: {
        permissions: [
            'assessment-delete-metrics-custom',
            'assessment-access-to-all'
        ]
    },



}

export{
    AssessmentAccessControl
};