<div class="row">
  <div class="col-12 grid-margin">
      <div class="card">
          <div class="card-body">
              <div class='app'>
                  <div class='app-top calendar-head'>
                    <div>
                        <button matRipple class="btn btn-primary calender-arrows" mat-raised-button (click)="previousDate()">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>
                        <button matRipple class="btn btn-primary calender-arrows" mat-raised-button (click)="currentDate()">
                          Today
                        </button>
                        <button matRipple class="btn btn-primary calender-arrows" mat-raised-button (click)="nextDate()">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                        <!-- <button class="btn btn-primary calender-arrows" mat-raised-button (click)="currentDate()">
                            {{date| dateTime:'LLLL'}}
                        </button> -->

                        <button matRipple class="btn btn-primary calender-icon" mat-raised-button (click)="picker.open()">
                            <mat-icon aria-hidden="false" aria-label="Example home icon">calendar_today</mat-icon>
                        </button>
                        <mat-form-field appearance="fill" style="width:1px;visibility:hidden;">
                            <input matInput [matDatepicker]="picker" (dateChange)="setdate($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>


                      <div class="right">
                          <div ngbDropdown *ngIf="view_class">
                              <button matRipple *ngIf="calendarsAccessControl.view_events.permissions | module: 'calendar'"
                                  class="btn btn-outline-primary" type="button" id="dropdownClass"
                                  ngbDropdownToggle style="min-width: 15vw; height: 2.6rem;">
                                  Group
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownClass" style="min-width: 100%;">
                                  <input type="text" class="form-control filter-search" [(ngModel)]="searchTerm"
                                      placeholder="Search..." (input)="search()" />
                                  <div>
                                      <div class=" filter-elem" *ngFor="let elem of classes; let i = index">
                                          {{elem.basic_information.name.first_name}}
                                          <input type="checkbox" class="pointer" [(ngModel)]="elem.isSelected"
                                              [ngModelOptions]="{standalone: true}" (change)="filterClass()">
                                      </div>
                                  </div>
                                  <div class="mt-4 mb-4" *ngIf="classes.length === 0"
                                      style="display: flex; justify-content:center;">
                                      No Groups
                                  </div>
                              </div>
                          </div>

                          <div ngbDropdown *ngIf="calendarsAccessControl.view_events.permissions | module: 'calendar'">
                              <button matRipple class="btn btn-outline-primary" type="button" id="dropdownFilter"
                                  ngbDropdownToggle style="min-width: 15vw; height: 2.6rem;">
                                  Event Type
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownFilter" style="min-width: 100%;">
                                  <!-- <h6 class="dropdown-header filter-header">Session Status</h6>
                                  <div class="dropdown-item filter-elem">Pending <input type="checkbox"></div>
                                  <div class="dropdown-item filter-elem">Completed <input type="checkbox"></div>
                                  <div class="dropdown-item filter-elem">Upcoming <input type="checkbox"></div> -->
                                  <!-- <h6 class="dropdown-header filter-header">Event Type</h6> -->
                                  <div class=" filter-elem">P.D Sessions <input type="checkbox" class="pointer"  [(ngModel)]="pd_filt"
                                          [ngModelOptions]="{standalone: true}" (change)="filterClass()"></div>
                                  <div class=" filter-elem">Homework <input type="checkbox" class="pointer"  [(ngModel)]="hw_filt"
                                          [ngModelOptions]="{standalone: true}" (change)="filterClass()"></div>
                                  <div class=" filter-elem">Lessons <input type="checkbox" class="pointer"  [(ngModel)]="lp_filt"
                                          [ngModelOptions]="{standalone: true}" (change)="filterClass()"></div>
                              </div>
                          </div>
                      </div>


                  </div>

                  <div class="calendar-conatin mt-3">
                    <ng-container *ngIf="calendarsAccessControl.view_events.permissions | module: 'calendar'; else emptyCalendar" >
                      <full-calendar  #fullcalendar [events]="calendarEvents"
                                        (dateClick)="handleDateClick($event)"
                                        (eventDragStop)="eventDragStop($event)"
                                        (eventClick)="eventClick($event)"
                                        [options]="options">
                        </full-calendar>
                    </ng-container>

                    <ng-template #emptyCalendar>
                        <full-calendar #fullcalendar [events]="[]"
                                        (dateClick)="handleDateClick($event)"
                                        (eventDragStop)="eventDragStop($event)"
                                        (eventClick)="eventClick($event)"
                                        [options]="options">
                        </full-calendar>
                    </ng-template>
                  </div>

                  <div class="legend-contain mt-3" *ngIf="calendarsAccessControl.view_events.permissions | module: 'calendar'">
                    <div class="legend-center calendar-head">
                        <div class="legend-elem pending-text">
                            <div class="legend-ball pending-ball over-flow-word"></div> Homework
                        </div>
                        <div class="legend-elem completed-text">
                            <div class="legend-ball completed-ball over-flow-word"></div> Lesson Plan
                        </div>
                        <div class="legend-elem upcoming-text">
                            <div class="legend-ball upcoming-ball over-flow-word"></div> Personnel development
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #content let-modal class="modal fade" id="exampleModal-2" aria-labelledby="exampleModalLabel-2">
  <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title mt-1" id="exampleModalLabel">{{selectedDate | dateTime:'MMM | dd | YYYY | EEEE'}}</h5>
          <button type="button" class="close" data-dismiss="modal" style="line-height: 0px;" aria-label="Close" (click)="modal.dismiss()">
              <img src="images/icons/ic_close_orange.png" alt="icon" style="width:20px" class="close-icon">
          </button>
      </div>
      <div class="modal-body" style="max-height: 80vh;overflow-y: auto;">
          <div class="event-elem over-flow-word" *ngFor="let elem of selectedDateEvents.academy_events; let i = index"
              [ngClass]="[elem.event_type === 'homework' ? 'homework-elem' : '',  elem.event_type === 'lesson_plan' ? 'lesson-plan' : '',  elem.event_type === 'personal' ? 'personal-elem' : '']">
              <div class="title over-flow-word mb-2" style="max-width: 15rem;">
                  {{elem.name | string : 'sentence'}}
              </div>
              <div class="title1 mb-2">
                  {{elem.start_date | dateTime}}
              </div>
              <div class="title1 mb-3" *ngIf="elem.event_type === 'personal'">
                  Conducted by : {{elem.coach || '-'}}
              </div>
              <div class="mt-2">
                  <!-- <button class="btn" *ngIf="elem.event_type == 'homework'">View details</button>
                  <button class="btn" *ngIf="elem.event_type == 'lesson_plan'">View details</button> -->
                  <button matRipple class="btn main-height" (click)="goto(elem)" matRipple>View details</button>
              </div>
          </div>
          <div *ngIf="selectedDateEvents.academy_events ===  null" class="no-data">
              No events on {{selectedDate | dateTime}}. Please choose another date.
          </div>
      </div>
  </div>
</ng-template>
