const LessonPlanAccessControl = { 
    view_lesson_plan: {
        permissions: [
            'lesson-plan-view',
            'lesson-access-to-all'
        ]
    },
}

export{
    LessonPlanAccessControl
};