/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-eula',
  standalone: true,
  imports: [],
  templateUrl: './eula.component.html',
  styleUrl: './eula.component.scss'
})
export class EulaComponent implements OnInit {
  eula: any = {}
  constructor(private api_service: ApiService) { }

  ngOnInit(): void {
    this.api_service.getEula().subscribe((res: any) => {
      this.eula = res[0]
    })
  }
}
