/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  API_URL = `${environment.apiURL}`
  API_KEY_PINCODE = `${environment.pincode_api}`

  constructor(
    private http: HttpClient,
    // private toastr: ToastrService,
    // private router: Router,
    // private storage: StorageService,
  ) { }

  /**
   * Get member types
   *
   * @returns list of member types
   */
  getMemberTypes() {
    return this.http.get(this.API_URL + '/user-types')
  }

  /**
   * Get member of a given id
   *
   * @param id user id of the user to be fetched
   * @param filters object containing sports and filter
   * @returns list of members
   */
  getMemberOfId(id: string, filters = {}) {
    return this.http.get(this.API_URL + `/members/` + id, {
      params: filters
    });
  }

  getMembersByType(id: string, type: string, page: string, filter_sports?: string, filter_status?: string, search_key?: string, page_limit? :number) {
    let params = new HttpParams()
    .append('page', page)
    .append('type', type)
    if(filter_sports){
      params = params.append('sports', filter_sports)
    }
    if(filter_status){
      params = params.append('status', filter_status)
    }
    if(search_key){
      params = params.append('search_key', search_key)
    }
    if(page_limit){
      params = params.append('limit', page_limit.toString())
    }
    // console.log(this.API_URL + `/members-v2/` + id, { params: params})
    return this.http.get(this.API_URL + `/members-v2/` + id, { params: params});
  }

  /**
   * Get list of academies in dartle
   *
   * @param page corresposnding pagination number
   */
  getAcademiesList(page = 0, search_key?: string){
    let params = new HttpParams().append('page', page.toString())
    if(search_key){
      params = params.append('search_key', search_key);
    }
    return this.http.get(`${this.API_URL}/academy-list`, { params: params });
  }

  /**
   * send profiles to academies
   *
   * @param page corresposnding pagination number
   */
  postRecruitments(recruitment_Invites: any[]) {
    return this.http.post(`${this.API_URL}/recruitment`, recruitment_Invites);
  }

  // postRecruitments(recruitment_Invites) {
  //   let params = new HttpParams().append('Recruitment_Invites', recruitment_Invites)
  //   return this.http.post(`${this.API_URL}/recruitment`, params);
  // }

  /**
   * Sent email invitation for a user.
   *
   * @param academy user id of the academy.
   * @param id user id of the user to be sent invitation.
   * @returns
   */
  sendInvitaionMail(academy: string, id: string) {
    return this.http.get(this.API_URL + `/members-sent-invite/${academy}/${id}`);
  }

  /**
   * Sent email invitation for a user.
   *
   * @param academy user id of the academy.
   * @param id user id of the user to be sent invitation.
   * @returns
   */
  sendInvitaionMailParent(academy: string, id: string, user: string) {
    return this.http.get(this.API_URL + `/members-sent-invite-parent/${academy}/${id}/${user}`);
  }

  /**
   * Check if given user is a member of the Academy
   *
   * @param id user id of the academy
   * @param mid user id of the user to be checked
   * @returns true/false
   */
  isMember(id: string, mid: string) {
    return this.http.get(this.API_URL + `/member/${id}/${mid}`);
  }

  /**
   * Get member of a given id
   *
   * @param id user id of the user to be fetched
   * @returns list of members
   */
  getMemberOfIdSpecificType(id: string, type: string) {
    return this.http.get(this.API_URL + `/members/` + id + "/" + type);
  }

  /**
   * Accept member in to the academy.
   *
   * @param id id user id of the user to be accepted.
   * @returns
   */
  acceptMemberToAcademy(id: any) {
    return this.http.get(`${this.API_URL}/members/${id}/accept`);
  }

  /**
   * Add a member to the Academy
   *
   * @param data json data of the admitting member
   * @param id id of the academy
   * @returns post result
   */
  addMember(data: any, id: string) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(this.API_URL + `/members/` + id + `/add`, data, config);
  }

  transferSingle(data: any, id:any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(this.API_URL + `/members-v2/` + id + `/remove`, data, config);

    // let params = new HttpParams().set("id", id);
    // return this.http.get(this.API_URL + `/members-v2/${id}/remove`, { params: params });
  }

  transferMany(data: any, id:any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(this.API_URL + `/members-v2/` + id + `/remove_many`, data, config);
  }

  addMemberMultipleMembers(data: any[], id: string): Observable<any> {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(`${this.API_URL}/members-v2/${id}/add`, data, config).pipe(
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Add a member to the Academy with parent self registeration.
   *
   * @param data json data of the admitting member
   * @param id id of the academy
   * @returns post result
   */
  addMemberSelf(data: any, id: string) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(this.API_URL + `/members/` + id + `/add-parent`, data, config);
  }

  getClassCoachs(id: string, classid: string) {
    const params = new HttpParams().set("classid", classid);
    return this.http.get(this.API_URL + `/groups/${id}/coachs`, { params: params });
  }

  /**
   * Add an already existing user to the Academy.
   *
   * @param mid user id of the existing user.
   * @param id user id of the academy
   * @param role role of the oncoming user.
   * @returns
   */
  addMemberExisting(mid: any, id: any, role: any) {
    return this.http.get(this.API_URL + `/members/` + id + `/` + role + `/` + mid);
  }

  /**
   * Add parental info of the existing user to the Academy.
   *
   * @param mid user id of the existing user.
   * @param id user id of the academy
   * @param role role of the oncoming user.
   * @param data role of the parent's data.
   * @returns
   */
  addParentMemberExisting(mid: any, id: any, role: any, data: any) {
    return this.http.post(this.API_URL + `/members/` + id + `/` + role + `/` + mid, data);
  }

  /**
   * Remove a member from the academy
   *
   * @param id user id of the Academy.
   * @param mid user id of the member to be deleted.
   * @returns
   */
  removeMember(id: any, mid: any) {
    const httpParams = new HttpParams()
      .append("mid", mid)
    return this.http.post(this.API_URL + `/members/` + id + `/remove_many`, httpParams);
  }

  /**
   * Removes a given member from the specific group.
   *
   * @param id User id of the academy.
   * @param mid User id of the member.
   * @returns Status of the member removal.
   */
  removeMemberToGroup(id: any, mid: string) {
    const httpParams = new HttpParams()
      .append("mid", mid)
    return this.http.post(this.API_URL + `/groups/` + id + `/remove`, httpParams);
  }

  /**
   * Removes a given member from the specific group.
   *
   * @param id User id of the academy.
   * @param mid User id of the member.
   * @returns Status of the member removal.
   */
  removeMemberToGroupSelect(id: any, mid: any, academy: string) {
    const httpParams = new HttpParams()
      .append("mid", mid)
      .append("classid", id)
    return this.http.post(this.API_URL + `/groups/${academy}/remove`, httpParams);
  }

  /**
   * Get the state list from a country.
   *
   * @param code iso code of the country.
   * @returns
   */
  getStateList(iso: string) {
    return this.http.get(`${this.API_URL}/zipcode/${iso}`)
  }

  /**
   * Get pincode from state and country.
   *
   * @param state state name.
   * @param iso iso code of the country.
   * @returns
   */
  getPincodes(state: string, iso: string) {
    return this.http.get(`${this.API_URL}/zipcode/${iso}/${state}`)
  }

  /**
     * Get Gallery Summary for a member
     *
     * @param memberId UUID for member.
     * @param lastId ID of the last element in a page.
     * @returns
     */
  // getGallerySummaryForMember(memberId: string, lastId = null) {
  //   return this.http.get(this.API_URL + `/feed/academic/${memberId}`, {
  //     params: {
  //       lastid: lastId
  //     }
  //   })
  // }

  /**
   * Get Gallery Details for a member
   *
   * @param memberId UUID for member.
   * @param type type of the gallery: homework | pd | user | tagged .
   * @returns
   */
  // getGalleryDetailsForMember(memberId: string, type: string, lastId = null) {
  //   return this.http.get(this.API_URL + `/feed/academic/${memberId}/${type}`, {
  //     params: {
  //       lastid: lastId
  //     }
  //   })
  // }

  /**
   * Get comments for a post
   *
   * @param memberId UUID for member.
   * @param postId UUID of post
   * @returns
   */
  getCommentsForPost(memberId: string, postId: string) {
    return this.http.get(this.API_URL + `/comments`, {
      params: {
        postid: postId,
        userid: memberId
      }
    })
  }

  getProfileAcademicStats(academyId: string, userId: string, params = {}) {
    return this.http.get(this.API_URL + `/profile-academics/${academyId}/${userId}`, {
      params: {
        ...params
      }
    })
  }

  /**
   * Get List of Class for a member
   *
   * @param userId UUID for member.
   * @returns list of classes
   */
  getClassList(userId: string) {
    return this.http.get(this.API_URL + `/class-list/${userId}`)
  }

  getClassListOfAcademy(userId: string, academy: string) {
    return this.http.get(this.API_URL + `/class-list/${userId}/${academy}`)
  }

  /**
   * Fetch the all user's classes
   *
   * @param userid ObjectId: of the user.
   * @returns
   */
  loginFetchUserClass(userid: string) {
    return this.http.get(this.API_URL + `/class-list/${userid}`)
  }

  /**
   * Get the attendence of the user with classlist
   *
   * @param academyId Object id of the academy.
   * @param userId Object id of the user.
   * @param classes List of Object ids of the class.
   * @returns
   */
  getAttendenceForUserClasses(academyId: string, userId: string, classes: any) {
    return this.http.get(this.API_URL + `/profile-attendance/${academyId}/${userId}`, {
      params: {
        class_list: classes
      }
    })
  }

  /**
   * Get the list of products in the Academy.
   *
   * @param academy_id Object id of the academy.
   * @returns
   */
  getProducts(academy_id: string, fetch_all: any = "") {
    const params = new HttpParams().append('id', academy_id).append('all', fetch_all)
    return this.http.get(`${this.API_URL}/products`, { params: params })
  }

  /**
   * Get the a product in the Academy.
   *
   * @param product_id Object id of the product.
   * @returns
   */
  getProductIndividual(product_id: string) {
    return this.http.get(`${this.API_URL}/products/${product_id}`)
  }

  /**
   * Delete a individual product plan
   *
   * @param product_id Object id of the product.
   * @returns
   */
  deleteProductIndividual(product_id: string) {
    return this.http.delete(`${this.API_URL}/products/${product_id}`)
  }

  /**
   * Create a product plan
   *
   * @param data
   * @returns
   */
  createProducts(data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/products`, data, config);
  }

  /**
   * edit a product plan
   *
   * @param data
   * @returns
   */
  editProductsIndividual(data: any, product_id: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.put(`${this.API_URL}/products/${product_id}`, data, config);
  }

  /**
   * Get the list of subscriptions in the Academy.
   *
   * @param plans List of products which are to be fetched.
   * @param page page number.
   * @returns
   */
  getSubscriptions(academy_id: string, plans: string, page: any = 0, search_key?: string) {
    let params = new HttpParams().append('plans', plans)
      .append('page', page)
      .append("academy_id", academy_id)
    if(search_key){
      params = params.append('search_key', search_key)
    }
    return this.http.get(`${this.API_URL}/subscriptions`, { params: params })
  }

  /**
  * Update a subscription record.
  *
  * @param data Data of the subscription to be updated.
  * @returns
  */
  updateSubscriptions(data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.put(`${this.API_URL}/subscriptions/${data._id}`, data, config);
  }

  assignSubscriptions(user: string, product: string | number | boolean, new_product: string | number | boolean) {
    const params = new HttpParams().append('user', user).append('product', product).append('new_product', new_product)
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: params }
    return this.http.put(`${this.API_URL}/subscriptions/${product}/assign`, {}, config);
  }

  /**
  * Create a subscription record.
  *
  * @param data Data of the subscription to be updated.
  * @returns
  */
  createSubscriptions(data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/subscriptions`, data, config);
  }

  /**
   * Get the list of transactions in the Academy.
   *
   * @param plans List of products which are to be fetched.
   * @param page page number.
   * @returns
   */
  // getTransactions(academy_id: string, plans: any, page: any = 0, user_id?: string | number | boolean, search_key?: string | number | boolean) {
  //   if (user_id) {
  //     const params = new HttpParams().append('plans', plans)
  //       .append('page', page)
  //       .append("academy_id", academy_id)
  //       .append("user", user_id)
  //       .append('search_key', search_key)
  //     return this.http.get(`${this.API_URL}/transactions`, { params: params })
  //   } else {
  //     const params = new HttpParams().append('plans', plans)
  //       .append('page', page)
  //       .append('academy_id', academy_id)
  //       .append('search_key', search_key)
  //     return this.http.get(`${this.API_URL}/transactions`, { params: params })
  //   }
  // }

  /**
   * Create a transactions record.
   *
   * @param data Data of the transaction to be recorded.
   * @returns
   */
  createTransactions(data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/transactions`, data, config);
  }

  /**
  * Update a push notification to the user record.
  *
  * @param data Data of the push notifications to be recorded.
  * @param id object id of the user to be recorded.
  * @returns
  */
  updatePushToken(data: any, id: string) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.put(`${this.API_URL}/users/${id}/push_token`, data, config);
  }

  /**
   * Remind a list of the user of there subscriptions.
   *
   * @param data Notifications data to be saved.
   * @param reminde_list array of the users to be notificied.
   * @returns
   */
  remindSubscriptions(data: any, reminde_list: any) {
    const params = new HttpParams().append('reminders', reminde_list)
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: params }
    return this.http.post(`${this.API_URL}/queue/remind`, data, config);
  }

  /**
   * Get Gallery Summary for a member
   *
   * @param memberId UUID for member.
   * @param lastId ID of the last element in a page.
   * @returns
   */
  getGallerySummaryForMember(memberId: string, lastId?: any) {
    return this.http.get(this.API_URL + `/feed/academic/${memberId}`, {
      params: {
        lastid: lastId
      }
    })
  }

  /**
     * Get Gallery Details for a member
     *
     * @param memberId UUID for member.
     * @param type type of the gallery: homework | pd | user | tagged .
     * @returns
     */
  getGalleryDetailsForMember(memberId: any, type: string, lastId?: any) {
    return this.http.get(this.API_URL + `/feed/academic/${memberId}/${type}`, {
      params: {
        lastid: lastId
      }
    })
  }

  /**
 * API to get homework individual.
 *
 * @param id Object Id: of the Homework.
 * @returns Json object with Homework data.
 */
  getHomeworkIndi(id: any) {
    return this.http.get(this.API_URL + `/homework-indi/${id}`);
  }

  /**
   * Get a specific Personal Development.
   *
   * @param id Object Id: Personal Development id.
   * @returns
   */
  getPesonalDevelopmentWithId(id: any) {
    return this.http.get(this.API_URL + `/personaldev/${id}`);
  }

  /**
   * Get the list of transactions in the Academy.
   *
   * @param plans List of products which are to be fetched.
   * @param page page number.
   * @returns
   */
    getTransactions(academy_id: any, plans: any, page: any = 0, user_id?: any, search_key?: string) {
      if (user_id) {
        const params = new HttpParams().append('plans', plans)
          .append('page', page)
          .append("academy_id", academy_id)
          .append("user", user_id)
          .append('search_key', search_key||'')
        return this.http.get(`${this.API_URL}/transactions`, { params: params })
      } else {
        const params = new HttpParams().append('plans', plans)
          .append('page', page)
          .append('academy_id', academy_id)
          .append('search_key', search_key||'')
        return this.http.get(`${this.API_URL}/transactions`, { params: params })
      }
    }
}
