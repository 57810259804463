import { Component } from '@angular/core';
import { SharedModule } from '../shared.moudle';

@Component({
  selector: 'app-refund-policy',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.css']
})
export class RefundPolicyComponent {
  pdfSrc = "pdf/terms.pdf";
}
