const ReportAccessControl = {
    view_student_list_reports: {
        permissions: [
            'view-reports-student-list',
            'report-access-to-all'
        ]
    },
    view_overview_report: {
        permissions: [
            'overview-report-view',
            'report-access-to-all'
        ]
    },
    download_overview_report: {
        permissions: [
            'overview-report-download',
            'report-access-to-all'
        ]
    },
    share_overview_report: {
        permissions: [
            'overview-report-share',
            'report-access-to-all'
        ]
    },
    view_overview_report_feedback: {
        permissions: [
            'view-feedback-overview-report',
            'report-access-to-all'
        ]
    },
    create_overview_report_feedback: {
        permissions: [
            'create-feedback-overview-report',
            'report-access-to-all'
        ]
    },
    edit_overview_report_feedback: {
        permissions: [
            'edit-feedback-overview-report',
            'report-access-to-all'
        ]
    },
    delete_overview_report_feedback: {
        permissions: [
            'delete-feedback-overview-report',
            'report-access-to-all'
        ]
    },


    view_academic_report: {
        permissions: [
            'academic-report-view',
            'report-access-to-all'
        ]
    },
    download_academic_report: {
        permissions: [
            'academic-report-download',
            'report-access-to-all'
        ]
    },
    share_academic_report: {
        permissions: [
            'academic-report-share',
            'report-access-to-all'
        ]
    },




    view_assessment_report: {
        permissions: [
            'assessment-report-view',
            'report-access-to-all'
        ]
    },
    download_assessment_report: {
        permissions: [
            'assessment-report-download',
            'report-access-to-all'
        ]
    },
    share_assessment_report: {
        permissions: [
            'assessment-report-share',
            'report-access-to-all'
        ]
    },



    view_session_report: {
        permissions: [
            'session-report-view',
            'report-access-to-all'
        ]
    },
    download_session_report: {
        permissions: [
            'session-report-download',
            'report-access-to-all'
        ]
    },
    share_session_report: {
        permissions: [
            'session-report-share',
            'report-access-to-all'
        ]
    },


    view_personnel_devolopment_report: {
        permissions: [
            'personnel-devolopment-report-view',
            'report-access-to-all'
        ]
    },
    download_personnel_devolopment_report: {
        permissions: [
            'personnel-devolopment-report-download',
            'report-access-to-all'
        ]
    },
    share_personnel_devolopment_report: {
        permissions: [
            'personnel-devolopment-report-share',
            'report-access-to-all'
        ]
    },
}

export{
    ReportAccessControl
};