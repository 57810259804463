/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChange, OnChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-password-strength',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './password-strength.component.html',
  styleUrl: './password-strength.component.scss'
})
export class PasswordStrengthComponent implements OnChanges  {
  bar0 = "";
  bar1 = "";
  bar2 = "";
  bar3 = "";
  private colors = ['#FE0000', '#FFAC1D', '#A6C061', '#28B30E'];
  strength = 'weak'
  @Input() public passwordToCheck = "";


  lowerLetters = false
  upperLetters = false
  numbers = false
  symbols = false
  length = false

  constructor() { }




  /**
   * Detect the changes in the input.
   *
   * @param changes the current input in the front end.
   */
  ngOnChanges(changes: Record<string, SimpleChange>): void {
    const password = changes['passwordToCheck'].currentValue;
    this.setBarColors(4, '#C4C4C4');
    if (password) {
      const c = this.getColor(this.checkStrength(password));
      this.setBarColors(c.index, c.color);
    } else {
      this.lowerLetters = false;
      this.upperLetters = false;
      this.numbers = false;
      this.symbols = false;
      this.length = false;
    }
  }

  private getColor(s: any) {
    let index = 0;
    if (s === 10) {
      index = 0;
      this.strength = "Very Weak"
    } else if (s === 20) {
      index = 1;
      this.strength = "Weak"
    } else if (s === 30) {
      index = 2;
      this.strength = "Good"
    } else if (s === 40) {
      index = 3;
      this.strength = "Great"
    } else {
      index = 4;
      this.strength = "Great"
    }
    return {
      index: index + 1,
      color: this.colors[index]
    };
  }

  private setBarColors(count: number, col: any) {
    for (let n = 0; n < count; n++) {
      switch(n){
        case 0:
          this.bar0 = col;
          break
        case 1:
          this.bar1 = col;
          break
        case 2:
          this.bar2 = col;
          break
        case 3:
          this.bar3 = col;
          break
        default:
          break
      }
      // this['bar' + n] = col;
    }
  }


  /**
   * Check the strength of current password.
   *
   * @param p Current password.
   * @returns strength
   */
  checkStrength(p: any) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`[\]]/g;
    this.lowerLetters = /[a-z]+/.test(p);
    this.upperLetters = /[A-Z]+/.test(p);
    this.numbers = /[0-9]+/.test(p);
    this.symbols = regex.test(p);
    this.length = p.length > 6
    // 3
    const flags = [this.lowerLetters, this.upperLetters, this.numbers, this.symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // 7
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;
    return force;
  }
}
