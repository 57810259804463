import { Component } from '@angular/core';
import { environment } from '../../../../environments/environments';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public version: string = environment.version;
  year = new Date().getFullYear()

  goToLink(url: string){
    window.open(url, "_blank");
  }
}
