/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  API_URL = `${environment.apiURL}`
  API_KEY_PINCODE = `${environment.pincode_api}`

  constructor(
    private http: HttpClient,
  ) { }



  /**
   * Get roles of a given academy
   *
   * @param id id of the academy
   * @returns list of roles
   */
  getRolesOfAcademy(id: any) {
    return this.http.get(this.API_URL + `/roles/` + id);
  }

  /**
   * Create role for the given academy
   *
   * @param id id of the academy
   * @param roleData Data of the role to be created for the academy - includes users and permissions list
   * @returns
   */
  createRoleForAcademy(id: any, roleData: any) {
    return this.http.post(this.API_URL + `/roles/` + id, roleData);
  }

  /**
   * Create role for the given academy
   *
   * @param id id of the academy
   * @param roleData Data of the role to be created for the academy - includes users and permissions list
   * @returns Details of role
   */
  viewRoleById(id: any, academyId: any) {
    return this.http.get(`${this.API_URL}/roles-individual/${id}/${academyId}`);
  }

  /**
   * Get a list of eligible member that can be assigned a role.
   *
   * @param academyId id of the academy.
   * @param role_id id of the role.
   * @returns
   */
  getmembersListAssign(academyId: string, role_id: string, page: any = 0, profile = "", search_key?: string | number | boolean) {
    if (search_key) {
      const params = new HttpParams().append('page', page).append('profile', profile).append('search_key', search_key)
      return this.http.get(`${this.API_URL}/roles-assign-list/${academyId}/${role_id}`, { params: params })
    } else {
      const params = new HttpParams().append('page', page).append('profile', profile)
      return this.http.get(`${this.API_URL}/roles-assign-list/${academyId}/${role_id}`, { params: params })
    }


  }

  /**
    * Update role for the given id
    *
    * @param id id of the role
    * @param roleData Data of the role to be update for the academy - might include users and permissions list
    * @returns
    */
  updateRoleById(id: any, roleData: any) {
    return this.http.put(this.API_URL + `/roles/` + id, roleData);
  }

  /**
   * Update the status of the role
   *
   * @param id Object id of the role
   * @param flag Boolean flag.
   * @returns
   */
  updateRoleStatusById(id: any, flag: any) {
    return this.http.put(`${this.API_URL}/roles/${id}/status/${flag}`, {});
  }

  /**
   * Delete role by role id
   *
   * @param id id of the role
   * @returns
   */
  deleteRoleById(id: any) {
    return this.http.get(this.API_URL + `/roles-delete/` + id);
  }

  /**
   * Get permissions of a given academy
   *
   * @param id id of the academy
   * @returns list of permissions
   */
  getPermissionsOfAcademy() {
    return this.http.get(this.API_URL + `/permission`);
  }

  /**
   * Search members based on academy
   *
   * @param academyId id of the academy
   * @param key search text
   * @returns list of users
   */
  searchMembers(academyId: any, key: any) {
    return this.http.get(this.API_URL + `/members-search/` + academyId, {
      params: {
        key
      }
    });
  }

  /**
   * Assign user to a role
   *
   * @param id id of the role
   * @param userId user id to remove from the role
   * @returns
   */
  assignUserToRole(academy: string, role: string, users: any) {
    const params = new HttpParams().append('users', users)
    return this.http.get(`${this.API_URL}/roles/${academy}/${role}/assign`, { params: params });
  }

  /**
   * Remove user from a role
   *
   * @param id id of the role
   * @param userId user id to remove from the role
   * @returns
   */
  removeUserFromRole(id: any) {
    return this.http.delete(this.API_URL + `/roles/${id}/remove`);
  }
}
