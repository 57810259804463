<div class="col-12 mb-3">
  <h3 class="page-title">
      <!-- <i class="mdi mdi-account menu-icon header-icon"></i> -->
      <i class="bi bi-person-fill menu-icon header-icon"></i>
      Gallery
  </h3>
</div>
<div class="col-md-12">
  <div class="card col-12" style="margin-top: 2rem;">
      <div class="card-body">
          <div class="row">
              <div class="col-12">
                  <div class="row" *ngIf="!is_loading">
                      <div class="col-12">
                          <div class="w-100 mb-3">
                              <div class="pulse-container mb-5 p-5 h-100 w-100">
                                  <div class="pulse" *ngFor="let num of [1,2, 3, 4, 5, 6]"></div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="w-100 d-flex flex-wrap">
                    @for (gallery of data; track gallery.title) {
                      <div class="col-lg-4 col-md-6 col-12 p-4"
                          (keydown.enter)="redirectToGalleryDetails(gallery.title)"
                          (keydown.space)="redirectToGalleryDetails(gallery.title)"
                          tabindex="0"
                          role="button"
                          (click)="redirectToGalleryDetails(gallery.title)">
                          <div class="container" *ngIf="gallery.title === 'Homework Attachments'">
                              <div class="top-section">
                                  <img src="{{gallery.url}}" alt="pic" (error)="onImgError($event, gallery)"
                                      [ngClass]="{'error-image': gallery.is_load_error, 'image-loaded': !gallery.is_load_error}">
                              </div>
                              <div class="footer">
                                  <img class="content-logo" src="images/icons/gallery_homework.png" alt="pic"
                                      style="width: 3rem;">
                                  <div>
                                      <h5 class="gallery-title font-weight-bold">{{gallery.title}}</h5>
                                      <div style="display: flex;">
                                          <span class="contents" style="margin-right: 2rem;">
                                              <img src="images/icons/gallery_image.png" style="width: 17px;" alt="pic">
                                              Images({{gallery.photo_count}})</span>
                                          <span class="contents">
                                              <img src="images/icons/gallery_videocam.png" alt="pic"
                                                  style="width: 17px;">
                                              Videos({{gallery.video_count}})</span>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="container" *ngIf="gallery.title === 'Personal Development'">
                              <div class="top-section">
                                  <img src="{{gallery.url}}" alt="pic" (error)="onImgError($event, gallery)"
                                      [ngClass]="{'error-image': gallery.is_load_error, 'image-loaded': !gallery.is_load_error}">
                              </div>
                              <div class="footer">
                                  <img class="content-logo" alt="pic" src="images/icons/gallery_pd.png"
                                      style="width: 3rem;">
                                  <div>
                                      <h5 class="gallery-title font-weight-bold">{{gallery.title}}</h5>
                                      <div style="display: flex;">
                                          <span class="contents" style="margin-right: 2rem;">
                                              <img src="images/icons/gallery_image.png" alt="pic" style="width: 17px;">
                                              Images({{gallery.photo_count}})</span>
                                          <span class="contents">
                                              <img src="images/icons/gallery_videocam.png" alt="pic"
                                                  style="width: 17px;">
                                              Videos({{gallery.video_count}})</span>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="container" *ngIf="gallery.title === 'Posts Tagged' || gallery.title === 'Posts'">
                              <div class="top-section">
                                  <img src="{{gallery.url}}" (error)="onImgError($event, gallery)" alt="pic"
                                      [ngClass]="{'error-image': gallery.is_load_error, 'image-loaded': !gallery.is_load_error}">
                              </div>
                              <div class="footer">
                                  <img class="content-logo" src="images/icons/gallery_home.png" alt="pic"
                                      style="width: 3rem;">
                                  <div>
                                      <h5 *ngIf="gallery.title === 'Posts Tagged'"
                                          class="gallery-title font-weight-bold">{{gallery.title}}</h5>
                                      <h5 *ngIf="gallery.title === 'Posts'" class="gallery-title font-weight-bold">All
                                          {{gallery.title}}</h5>
                                      <div style="display: flex;">
                                          <span class="contents" style="margin-right: 2rem;">
                                              <img src="images/icons/gallery_image.png" style="width: 17px;" alt="pic">
                                              Images({{gallery.photo_count}})</span>
                                          <span class="contents">
                                              <img src="images/icons/gallery_videocam.png" alt="pic"
                                                  style="width: 17px;">
                                              Videos({{gallery.video_count}})</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    }

                    @if (data.length === 0 && is_loading) {
                      <div class="w-100">
                        <div class="w-100 d-flex justify-content-center no-data">
                            No images uploaded yet.
                        </div>
                    </div>
                    }
                  </div>

              </div>
          </div>
      </div>
  </div>
</div>
