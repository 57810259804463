/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { Component, computed, signal, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { ThemeService } from '../../services/theme.service';
import { NavbarComponent } from "../shared/navbar/navbar.component";
import { SidebarComponent } from "../shared/sidebar/sidebar.component";
import { SharedModule } from '../shared/shared.moudle';
import { PermissionPipe } from '../../helpers/permission.pipe';

import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { FooterComponent } from '../shared/footer/footer.component';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    NavbarComponent,
    SidebarComponent,
    SharedModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    FooterComponent,
    MatSidenavModule
  ],
  providers: [DatePipe, CurrencyPipe, PermissionPipe],
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        // style({ transform: 'translateX(-100%)',opacity: 0 }),
        // animate('300ms ease-out', style({ transform: 'translateX(0%)',  })),
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ])
    ])
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit {
  screenWidth = signal(0);
  screenHeight: any;
  version: unknown
  feed: unknown
  showNav = true
  user_id = ""
  collapsed = signal(false);
  sidenavWidth = computed(() => (this.collapsed() ? (this.isMobile() ?"0px": "70px") : (this.isMobile()?"70px": "12vw") ));
  isMobile = computed(()=>(this.screenWidth() <=1200))


  constructor(
    private themeService: ThemeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (this.showNavChecker(event['url'])
          || (event['url'].includes('/user/consent'))
          || (event['url'].includes('/user/send'))
          || (event['url'].includes('/user/reset'))
          || (event['url'].includes('/verify-otp'))
          || (event['url'].includes('self-registration'))
          || (event['url'].includes('/password-reset'))
        ) {
          this.showNav = false;
        } else {
          this.showNav = true;
        }
      }
    });

   }

  ngOnInit() {
    this.screenWidth.set(window.innerWidth);
    this.screenHeight = window.innerHeight;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child!.firstChild) {
          child = child!.firstChild;
        }
        return child!.snapshot.data['title'];
      })
    ).subscribe((pageTitle: string) => {
      this.titleService.setTitle(pageTitle);
    });
    // console.log(environment.production)
    // if (environment.production) {
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['title'];
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: any) {
    this.screenWidth.set(window.innerWidth);
    this.screenHeight = window.innerHeight;
  }

  onCollapsedChange(newState: boolean) {
    this.collapsed.set(newState)
  }

  showNavChecker(url: string) {
    switch (url) {
      case "/login":
        return true;
      case "/signup":
        return true;
      case "/switch":
        return true;
      case "/register":
        return true;
      case "/forgot":
        return true;
      case "/password":
        return true;
      case "/password-reset":
        return true;
      case "/verify-otp":
        return true;

      default:
        return false;
    }
  }

  onActivate(event: any) {
    // console.log(event)
    document.body.scrollTop = 0;
  }

  toggleTheme() {
    const newTheme = document.documentElement.getAttribute('data-theme') === 'dark' ? 'light' : 'dark';
    this.themeService.setTheme(newTheme);
  }
}
