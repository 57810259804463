<nav class="navbar navbar-dark navbar-expand-md w-100">

  <div class="container-fluid" style="display: flex; align-items: center;">
    <button mat-icon-button (click)="toggle()">
      <mat-icon>menu</mat-icon>
    </button>
      <a class="navbar-brand" routerLink="/"><img src="images/logo.svg" class="logo-nav" alt=""></a>
      <div class="collapse navbar-collapse mr-auto w-100" id="navbarNav">
        <ul class="navbar-nav navbar-nav-right w-100">
          <li class="nav-item nav-profile dropdown ms-auto">
            <a class="nav-link dropdown-toggle d-flex" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="nav-profile-img">
                <img src="{{dp}}" (error)="onImgError($event)" alt="image">
                <span class="availability-status online"></span>
              </div>
              <div class="nav-profile-text d-flex">
                <p class="mb-1 max-width text-black text-base text-wrap prevent-select" title="{{display_name}}" translate>{{display_name}}</p>
              </div>
              <span class="bi bi-chevron-down"></span>
            </a>
            <div class="dropdown-menu navbar-dropdown" aria-labelledby="navbarDropdown">
              <a class="dropdown-item text-base" href="javascript:;" [routerLink]="['/settings']" translate>
                <i class="bi bi-gear"></i>
                Setting
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-base" href="javascript:;" translate (click)="logout()">
                <i class="bi bi-box-arrow-left"></i>
                Sign out
              </a>
            </div>
          </li>
        </ul>
      </div>
      <!-- <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
      (click)="toggleOffcanvas()">
      <i class="bi bi-three-dots-vertical"></i>
    </button> -->
  </div>
</nav>

